import { OktaAuth } from "@okta/okta-auth-js"
import { Dispatch } from "redux"
import { clearLoggedInUser } from "../redux/reducers/userReducer"

export class LogoutHelper {
    private dispatch: Dispatch
    private oktaAuth: OktaAuth

    constructor(oktaAuth: OktaAuth, dispatch: Dispatch) {
        this.oktaAuth = oktaAuth
        this.dispatch = dispatch
    }

    /* eslint-disable class-methods-use-this */
    IfExternalURL() {
        return window.location.href.includes('hillsidenewmedia')
    }
    /* eslint-enable class-methods-use-this */

    async handleLogout() {
        if (this.IfExternalURL()) {
            await this.oktaAuth.signOut()
        } else {
            this.oktaAuth.signInWithRedirect()
            this.dispatch(clearLoggedInUser())
        }
    }

    getLogoutButtonLabel() {
        if (this.IfExternalURL()) {
            return "Logout"
        }
        return "Resync Okta"

    }

    oktaExpiryListeners() {
        if (this.IfExternalURL()) {
            this.oktaAuth.tokenManager.on('expired', () => this.handleLogout())

            return () => {
                this.oktaAuth.tokenManager.off('expired', () => this.handleLogout())
            }
        }
    }
}