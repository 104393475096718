// eslint-disable-next-line no-shadow
export enum BookingAction {
  SHOW_ME = 'SHOW_ME',
  CHECK_IN = 'CHECK_IN',
  CANCEL = 'CANCEL',
}

// eslint-disable-next-line no-shadow
export enum BookingNotificationError {
  CANCELLED = 'NotificationCancelledBooking',
  EXPIRED = 'NotificationOldBooking',
}

// eslint-disable-next-line no-shadow
export enum BookingStatus {
  PENDING = 1,
  ACCEPTED = 2,
  DECLINED = 3,
  CANCELLED = 4,
  CHECKEDIN = 5,
}

// eslint-disable-next-line no-shadow
export enum BookingSearchForOptions {
  MYSELF = 1,
  EMPLOYEE = 2,
  VISITOR = 3,
}

// eslint-disable-next-line no-shadow
export enum BookingFeatureTypes {
  Desk = 1,
  Meeting_Room = 2,
  Large_Desk = 3,
  SVG_Rectangle = 4,
  SVG_Text = 5,
  SVG_Generic = 6,
}

// eslint-disable-next-line no-shadow
export enum BookingSearchType {
  SINGLE = 0,
  BLOCK = 1,
}

// eslint-disable-next-line no-shadow
export enum BookingDeskPortPosition {
  TOP = 1,
  RIGHT = 2,
  BOTTOM = 3,
  LEFT = 4,
}

// eslint-disable-next-line no-shadow
export enum BookingSliderIndex {
  GRID_VIEW = 0,
  FLOORPLAN_VIEW = 1,
}

// eslint-disable-next-line no-shadow
export enum BookingWizardStepLabels {
  SELECT_DESK = 0,
  SUMMARY = 1,
  ALTERNATIVES = 2,
}

// eslint-disable-next-line no-shadow
export enum CheckInOutStatus {
  IN = 'in',
  OUT = 'out',
}

// eslint-disable-next-line no-shadow
export enum WorkingHoursAlias {
  HALF_DAY = 4,
  FULL_DAY = 8,
}

// eslint-disable-next-line no-shadow
export enum BookingListMaxMonths {
  BOOKINGS_MAX_MONTHS = 6,
  BOOKINGS_MAX_BOOKINGS = 6,
  BOOKINGS_MIN_CHECK = 3,
  BOOKINGS_MIN_DISPLAY = 2,
  BOOKINGS_MAX_THROTTLE = 2000,
  BOOKINGS_DELAY_FACTOR = 700,
  BOOKINGS_SEEK_ATLEAST = 5,
  BOOKINGS_SEEK_BOOKING = 4
}

// eslint-disable-next-line no-shadow
export enum Time {
  MIDNIGHT = '00:00:00',
  MIDNIGHT_PLUS_ONE_SECOND = '00:00:01',
  MIDDAY = '12:00:00',
  MIDDAY_MINUS_ONE_SECOND = '11:59:59',
  MIDNIGHT_MINUS_ONE_SECOND = '23:59:59',
  ELEVEN_PM = '23:00:00',
}

// eslint-disable-next-line no-shadow
export enum BookingZIndexLayers {
  BOOKING_MOBILE_FLOORPLAN_VIEW_FEATURE_POPOVER = 1150,
  BOOKING_MOBILE_FLOORPLAN_VIEW_OVERLAY = 1140,
  BOOKING_FLOORPLAN_VIEW_ZOOM_CONTROLS = 1120,
  BOOKING_MOBILE_FLOORPLAN_VIEW_BACK_BUTTON = 1110,
  BOOKING_FLOORPLAN_VIEW_FEATURE_POPOVER = 1105,
  BOOKING_BLOCK_AVAILABILITY_FEATURE_GRID = 1100,
  BOOKING_BLOCK_FEATURE_MIN_NAV_OVERLAY = 1050,
  BOOKING_DASHBOARD_FEATURE_GRID_VIEW_BLEND = 1010,
}
