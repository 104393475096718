import { DateRange } from '@mui/x-date-pickers-pro'
import { format } from 'date-fns'

import { RequestType } from '../../models'
import {
  CalloutDetails,
  EnhancementPostBody,
  EnhancementPutBody,
  SubmitStatus,
} from '../../models/enhancement'
import { AbsenceDayRequest } from '../../types/absence'

import { formatDateWithTimeZoneStr } from '../date-utils'
import { ConflictMatch } from '../../types/base-response'
import { tabType } from '../../components/Absence/types'
import { DateFormats } from '../../api/absence/tempTypes/generalprops'
import { MyRequestDetailResponse, RequestDayRequest } from '../../types/requests'

export interface BuilderArgs {
  requestType: RequestType
  employeeId: number
  dateRange: DateRange<Date>
  comments: string
  selectedDays: AbsenceDayRequest[] | null
  financialYear: string
  increase: boolean
  hours: number
  overrideConflicts: boolean
  alerts: ConflictMatch[] | null
  conflicts: boolean
  calloutDetails?: CalloutDetails
  project?: string
  selectedDate?: Date | null
  paidHoursOverride?: number
  calculatedPaidHours?: number
}

export const convertTabTypeForRequest = (tabToConvert: string) => {
  if (tabToConvert === 'Holiday (M)') return 'ForcedHoliday'
  if (tabToConvert === 'Lieu Day (M)') return 'LieuDay'
  if (tabToConvert === 'Work From Home') return 'WorkFromHome'
  if (tabToConvert === 'Maternity') return 'MaternityLeave'
  if (tabToConvert === 'Paternity') return 'PaternityLeave'
  if (tabToConvert === 'Night Shift Cover') return 'NightShiftCover'
  if (tabToConvert === 'Twilight Shift Cover') return 'TwilightShiftCover'
  if (tabToConvert === 'Overtime (M)') return 'Overtime'
  if (tabToConvert === 'Night (M)') return 'Night'
  if (tabToConvert === 'On Call (M)') return 'On Call'
  if (tabToConvert === 'Call Out (M)') return 'Call Out'
  return tabToConvert
}

export const buildEnhancementPostBody = (
  args: BuilderArgs,
  getDayHours: (day: { day?: Date | null; hours?: number }) => string
): EnhancementPostBody => {
  const body: EnhancementPostBody = {
    employeeId: args.employeeId,
    enhancementType: convertTabTypeForRequest(tabType(args.requestType)),
    dateRange: {
      start: format(args.dateRange[0]!, DateFormats.DATE_AND_TIME),
      end: format(args.dateRange[1]!, DateFormats.DATE_AND_TIME),
    },
    comments: args.comments,
    days:
      args.selectedDays &&
      args.selectedDays?.map(day => ({
        day: formatDateWithTimeZoneStr(day.day, 'yyyy-MM-dd'),
        hours:
          args.requestType === RequestType.ON_CALL || args.requestType === RequestType.ON_CALL_M
            ? parseFloat(getDayHours(day))
            : day.hours,
        checked: day.checked,
      })),
  }
  if (
    (args.requestType === RequestType.CALL_OUT || args.requestType === RequestType.CALL_OUT_M) &&
    args.calloutDetails
  ) {
    body.calloutDetails = args.calloutDetails
    body.paidHoursOverride = args.paidHoursOverride !== undefined ? Number(args.paidHoursOverride.toFixed(2)) : undefined
    body.calculatedPaidHours = args.calculatedPaidHours
  }
  if (args.project) body.project = args.project
  return body
}

export const buildEnhancementPutBody = ({
  requestId,
  dateFrom,
  dateTo,
  requestDays,
  requesterComments,
}: MyRequestDetailResponse & { requestDaysCr?: RequestDayRequest[] }): EnhancementPutBody => ({
  id: requestId!,
  dateRange: {
    start: format(new Date(dateFrom), DateFormats.DATE_AND_TIME),
    end: format(new Date(dateTo!), DateFormats.DATE_AND_TIME),
  },
  days: requestDays.map(day => ({
    day: format(new Date(day.day), 'yyyy-MM-dd'),
    hours: Number(day.hours),
    checked: day.checked,
  })),
  status: { submitStatus: SubmitStatus.PENDING, comments: requesterComments },
})
