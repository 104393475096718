import { Box, Container } from '@mui/material'
import { useCallback, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  setSelectedDepartment,
  setSelectedSettings,
  setUserSettings,
} from '../../redux/reducers/appSettingsReducer'
import { RootStore } from '../../redux/store'
import { settingsService } from '../../services/settingsService'
import Sidebar, { refreshFeatureToggles } from '../../shared/layout/Sidebar'
import SnackBar from '../../shared/UI/SnackBar'
import { SettingsMaintenanceRequest } from '../../types/settings-maintenance-request'
import { isDesktopUp, isMobileDown, isTabletUp } from '../../theme/deviceChecks'
import { setSelectedDepartmentFilter } from '../../redux/reducers/myActionsReducer'

import AppRoutes from '../../routes/AppRoutes'
import { SelectOption } from '../../services/dashboardService'

import { ResponsiveDetectionMobileState } from '../../types/responsive-detection-mobile'

interface AppBodyDivProps {
  showSideDrawer: boolean
  isMobileLayout: boolean
}

interface AppBodyContainerProps {
  isMobileLayout: boolean
}

const AppBodyDiv = memo(styled(Box)<AppBodyDivProps>`
  background-color: #f4f5f7;
  display: flex;

  ${isMobileDown()} {
    height: ${({ showSideDrawer }) => (showSideDrawer ? '100vh' : 'auto')};
  }

  ${isTabletUp()} {
    width: ${({ isMobileLayout }) => (isMobileLayout ? '100%' : 'calc(100vw - 34px)')};
  }
`)

const AppBodyContainer = memo(styled(Container)<AppBodyContainerProps>`
  &.MuiContainer-root {
    padding: 0;
  }

  ${isTabletUp()} {
    &.MuiContainer-root {
      padding: ${({ isMobileLayout }) => (isMobileLayout ? '0' : '32px')};
    }

    &.MuiContainer-root > .MuiGrid-root:not(.mobilePageHeader) {
      padding: ${({ isMobileLayout }) => (isMobileLayout ? '0 32px 32px' : '0')};
    }
  }

  ${isDesktopUp()} {
    &.MuiContainer-root {
      padding-bottom: 16px;
    }
  }
`)

export function AppBody() {

  useEffect(() => {
    refreshFeatureToggles()
  }, [])

  const dispatch = useDispatch()

  const {
    mobileDetection
  } = useSelector((state: RootStore) => state.responsiveDetection)
  const { isMobile, isMobileLayout }: ResponsiveDetectionMobileState = mobileDetection

  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.sideBarExpanded
  )

  const { departments, selectedDepartment, employeeDetails } = useSelector(
    (state: RootStore) => state.appSettings
  )

  const userDepartment = useSelector<RootStore, SelectOption | null>(
    (state: RootStore) => state.appSettings.loggedInEmployeeDepartment
  )

  const isUserUnallocatedDepartment = useCallback(
    (): boolean => userDepartment?.displayValue === 'Unallocated',
    [userDepartment]
  )

  useEffect(() => {
    if (!userDepartment) {
      return
    }
    if (
      departments.length > 1 &&
      departments.findIndex(dept => dept.associatedValue === 0 && dept.displayValue === 'All') < 0
    ) {
      departments.unshift({ ...departments[0], displayValue: 'All', value: 0 })
    }

    const allOption = departments.find(dept => dept.displayValue === 'All' && dept.value === 0)
    const toBeSelected = isUserUnallocatedDepartment()
      ? allOption ?? null
      : allOption ?? userDepartment

    dispatch(setSelectedDepartmentFilter(toBeSelected))
    dispatch(setSelectedDepartment(toBeSelected))
  }, [departments, dispatch, isUserUnallocatedDepartment, userDepartment])

  useEffect(() => {
    if (!employeeDetails || employeeDetails.departmentId === 0) {
      return
    }

    settingsService.getSettings(employeeDetails.departmentId).then(response => {
      const requestType: SettingsMaintenanceRequest = {
        errors: [],
        departmentId: employeeDetails.departmentId,
        isHolidayRequestAllowed: response.isHolidayRequestAllowed,
        isDayOffRequestAllowed: response.isDayOffRequestAllowed,
        isShiftRequestAllowed: response.isShiftRequestAllowed,
        hasEmailNotification: response.hasEmailNotification,
        hasEntitlementSeasonalSplit: response.hasEntitlementSeasonalSplit,
        isLieuDayRequestAllowed: response.isLieuDayRequestAllowed,
        isWfhRequestAllowed: response.isWfhRequestAllowed,
        applyHolidayChangesToRota: response.applyHolidayChangesToRota,
        isBirthdayRequestAllowed: response.isBirthdayRequestAllowed,
        isBuyRequestAllowed: response.isBuyRequestAllowed,
        isSellRequestAllowed: response.isSellRequestAllowed,
        areWeekendsWorked: response.areWeekendsWorked,
        hasEnhancements: response.hasEnhancements,
      }
      dispatch(setUserSettings(requestType))
    })
  }, [dispatch, employeeDetails, employeeDetails?.departmentId])

  useEffect(() => {
    if (!settingsService) {
      return
    }
    if (!selectedDepartment?.value) {
      return
    }
    settingsService.getSettings(selectedDepartment?.value).then(response => {
      const requestType: SettingsMaintenanceRequest = {
        errors: [],
        departmentId: selectedDepartment?.value,
        isHolidayRequestAllowed: response.isHolidayRequestAllowed,
        isDayOffRequestAllowed: response.isDayOffRequestAllowed,
        isShiftRequestAllowed: response.isShiftRequestAllowed,
        hasEmailNotification: response.hasEmailNotification,
        hasEntitlementSeasonalSplit: response.hasEntitlementSeasonalSplit,
        isLieuDayRequestAllowed: response.isLieuDayRequestAllowed,
        isWfhRequestAllowed: response.isWfhRequestAllowed,
        applyHolidayChangesToRota: response.applyHolidayChangesToRota,
        isBirthdayRequestAllowed: response.isBirthdayRequestAllowed,
        isBuyRequestAllowed: response.isBuyRequestAllowed,
        isSellRequestAllowed: response.isSellRequestAllowed,
        areWeekendsWorked: response.areWeekendsWorked,
        hasEnhancements: response.hasEnhancements,
      }
      dispatch(setSelectedSettings(requestType))
    })
  }, [dispatch, selectedDepartment])

  return (
    <AppBodyDiv showSideDrawer={showSideDrawer} isMobileLayout={isMobileLayout}>
      <>
        <Sidebar />
        <AppBodyContainer isMobileLayout={isMobile || isMobileLayout} maxWidth="xl">
          <AppRoutes />
        </AppBodyContainer>
      </>
      <SnackBar />
    </AppBodyDiv>
  )
}
