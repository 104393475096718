import { GridColDef, GridLinkOperator, GridRenderCellParams } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Grid, TextField, useMediaQuery } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageContext } from '../../../context/MyRequestsPageContext'
import {
  SetShowModalPayload,
  hideModal,
  showModalDialog,
} from '../../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../../redux/reducers/snackbarReducer'
import { RootStore } from '../../../redux/store'
import { availabilityService } from '../../../services/availabilityService'
import Drawer from '../../../shared/UI/Drawer'
import { statusTranslation } from '../../../shared/UI/EventPopup/utils'
import Modal from '../../../shared/UI/Modal'
import NoResult from '../../../shared/UI/NoResult'
import Paragraph from '../../../shared/UI/Paragraph/index'
import StatusLabel from '../../../shared/UI/StatusLabel'
import TypeLabel from '../../../shared/UI/TypeLabel'
import Card from '../../../shared/layout/Card'
import { isMobileDown, isMobilePortrait } from '../../../theme/deviceChecks'
import { getPalleteTypeByProp } from '../../../theme/palette'
import { BaseResponse } from '../../../types/base-response'
import { RequestDataType } from '../../../types/request-data-type'
import { getLocalDateString } from '../../../utils/date-utils'
import UserErrorMessage from '../../../utils/errorFilter'
import ViewEditOccurrence from '../ViewEditOccurrence'
import { DrawerComponentProps, MyRequestGridProps } from './types'
import { canDeleteApprovedRequest, canEditPendingRequest } from '../utils'
import { isEnhancement } from '../../../utils/SharedMethods/isEnhancement'
import { v2MyActionsService } from '../../../services/myActionsServiceV2'
import { useDefaultErrorHandler } from '../../../utils/Hooks/useDefaultErrorHandler'
import { getRequestStatusDisplayName } from '../../../utils/SharedMethods/getRequestStatusDisplayName'

function NoRowsOverlay() {
  return <NoResult message="No Requests" showImage />
}

export function MyRequestsGrid({ requestsData, onItemUpdated, filter }: MyRequestGridProps) {
  const [pageSize, setPageSize] = useState<number>(10)
  const [requestToDelete, setRequestToDelete] = useState<number>(0)
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps | null>(null)
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false)
  const [deleteComment, setDeleteComment] = useState('')
  const [disabled, setIsDisabled] = useState(true)
  const [cancellationRequested, setCancellationRequested] = useState<boolean>(false)
  const [showViewEditDrawer, setShowViewEditDrawer] = useState<boolean>(false)
  const [closeDrawerLoading, setCloseDrawerLoading] = useState<boolean>(false)
  const [cancelLoading, setCancelLoading] = useState<boolean>(false)
  const [fieldsTouched, setFieldsTouched] = useState({ deleteComments: false })
  const [queryRequested, setQueryRequested] = useState<boolean>(false)
  const [deletePending, setDeletePending] = useState<boolean>(false)
  const [currentRow, setCurrentRow] = useState<GridRenderCellParams | null>()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const location = useLocation()
  const defaultErrorHandler = useDefaultErrorHandler()
  const { getRequestsData } = useContext(PageContext)

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const mobile = useMediaQuery(isMobileDown())
  const isMobPortrait = useMediaQuery(isMobilePortrait())
  const autoFilter = useMemo(() => {
    if (!filter) {
      return []
    }
    if (location.state.previousLocation === '/dashboard') {
      return filter.map(flt => ({ columnField: 'status', operatorValue: 'contains', value: flt }))
    }
    return filter.map(flt => ({ columnField: 'type', operatorValue: 'contains', value: flt }))
  }, [filter])

  const deleteRequest = () => {
    const request = isEnhancement(currentRow?.row.type)
      ? v2MyActionsService.deleteEnhancement(requestToDelete)
      : availabilityService.deleteMyRequest(requestToDelete)
    request
      .then(e => {
        setCancelLoading(false)
        dispatch(showSuccessMessage('Request has been deleted'))
        dispatch(hideModal())
        onItemUpdated()
        setCurrentRow(null)
      })
      .catch(err => {
        setCancelLoading(false)
        defaultErrorHandler(err)
      })
  }
  const modalConfirmDelete = () => {
    setCancelLoading(true)

    if (deletePending) {
      deleteRequest()
    } else {
      availabilityService
        .cancelChangeRequest({
          requestId: requestToDelete,
          requestStatusId: 2,
        })
        .then(e => {
          let successMessage = 'Change request has been cancelled'
          if (cancellationRequested) {
            successMessage = 'Cancel request has been cancelled'
          }
          if (queryRequested) {
            successMessage = 'Query has been cancelled'
          }
          setCancelLoading(false)
          dispatch(showSuccessMessage(successMessage))
          dispatch(hideModal())
          onItemUpdated()
        })
        .catch(err => {
          setCancelLoading(false)
          defaultErrorHandler(err)
        })
    }
  }

  const modalRequestCancel = () => {
    setCancelLoading(true)
    availabilityService
      .cancelApprovedRequest({
        requestId: requestToDelete,
        cancelComments: deleteComment,
      })
      .then(e => {
        setCancelLoading(false)
        dispatch(showSuccessMessage('Cancel request has been sent'))
        dispatch(hideModal())
        onItemUpdated()
      })
      .catch(err => {
        setCancelLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const showDeleteRequestModal = (id: number, modalType: string) => {
    if (modalType === 'approved' || modalType === 'amended') {
      setShowCommentBox(true)
    } else {
      setShowCommentBox(false)
    }
    setDeleteComment('')
    setRequestToDelete(id)
    if (modalType === 'cancelCancellation') {
      setCancellationRequested(true)
    } else {
      setCancellationRequested(false)
    }
    if (modalType === 'pending') {
      setDeletePending(true)
    } else {
      setDeletePending(false)
    }
    if (modalType === 'queried') {
      setQueryRequested(true)
    } else {
      setQueryRequested(false)
    }
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to cancel this request?`,
        buttonLabel: 'Cancel Request',
        type: 'question',
        showModal: true,
      })
    )
  }

  const canQueryRequest = (requestType: string): boolean => {
    switch (requestType.toLowerCase()) {
      case 'holiday (m)':
        return true
      default:
        return false
    }
  }

  const canEditApprovedRequest = (requestType: string): boolean => {
    switch (requestType.toLowerCase()) {
      case 'holiday':
      case 'work from home':
        return true
      default:
        return false
    }
  }

  const calculateStatusLabel = (prop: any) => {
    if (!prop?.status) return

    if (prop?.isQueried) {
      return 'Queried'
    }
    return statusTranslation(prop.status)
  }

  const calculateCellValuesStatusLabel = (cellValues: RequestDataType) => {
    if (!cellValues.status) return

    if (cellValues.isQueried) {
      return 'Queried'
    }
    return getRequestStatusDisplayName(cellValues.status)
  }

  const hideDateTo = (requestType: string): boolean =>
    requestType === 'Shift' ||
    requestType === 'Day Off' ||
    requestType === 'Birthday' ||
    requestType === 'Buy' ||
    requestType === 'Sell'

  const getColumnTestId = (columnName: string): string => `MyAvailability-Grid-${columnName}`

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Type',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: cellValues => (
        <TypeLabel
          label={
            cellValues.row.requestTypeId === 4 || cellValues.row.type === 'Manual'
              ? cellValues.row.manualRequestType
              : getPalleteTypeByProp(cellValues.row.type).label
          }
          type={cellValues.row.type}
        />
      ),
      sortable: true,
    },
    {
      field: 'dateFrom',
      headerName: 'Date from',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: cellValues => getLocalDateString(cellValues.value),
      sortable: true,
    },
    {
      field: 'dateTo',
      headerName: 'Date to',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: cellValues =>
        hideDateTo(cellValues.row.type)
          ? ''
          : cellValues.value && getLocalDateString(cellValues.value),
    },
    {
      field: 'hours',
      headerName: 'Hours',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: cellValues =>
        cellValues.row.type.toLowerCase() === 'late' ? (
          <Paragraph>
            {`${cellValues.row.hours !== 0 && Math.round(cellValues.row.hours * 60)} minutes lost`}
          </Paragraph>
        ) : (
          <Paragraph>{cellValues.row.hours !== 0 && cellValues.row.hours}</Paragraph>
        ),
      sortable: false,
    },
    {
      field: 'dateSubmitted',
      headerName: 'Date Submitted',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: cellValues => (
        <Paragraph>{getLocalDateString(cellValues.row.dateSubmitted)}</Paragraph>
      ),
      sortable: true,
    },
    {
      field: 'actionedBy',
      headerName: 'Actioned By',
      headerClassName: 'header',
      flex: 1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: cellValues => <Paragraph>{cellValues.row.actionedBy}</Paragraph>,
      sortable: true,
    },
    {
      field: 'actionedDate',
      headerName: 'Actioned Date',
      headerClassName: 'header',
      type: 'date',
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      flex: 1,
      renderCell: cellValues =>
        cellValues.row.actionedDate ? getLocalDateString(cellValues.row.actionedDate) : '',
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1.1,
      renderHeader: params => (
        <div
          style={{ fontWeight: 'bold', color: 'black' }}
          data-testid={getColumnTestId(params.field)}
        >
          {params.colDef.headerName}
        </div>
      ),
      renderCell: cellValues => (
        <StatusLabel
          status={
            cellValues.row.isCancellation
              ? 'Cancellation Requested'
              : calculateCellValuesStatusLabel(cellValues.row)
          }
        />
      ),
      sortable: true,
    },
    {
      field: 'icons',
      headerName: '',
      headerClassName: 'header',
      width: 120,
      minWidth: 120,
      align: 'right',
      sortable: false,
      disableColumnMenu: true,
      renderCell: cellValues => {
        if (canQueryRequest(cellValues.row.type) && cellValues.row.type !== 'Holiday (M)') {
          return (
            <IconButton onClick={event => event.stopPropagation()}>
              <HelpIcon color="primary" />
            </IconButton>
          )
        }

        const status = getRequestStatusDisplayName(cellValues.row.status)
        switch (status) {
          case 'Change Requested':
            if (cellValues.row.isQueried) {
              return (
                <>
                  {cellValues.row.type === 'Holiday (M)' && (
                    <IconButton
                      onClick={event => {
                        event.stopPropagation()
                        showDeleteRequestModal(cellValues.row.id, 'queried')
                      }}
                    >
                      <CancelIcon color="primary" />
                    </IconButton>
                  )}
                </>
              )
            }
            return (
              <IconButton
                onClick={event => {
                  event.stopPropagation()
                  showDeleteRequestModal(
                    cellValues.row.id,
                    `cancel${cellValues.row.isCancellation ? 'Cancellation' : ''}`
                  )
                }}
              >
                <CancelIcon color="primary" />
              </IconButton>
            )

          case 'Pending':
            return (
              <>
                {canEditPendingRequest(cellValues.row.type, cellValues.row.status) &&
                  cellValues.row.type !== 'Holiday (M)' && (
                    <IconButton onClick={event => event.stopPropagation()}>
                      <EditIcon
                        color="primary"
                        onClick={event => {
                          event.stopPropagation()
                          setDrawerComponent({
                            title: cellValues.row.type,
                            component: (
                              <ViewEditOccurrence
                                id={cellValues.row.id}
                                requestType={cellValues.row.type}
                                closeViewEditDrawer={async () => {
                                  setCloseDrawerLoading(true)
                                  await availabilityService.deleteRequestLock(cellValues.row.id)
                                  setCloseDrawerLoading(false)
                                  setShowViewEditDrawer(false)
                                }}
                                closeLoading={closeDrawerLoading}
                              />
                            ),
                            status: cellValues.row.status,
                            id: cellValues.row.id,
                          })
                          setShowViewEditDrawer(true)
                        }}
                        data-testid="MyAvailability-Grid-EditIcon"
                      />
                    </IconButton>
                  )}
                {cellValues.row.type === 'Holiday (M)' && (
                  <IconButton onClick={event => event.stopPropagation()}>
                    <HelpIcon
                      color="primary"
                      onClick={event => {
                        event.stopPropagation()
                        setDrawerComponent({
                          title: cellValues.row.type,
                          component: (
                            <ViewEditOccurrence
                              id={cellValues.row.id}
                              requestType={cellValues.row.type}
                              closeViewEditDrawer={async () => {
                                setCloseDrawerLoading(true)
                                await availabilityService.deleteRequestLock(cellValues.row.id)
                                setCloseDrawerLoading(false)
                                setShowViewEditDrawer(false)
                              }}
                              closeLoading={closeDrawerLoading}
                              isHolidayManualRequest
                            />
                          ),
                          status: cellValues.row.status,
                          id: cellValues.row.id,
                        })
                        setShowViewEditDrawer(true)
                      }}
                    />
                  </IconButton>
                )}
                {cellValues.row.type !== 'Holiday (M)' && (
                  <IconButton
                    onClick={event => {
                      event.stopPropagation()
                      setCurrentRow(cellValues)
                      showDeleteRequestModal(cellValues.row.id, cellValues.row.status.toLowerCase())
                    }}
                    data-testid={`delete-request-${cellValues.row.id}`}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                )}
              </>
            )
          case 'Amended':
            return (
              <>
                {canEditPendingRequest(cellValues.row.type, cellValues.row.status) &&
                  cellValues.row.type !== 'Holiday (M)' && (
                    <IconButton onClick={event => event.stopPropagation()}>
                      <EditIcon
                        color="primary"
                        onClick={event => {
                          event.stopPropagation()
                          setDrawerComponent({
                            title: cellValues.row.type,
                            component: (
                              <ViewEditOccurrence
                                id={cellValues.row.id}
                                requestType={cellValues.row.type}
                                closeViewEditDrawer={async () => {
                                  setCloseDrawerLoading(true)
                                  await availabilityService.deleteRequestLock(cellValues.row.id)
                                  setCloseDrawerLoading(false)
                                  setShowViewEditDrawer(false)
                                }}
                                closeLoading={closeDrawerLoading}
                              />
                            ),
                            status: cellValues.row.status,
                            id: cellValues.row.id,
                          })
                          setShowViewEditDrawer(true)
                        }}
                        data-testid="MyAvailability-Grid-EditIcon"
                      />
                    </IconButton>
                  )}
                {cellValues.row.type === 'Holiday (M)' && (
                  <IconButton onClick={event => event.stopPropagation()}>
                    <HelpIcon
                      color="primary"
                      onClick={event => {
                        event.stopPropagation()
                        setDrawerComponent({
                          title: cellValues.row.type,
                          component: (
                            <ViewEditOccurrence
                              id={cellValues.row.id}
                              requestType={cellValues.row.type}
                              closeViewEditDrawer={async () => {
                                setCloseDrawerLoading(true)
                                await availabilityService.deleteRequestLock(cellValues.row.id)
                                setCloseDrawerLoading(false)
                                setShowViewEditDrawer(false)
                              }}
                              closeLoading={closeDrawerLoading}
                              isHolidayManualRequest
                            />
                          ),
                          status: cellValues.row.status,
                          id: cellValues.row.id,
                        })
                        setShowViewEditDrawer(true)
                      }}
                    />
                  </IconButton>
                )}
                {!(cellValues.row.type === 'Holiday (M)' || isEnhancement(cellValues.row.type)) && (
                  <IconButton
                    onClick={event => {
                      event.stopPropagation()
                      showDeleteRequestModal(cellValues.row.id, cellValues.row.status.toLowerCase())
                    }}
                    data-testid={`delete-request-${cellValues.row.id}`}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                )}
              </>
            )
          case 'Approved':
            return (
              <>
                {canEditApprovedRequest(cellValues.row.type) && (
                  <IconButton onClick={event => event.stopPropagation()}>
                    <EditIcon
                      color="primary"
                      onClick={event => {
                        event.stopPropagation()
                        setDrawerComponent({
                          title: cellValues.row.type,
                          component: (
                            <ViewEditOccurrence
                              id={cellValues.row.id}
                              requestType={cellValues.row.type}
                              closeViewEditDrawer={async () => {
                                setCloseDrawerLoading(true)
                                await availabilityService.deleteRequestLock(cellValues.row.id)
                                setCloseDrawerLoading(false)
                                setShowViewEditDrawer(false)
                              }}
                              closeLoading={closeDrawerLoading}
                            />
                          ),
                          status: cellValues.row.status,
                          id: cellValues.row.id,
                        })
                        setShowViewEditDrawer(true)
                      }}
                    />
                  </IconButton>
                )}
                {canDeleteApprovedRequest(cellValues.row.type) && (
                  <IconButton
                    onClick={event => {
                      event.stopPropagation()
                      showDeleteRequestModal(cellValues.row.id, 'approved')
                    }}
                    data-testid={`delete-request-${cellValues.row.id}`}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                )}
                {cellValues.row.type === 'Holiday (M)' && (
                  <IconButton onClick={event => event.stopPropagation()}>
                    <HelpIcon
                      color="primary"
                      onClick={event => {
                        event.stopPropagation()
                        setDrawerComponent({
                          title: cellValues.row.type,
                          component: (
                            <ViewEditOccurrence
                              id={cellValues.row.id}
                              requestType={cellValues.row.type}
                              closeViewEditDrawer={async () => {
                                setCloseDrawerLoading(true)
                                await availabilityService.deleteRequestLock(cellValues.row.id)
                                setCloseDrawerLoading(false)
                                setShowViewEditDrawer(false)
                              }}
                              closeLoading={closeDrawerLoading}
                              isHolidayManualRequest
                            />
                          ),
                          status: cellValues.row.status,
                          id: cellValues.row.id,
                        })
                        setShowViewEditDrawer(true)
                      }}
                    />
                  </IconButton>
                )}
              </>
            )
          case 'Cancelled':
            return <></>
          default:
            return <></>
        }
      },
    },
  ]

  const mobileColumns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Type',
      headerClassName: 'header',
      width: 140,
      minWidth: 140,
      disableColumnMenu: true,
      renderCell: cellValues => (
        <TypeLabel
          label={
            cellValues.row.requestTypeId === 4 || cellValues.row.type === 'Manual'
              ? cellValues.row.manualRequestType
              : cellValues.row.type
          }
          type={cellValues.row.type}
        />
      ),
      sortable: true,
    },
    {
      field: 'dateFrom',
      headerName: 'Date from',
      headerClassName: 'header',
      type: 'date',
      width: 120,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: cellValues => getLocalDateString(cellValues.value),
      sortable: true,
    },
    {
      field: 'dateTo',
      headerName: 'Date to',
      headerClassName: 'header',
      type: 'date',
      width: 120,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: cellValues =>
        hideDateTo(cellValues.row.type)
          ? ''
          : cellValues.value && getLocalDateString(cellValues.value),
      sortable: true,
    },
    {
      field: 'hours',
      headerName: 'Hours',
      headerClassName: 'header',
      width: 80,
      minWidth: 80,
      disableColumnMenu: true,
      renderCell: cellValues => <Paragraph>{cellValues.row.hours}</Paragraph>,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'header',
      width: 140,
      minWidth: 140,
      disableColumnMenu: true,
      renderCell: cellValues => <StatusLabel status={cellValues.row.status} />,
      sortable: true,
    },
    {
      field: 'icons',
      headerName: '',
      headerClassName: 'header',
      width: 100,
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: cellValues => {
        if (canQueryRequest(cellValues.row.type)) {
          return (
            <IconButton onClick={event => event.stopPropagation()}>
              <HelpIcon color="primary" />
            </IconButton>
          )
        }

        switch (cellValues.row.status) {
          case 'Change Requested':
            return (
              <IconButton
                onClick={event => {
                  event.stopPropagation()
                  showDeleteRequestModal(cellValues.row.id, 'cancel')
                }}
              >
                <CancelIcon color="primary" />
              </IconButton>
            )
          case 'Pending':
          case 'Amended':
            return (
              <>
                {canEditPendingRequest(cellValues.row.type) && (
                  <IconButton onClick={event => event.stopPropagation()}>
                    <EditIcon
                      color="primary"
                      onClick={event => {
                        event.stopPropagation()
                        setDrawerComponent({
                          title: cellValues.row.type,
                          component: (
                            <ViewEditOccurrence
                              id={cellValues.row.id}
                              requestType={cellValues.row.requestType}
                              closeViewEditDrawer={async () => {
                                setCloseDrawerLoading(true)
                                await availabilityService.deleteRequestLock(cellValues.row.id)
                                setCloseDrawerLoading(false)
                                setShowViewEditDrawer(false)
                              }}
                              closeLoading={closeDrawerLoading}
                            />
                          ),
                          status: cellValues.row.status,
                          id: cellValues.row.id,
                        })
                        setShowViewEditDrawer(true)
                      }}
                    />
                  </IconButton>
                )}
                <IconButton
                  onClick={event => {
                    event.stopPropagation()
                    showDeleteRequestModal(cellValues.row.id, cellValues.row.status.toLowerCase())
                  }}
                  data-testid={`delete-request-${cellValues.row.id}`}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </>
            )
          case 'Approved':
            return (
              <>
                {canEditApprovedRequest(cellValues.row.type) && (
                  <IconButton onClick={event => event.stopPropagation()}>
                    <EditIcon
                      color="primary"
                      onClick={event => {
                        event.stopPropagation()
                        setDrawerComponent({
                          title: cellValues.row.type,
                          component: (
                            <ViewEditOccurrence
                              id={cellValues.row.id}
                              requestType={cellValues.row.type}
                              closeViewEditDrawer={async () => {
                                setCloseDrawerLoading(true)
                                await availabilityService.deleteRequestLock(cellValues.row.id)
                                setCloseDrawerLoading(false)
                                setShowViewEditDrawer(false)
                              }}
                              closeLoading={closeDrawerLoading}
                            />
                          ),
                          status: cellValues.row.status,
                          id: cellValues.row.id,
                        })
                        setShowViewEditDrawer(true)
                      }}
                    />
                  </IconButton>
                )}
                {canDeleteApprovedRequest(cellValues.row.type) && (
                  <IconButton
                    onClick={event => {
                      event.stopPropagation()
                      showDeleteRequestModal(cellValues.row.id, 'approved')
                    }}
                    data-testid={`delete-request-${cellValues.row.id}`}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                )}
              </>
            )
          case 'Cancelled':
            return <></>
          default:
            return <></>
        }
      },
    },
  ]

  useEffect(() => {
    if (showCommentBox === true) {
      setIsDisabled(!deleteComment)
    } else {
      setIsDisabled(false)
    }
  }, [deleteComment, showCommentBox])

  const content = (
    <Card>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
          setDeleteComment('')
          setCurrentRow(null)
          setFieldsTouched({ ...fieldsTouched, deleteComments: false })
        }}
        onClick={showCommentBox ? () => modalRequestCancel() : () => modalConfirmDelete()}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
        maxWidth="md"
        buttonLoading={cancelLoading}
        isDisabled={disabled}
        dropdown={
          showCommentBox ? (
            <Grid container>
              <Grid item xs={12} md={12} spacing={4} mt={4}>
                <Grid mt={2}>
                  <TextField
                    label="Comments"
                    value={deleteComment}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={e => {
                      setDeleteComment(e.target.value)
                    }}
                    onBlur={() => setFieldsTouched({ ...fieldsTouched, deleteComments: true })}
                    error={fieldsTouched.deleteComments && deleteComment === ''}
                    helperText="Required"
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )
        }
      />
      <Drawer
        isOpen={showViewEditDrawer}
        onClose={async () => {
          getRequestsData()
          setShowViewEditDrawer(false)
          await availabilityService.deleteRequestLock(Number(drawerComponent?.id))
        }}
        title={drawerComponent?.title || ''}
        status={drawerComponent?.status || ''}
        showOptions={false}
        mobileHeight={
          drawerComponent?.title === 'Day Off' || drawerComponent?.title === 'Shift'
            ? '620px'
            : undefined
        }
      >
        <Grid item p={isMobPortrait ? '32px 0 0 0' : 4}>
          {drawerComponent?.component}
        </Grid>
      </Drawer>
      <DataGridPro
        getRowId={row => row.id}
        rows={requestsData}
        columns={mobile ? mobileColumns : columns}
        disableSelectionOnClick
        disableVirtualization
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowHeight={50}
        autoHeight
        components={{ NoRowsOverlay }}
        onRowClick={params => {
          if (params.row.type.toLowerCase() === 'absence') {
            navigate(`/myavailability/absenceoccurrence/${params.row.id}`, {
              state: { previousLocation: '/myavailability', calendar: false },
            })
          }
          if (params.row.type.toLowerCase() === 'late') {
            navigate(`/myavailability/lateoccurrence/${params.row.id}`, {
              state: { previousLocation: '/myavailability', calendar: false },
            })
          }
          setDrawerComponent({
            title: params.row.type,
            component: (
              <ViewEditOccurrence
                id={params.row.id}
                readonlyOverride
                requestType={params.row.type}
                closeViewEditDrawer={async () => {
                  setCloseDrawerLoading(true)
                  await availabilityService.deleteRequestLock(Number(params.row.id))
                  setCloseDrawerLoading(false)
                  setShowViewEditDrawer(false)
                }}
                closeLoading={closeDrawerLoading}
                isCancellation={params.row.isCancellation}
              />
            ),
            status: params.row.isCancellation
              ? 'Cancellation Requested'
              : calculateStatusLabel(params.row),
            id: params.row.id,
          })
          setShowViewEditDrawer(true)
        }}
        pinnedColumns={{ left: mobile ? ['type'] : [] }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'dateFrom', sort: 'asc' }],
          },
          filter: {
            filterModel: {
              items: autoFilter,
              linkOperator: GridLinkOperator.Or,
            },
          },
        }}
        sx={{
          '&.MuiDataGrid-root': {
            border: 'none !important ',
            overflow: 'none',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            minHeight: '200px!important',
          },
          '.header': {
            backgroundColor: 'rgb(247, 247, 247)',
            height: '54px',
          },
          cursor: 'pointer',
        }}
        componentsProps={{
          panel: {
            sx: {
              '& .MuiNativeSelect-select option': {
                font: '13px "Poppins", sans-serif',
              },
            },
          },
        }}
        data-testid={getColumnTestId}
      />
    </Card>
  )

  return content
}
