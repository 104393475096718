import { DateRange } from '@mui/x-date-pickers-pro'
import { CalloutDetails } from '../../models/enhancement'

export interface FormValuesType extends CalloutDetails {
  start?: Date
  end?: Date
}
export type ValidationErrors = {
  [key: string]: boolean
}
export function validateFormValues(
  dateRange: DateRange<Date>,
  calloutDetails: CalloutDetails
): {
  isValid: boolean
  validationErrors: ValidationErrors
} {
  const [start, end] = dateRange
  const {
    calledOutBy,
    issueType,
    escalationRequired,
    escalatedTo,
    escalationType,
    transferToAnotherTeam,
    team,
    reason,
    conclusion,
    jiraReference,
    product,
  } = calloutDetails

  const validationErrors: ValidationErrors = {
    start: !start,
    end: !end,
    calledOutBy: !calledOutBy,
    issueType: !issueType,
    reason: !reason,
    conclusion: !conclusion,
    jiraReference: !jiraReference,
    product: !product,
  }

  // Check escalationRequired fields if escalation required
  if (escalationRequired) {
    validationErrors.escalationType = !escalationType
    validationErrors.escalatedTo = !escalatedTo
  }

  // Check 'team' if transferToAnotherTeam is true
  if (transferToAnotherTeam) validationErrors.team = !team

  return {
    isValid: Object.values(validationErrors).every(value => value === false),
    validationErrors,
  }
}
