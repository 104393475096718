
import { useMediaQuery } from '@mui/material'

import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'

import { isMobileDown, isMobileLandscapeAndTablet } from '../../theme/deviceChecks'

import {
  setResponsiveDetection
} from '../../redux/reducers/responsiveDetectionReducer'


const RESPONSIVE_DELAY_MS = 200

export function ResponsiveDetection() {

  const [throttle, setThrottle] = useState<boolean>(false)
  const [layoutSwitch, setLayoutSwitch] = useState<boolean>(false)
  const [firstLoad, setFirstLoad] = useState<boolean>(false)

  const dispatch = useDispatch()

  const mobilePortait = useMediaQuery(isMobileDown())
  const detectOritentationLandscape = useMediaQuery('(orientation: landscape)')
  const detectMobileDeviceR2 = useMediaQuery('(-webkit-device-pixel-ratio: 2)')
  const detectMobileDeviceR3 = useMediaQuery('(-webkit-device-pixel-ratio: 3)')
  const detectMobileLandscaopeMaxWidth = useMediaQuery('(max-width: 940px)')
  const detectMobileDevicePixels = useMemo(() => (
    detectMobileLandscaopeMaxWidth && (
      detectMobileDeviceR2 || detectMobileDeviceR3
    )
  ), [
    detectMobileLandscaopeMaxWidth,
    detectMobileDeviceR2,
    detectMobileDeviceR3
  ])
  const mobileDeviceLandscape = useMediaQuery(isMobileLandscapeAndTablet())
  const detectMobileDeviceLandscape = useMemo(() => (
    detectMobileLandscaopeMaxWidth && detectOritentationLandscape
  ), [
    detectMobileLandscaopeMaxWidth,
    detectOritentationLandscape
  ])
  const mobileLandscape = useMemo(() =>
    mobileDeviceLandscape && (
      detectMobileDevicePixels || detectMobileDeviceLandscape
    )
  , [
    mobileDeviceLandscape,
    detectMobileDevicePixels,
    detectMobileDeviceLandscape
  ])

  const isMobileLayout = useMemo(() =>
    mobilePortait || mobileLandscape || isMobile,
  [mobilePortait, mobileLandscape])

  const notReadyForResponsive = useMemo(() =>
    !firstLoad && throttle && !layoutSwitch,
  [firstLoad, throttle, layoutSwitch])

  useEffect(() =>{

    if (notReadyForResponsive) return

    const timeoutThrottle = setTimeout(() =>
      setThrottle(false), RESPONSIVE_DELAY_MS
    )

    dispatch(setResponsiveDetection({
      isMobile,
      isMobileLayout,
      mobilePortait,
      mobileLandscape,
      detectMobileDeviceLandscape,
      detectOritentationLandscape
    }))

    setLayoutSwitch(!!isMobileLayout)
    setThrottle(true)

    if (!firstLoad) setFirstLoad(true)

    return () => {
      setThrottle(false)
      clearTimeout(timeoutThrottle)
    }
  },[
    dispatch,
    notReadyForResponsive,
    isMobileLayout,
    mobilePortait,
    mobileLandscape,
    detectMobileDeviceLandscape,
    detectOritentationLandscape,
    firstLoad
  ])

  return <></>

}
