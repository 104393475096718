import { css, keyframes } from 'styled-components'
import styled from '@emotion/styled'
import { Fab, FabProps } from '@mui/material'

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 0.9;
  }
`

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0.9;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`

export const FabBackToTop = styled(Fab)`
  ${(props: FabProps) => `
    & {
      background: white;
      color: #747474;
      visibility: ${props.disabled ? 'hidden' : 'visible'};
      animation: ${() => 
        props.disabled ?
          css`${fadeOut} 2s linear`
        :
          css`${fadeIn} 3s linear`
      }
    }
  `}
`
