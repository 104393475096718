import { addMonths, startOfMonth } from 'date-fns'
import {
  BookingEmployeeBooking,
  BookingFloorPlan,
  BookingLocation,
  BookingZone,
  BookingFeature,
  MostUsedDesk,
  BookingSliderPosition as BookingSlider,
} from '../../services/booking/types'
import { BookingSliderPosition } from '../../utils/constants'
import { BookingListMaxMonths } from '../../components/Booking/Shared/enums'

export interface DeskBookingState {
  gridViewingDate: Date
  floorplanViewingDate: Date
  locations: BookingLocation[]
  floorplans: BookingFloorPlan[]
  featuresForFloorPlan?: BookingFeature[] | null
  zones: BookingZone[]
  openFloorplan: boolean
  showGridView: boolean
  showFloorplan: boolean
  showNavBar: boolean
  showSearch: boolean
  showFilter: boolean
  showBlockBookingWizard: boolean
  showBlockBookingSummary: boolean
  dashboardResults: { lastUpdated: Date; results: BookingEmployeeBooking[] } | null
  infinityBookingsResults: { lastUpdated: Date; results: BookingEmployeeBooking[] } | null
  bookingIsLoading: boolean
  focussedZoneID?: number
  floorplanManuallySelected: boolean
  todaysBooking?: BookingEmployeeBooking
  showMeBookingID?: number
  mostUsedDesk?: MostUsedDesk
  forwardDeskBookingsCount: number | undefined
  checkedIn: boolean
  maxBookingDate: Date
  bookingSliderPosition: BookingSlider
  showScrollToTopButton: boolean
}

export const deskBookingStateInitialState: DeskBookingState = {
  gridViewingDate: startOfMonth(new Date()),
  floorplanViewingDate: new Date(),
  locations: [],
  floorplans: [],
  zones: [],
  openFloorplan: false,
  showGridView: true,
  showFloorplan: false,
  showNavBar: true,
  showSearch: true,
  showFilter: false,
  showBlockBookingWizard: false,
  showBlockBookingSummary: false,
  dashboardResults: null,
  infinityBookingsResults: null,
  bookingIsLoading: false,
  floorplanManuallySelected: false,
  forwardDeskBookingsCount: undefined,
  mostUsedDesk: undefined,
  todaysBooking: { id: 0 } as BookingEmployeeBooking,
  checkedIn: false,
  maxBookingDate: addMonths(new Date(), BookingListMaxMonths.BOOKINGS_MAX_MONTHS),
  bookingSliderPosition: {
    slidePosition: BookingSliderPosition.BOOKINGS,
    buttonClick: false,
  } as BookingSlider,
  showScrollToTopButton: false,
}

const SET_GRID_VIEWING_DATE = 'SET_GRID_VIEWING_DATE'
const SET_FLOORPLAN_VIEWING_DATE = 'SET_FLOORPLAN_VIEWING_DATE'
const SET_LOCATIONS = 'SET_LOCATIONS'
const SET_FLOORPLANS = 'SET_FLOORPLANS'
const SET_FEATURES_FOR_FLOORPLAN = 'SET_FEATURES_FOR_FLOORPLAN'
const SET_ZONES = 'SET_ZONES'
const SET_OPEN_FLOORPLAN = 'SET_OPEN_FLOORPLAN'
const SET_SHOW_GRID_VIEW = 'SET_SHOW_GRID_VIEW'
const SET_SHOW_FLOORPLAN = 'SET_SHOW_FLOORPLAN'
const SET_SHOW_NAV_BAR = 'SET_SHOW_NAV_BAR'
const SET_SHOW_SEARCH_COMP = 'SET_SHOW_SEARCH_COMP'
const SET_SHOW_FILTER_COMP = 'SET_SHOW_FILTER_COMP'
const SET_SHOW_BLOCK_BOOKING_WIZARD = 'SET_SHOW_BLOCK_BOOKING_WIZARD'
const SET_SHOW_BLOCK_BOOKING_SUMMARY = 'SET_SHOW_BLOCK_BOOKING_SUMMARY'
const SET_FOCUSSED_ZONE_ID = 'SET_FOCUSSED_ZONE_ID'
const SET_DASHBOARD_RESULTS = 'SET_DASHBOARD_RESULTS'
const SET_INFINITY_BOOKINGS_RESULTS = 'SET_INFINITY_BOOKINGS_RESULTS'
const SET_LOADING = 'SET_LOADING'
const SET_FLOORPLAN_MANUALLY_SELECTED = 'SET_FLOORPLAN_MANUALLY_SELECTED'
const SET_TODAYS_BOOKING = 'SET_TODAYS_BOOKING'
const SET_SHOW_ME_BOOKING_ID = 'SET_SHOW_ME_BOOKING_ID'
const SET_FORWARD_DESK_BOOKINGS_COUNT = 'SET_FORWARD_DESK_BOOKINGS_COUNT'
const SET_MOST_USED_DESK = 'SET_MOST_USED_DESK'
const SET_CHECKED_IN = 'SET_CHECKED_IN'
const SET_MAX_BOOKING_DATE = 'SET_MAX_BOOKING_DATE'
const SET_BOOKING_SLIDER_POSITION = 'SET_BOOKING_SLIDER_POSITION'
const SET_INITIALISE = 'SET_INITIALISE'
const SET_SCROLL_TO_TOP= 'SET_SCROLL_TO_TOP'

interface SetDeskBookingGridViewDateState {
  type: typeof SET_GRID_VIEWING_DATE
  payload: Date
}

interface SetDeskBookingFloorplanViewDateState {
  type: typeof SET_FLOORPLAN_VIEWING_DATE
  payload: Date
}

interface SetDeskBookingLocationsState {
  type: typeof SET_LOCATIONS
  payload: BookingLocation[]
}

interface SetDeskBookingFloorplansState {
  type: typeof SET_FLOORPLANS
  payload: BookingFloorPlan[]
}

interface SetDeskBookingFeaturesForFloorplan {
  type: typeof SET_FEATURES_FOR_FLOORPLAN
  payload: BookingFeature[] | null
}

interface SetDeskBookingZonesState {
  type: typeof SET_ZONES
  payload: BookingZone[]
}

interface SetDeskBookingOpenFloorplan {
  type: typeof SET_OPEN_FLOORPLAN
  payload: boolean
}

interface SetDeskBookingShowGridView {
  type: typeof SET_SHOW_GRID_VIEW
  payload: boolean
}

interface SetDeskBookingShowFloorplan {
  type: typeof SET_SHOW_FLOORPLAN
  payload: boolean
}

interface SetDeskBookingShowNavBar {
  type: typeof SET_SHOW_NAV_BAR
  payload: boolean
}

interface SetDeskBookingShowSearchComponent {
  type: typeof SET_SHOW_SEARCH_COMP
  payload: boolean
}

interface SetDeskBookingShowFilterComponent {
  type: typeof SET_SHOW_FILTER_COMP
  payload: boolean
}

interface SetDeskBookingShowBlockBookingWizard {
  type: typeof SET_SHOW_BLOCK_BOOKING_WIZARD
  payload: boolean
}

interface SetDeskBookingShowBlockBookingSummary {
  type: typeof SET_SHOW_BLOCK_BOOKING_SUMMARY
  payload: boolean
}

interface SetDeskBookingDashboardResults {
  type: typeof SET_DASHBOARD_RESULTS
  payload: { lastUpdated: Date; results: BookingEmployeeBooking[] } | null
}

interface SetDeskBookingInfinityBookingsResults {
  type: typeof SET_INFINITY_BOOKINGS_RESULTS
  payload: { lastUpdated: Date; results: BookingEmployeeBooking[] } | null
}

interface SetDeskBookingLoading {
  type: typeof SET_LOADING
  payload: boolean
}

interface SetDeskBookingFocussedZoneID {
  type: typeof SET_FOCUSSED_ZONE_ID
  payload: number
}

interface SetDeskBookingFloorplanManuallySelected {
  type: typeof SET_FLOORPLAN_MANUALLY_SELECTED
  payload: boolean
}

interface SetDeskBookingTodaysBooking {
  type: typeof SET_TODAYS_BOOKING
  payload: BookingEmployeeBooking
}

interface SetDeskBookingShowMeBookingID {
  type: typeof SET_SHOW_ME_BOOKING_ID
  payload: number | undefined
}

interface SetForwardDeskBookingsCount {
  type: typeof SET_FORWARD_DESK_BOOKINGS_COUNT
  payload: number | undefined
}

interface SetMostUsedDesk {
  type: typeof SET_MOST_USED_DESK
  payload: MostUsedDesk | undefined
}

interface SetCheckedIn {
  type: typeof SET_CHECKED_IN
  payload: boolean
}

interface SetMaxBookingDate {
  type: typeof SET_MAX_BOOKING_DATE
  payload: Date
}

interface SetBookingSliderPosition {
  type: typeof SET_BOOKING_SLIDER_POSITION
  payload: BookingSlider
}

interface SetInitialise {
  type: typeof SET_INITIALISE
}

interface SetShowScrollToTopButton {
  type: typeof SET_SCROLL_TO_TOP
  payload: boolean
}

type deskBookingDespatchTypes =
  | SetDeskBookingGridViewDateState
  | SetDeskBookingFloorplanViewDateState
  | SetDeskBookingLocationsState
  | SetDeskBookingFloorplansState
  | SetDeskBookingFeaturesForFloorplan
  | SetDeskBookingZonesState
  | SetDeskBookingOpenFloorplan
  | SetDeskBookingShowGridView
  | SetDeskBookingShowFloorplan
  | SetDeskBookingShowNavBar
  | SetDeskBookingShowSearchComponent
  | SetDeskBookingShowFilterComponent
  | SetDeskBookingShowBlockBookingWizard
  | SetDeskBookingShowBlockBookingSummary
  | SetDeskBookingDashboardResults
  | SetDeskBookingInfinityBookingsResults
  | SetDeskBookingLoading
  | SetDeskBookingFocussedZoneID
  | SetDeskBookingFloorplanManuallySelected
  | SetDeskBookingTodaysBooking
  | SetDeskBookingShowMeBookingID
  | SetForwardDeskBookingsCount
  | SetMostUsedDesk
  | SetCheckedIn
  | SetMaxBookingDate
  | SetBookingSliderPosition
  | SetInitialise
  | SetShowScrollToTopButton

export const setBookingGridViewingDate = (date: Date): SetDeskBookingGridViewDateState => ({
  type: SET_GRID_VIEWING_DATE,
  payload: date,
})

export const setBookingFloorplanViewingDate = (
  date: Date
): SetDeskBookingFloorplanViewDateState => ({
  type: SET_FLOORPLAN_VIEWING_DATE,
  payload: date,
})

export const setDeskBookingLocationsState = (
  locations: BookingLocation[]
): SetDeskBookingLocationsState => ({
  type: SET_LOCATIONS,
  payload: locations,
})

export const setDeskBookingFloorplansState = (
  floorplans: BookingFloorPlan[]
): SetDeskBookingFloorplansState => ({
  type: SET_FLOORPLANS,
  payload: floorplans,
})

export const setDeskBookingFeaturesForFloorplan = (
  features: BookingFeature[] | null
): SetDeskBookingFeaturesForFloorplan => ({
  type: SET_FEATURES_FOR_FLOORPLAN,
  payload: features,
})

export const setDeskBookingZonesState = (zones: BookingZone[]): SetDeskBookingZonesState => ({
  type: SET_ZONES,
  payload: zones,
})

export const setDeskBookingOpenFloorplan = (open: boolean): SetDeskBookingOpenFloorplan => ({
  type: SET_OPEN_FLOORPLAN,
  payload: open,
})

export const setDeskBookingShowGridView = (show: boolean): SetDeskBookingShowGridView => ({
  type: SET_SHOW_GRID_VIEW,
  payload: show,
})

export const setDeskBookingShowFloorplan = (show: boolean): SetDeskBookingShowFloorplan => ({
  type: SET_SHOW_FLOORPLAN,
  payload: show,
})

export const setDeskBookingShowNavBar = (show: boolean): SetDeskBookingShowNavBar => ({
  type: SET_SHOW_NAV_BAR,
  payload: show,
})

export const setDeskBookingShowSearchComponent = (show: boolean): SetDeskBookingShowSearchComponent => ({
  type: SET_SHOW_SEARCH_COMP,
  payload: show,
})

export const setDeskBookingShowFilterComponent = (show: boolean): SetDeskBookingShowFilterComponent => ({
  type: SET_SHOW_FILTER_COMP,
  payload: show,
})

export const setDeskBookingShowBlockBookingWizard = (
  show: boolean
): SetDeskBookingShowBlockBookingWizard => ({
  type: SET_SHOW_BLOCK_BOOKING_WIZARD,
  payload: show,
})

export const setDeskBookingShowBlockBookingSummary = (
  show: boolean
): SetDeskBookingShowBlockBookingSummary => ({
  type: SET_SHOW_BLOCK_BOOKING_SUMMARY,
  payload: show,
})

export const setDeskBookingDashboardResults = (
  results: BookingEmployeeBooking[]
): SetDeskBookingDashboardResults => ({
  type: SET_DASHBOARD_RESULTS,
  payload: { lastUpdated: new Date(), results },
})

export const setDeskBookingInfinityBookingsResults = (
  results: BookingEmployeeBooking[]
): SetDeskBookingInfinityBookingsResults => ({
  type: SET_INFINITY_BOOKINGS_RESULTS,
  payload: { lastUpdated: new Date(), results },
})

export const setDeskBookingLoading = (loading: boolean): SetDeskBookingLoading => ({
  type: SET_LOADING,
  payload: loading,
})

export const setDeskBookingFocussedZoneID = (zoneID: number): SetDeskBookingFocussedZoneID => ({
  type: SET_FOCUSSED_ZONE_ID,
  payload: zoneID,
})

export const setDeskBookingFloorplanManuallySelected = (
  value: boolean
): SetDeskBookingFloorplanManuallySelected => ({
  type: SET_FLOORPLAN_MANUALLY_SELECTED,
  payload: value,
})

export const setDeskBookingTodaysBooking = (
  value: BookingEmployeeBooking
): SetDeskBookingTodaysBooking => ({
  type: SET_TODAYS_BOOKING,
  payload: value,
})

export const setDeskBookingShowMeBookingID = (
  value: number | undefined
): SetDeskBookingShowMeBookingID => ({
  type: SET_SHOW_ME_BOOKING_ID,
  payload: value,
})

export const setForwardDeskBookingsCount = (
  value: number | undefined
): SetForwardDeskBookingsCount => ({
  type: SET_FORWARD_DESK_BOOKINGS_COUNT,
  payload: value,
})

export const setMostUsedDesk = (value: MostUsedDesk | undefined): SetMostUsedDesk => ({
  type: SET_MOST_USED_DESK,
  payload: value,
})

export const setCheckedIn = (value: boolean): SetCheckedIn => ({
  type: SET_CHECKED_IN,
  payload: value,
})

export const setMaxBookingDate = (value: Date): SetMaxBookingDate => ({
  type: SET_MAX_BOOKING_DATE,
  payload: value,
})

export const setBookingSliderPosition = (value: BookingSlider): SetBookingSliderPosition => ({
  type: SET_BOOKING_SLIDER_POSITION,
  payload: value,
})

export const setInitialise = (): SetInitialise => ({
  type: SET_INITIALISE,
})

export const setShowScrollToTopButton = (value: boolean): SetShowScrollToTopButton => ({
  type: SET_SCROLL_TO_TOP,
  payload: value,
})

export const deskBookingReducer = (
  state: DeskBookingState = deskBookingStateInitialState,
  action: deskBookingDespatchTypes
): DeskBookingState => {
  switch (action.type) {
    case SET_GRID_VIEWING_DATE:
      return { ...state, gridViewingDate: action.payload }
    case SET_FLOORPLAN_VIEWING_DATE:
      return { ...state, floorplanViewingDate: action.payload }
    case SET_LOCATIONS:
      return { ...state, locations: action.payload }
    case SET_FLOORPLANS:
      return { ...state, floorplans: action.payload }
    case SET_FEATURES_FOR_FLOORPLAN:
      return { ...state, featuresForFloorPlan: action.payload }
    case SET_ZONES:
      return { ...state, zones: action.payload }
    case SET_OPEN_FLOORPLAN:
      return { ...state, openFloorplan: action.payload }
    case SET_SHOW_GRID_VIEW:
      return { ...state, showGridView: action.payload }
    case SET_SHOW_FLOORPLAN:
      return { ...state, showFloorplan: action.payload }
    case SET_SHOW_NAV_BAR:
      return { ...state, showNavBar: action.payload }
    case SET_SHOW_SEARCH_COMP:
      return { ...state, showSearch: action.payload }
    case SET_SHOW_FILTER_COMP:
      return { ...state, showFilter: action.payload }
    case SET_SHOW_BLOCK_BOOKING_WIZARD:
      return { ...state, showBlockBookingWizard: action.payload }
    case SET_SHOW_BLOCK_BOOKING_SUMMARY:
      return { ...state, showBlockBookingSummary: action.payload }
    case SET_DASHBOARD_RESULTS:
      return { ...state, dashboardResults: action.payload }
    case SET_INFINITY_BOOKINGS_RESULTS:
      return { ...state, infinityBookingsResults: action.payload }
    case SET_LOADING:
      return { ...state, bookingIsLoading: action.payload }
    case SET_FOCUSSED_ZONE_ID:
      return { ...state, focussedZoneID: action.payload }
    case SET_FLOORPLAN_MANUALLY_SELECTED:
      return { ...state, floorplanManuallySelected: action.payload }
    case SET_TODAYS_BOOKING:
      return { ...state, todaysBooking: action.payload }
    case SET_SHOW_ME_BOOKING_ID:
      return { ...state, showMeBookingID: action.payload }
    case SET_FORWARD_DESK_BOOKINGS_COUNT:
      return { ...state, forwardDeskBookingsCount: action.payload }
    case SET_MOST_USED_DESK:
      return { ...state, mostUsedDesk: action.payload }
    case SET_CHECKED_IN:
      return { ...state, checkedIn: action.payload }
    case SET_MAX_BOOKING_DATE:
      return { ...state, maxBookingDate: action.payload }
    case SET_BOOKING_SLIDER_POSITION:
      return { ...state, bookingSliderPosition: action.payload }
    case SET_INITIALISE:
      return deskBookingStateInitialState
    case SET_SCROLL_TO_TOP:
      return { ...state, showScrollToTopButton: action.payload }
    default:
      return state
  }
}
