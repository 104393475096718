import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAppDispatch } from '../../../redux/store'
import { clearLoggedInUser } from '../../../redux/reducers/userReducer'
import { LogoutHelper } from '../../../utils/LogoutHelper'

type Props = {
  mobile?: boolean
}

function Logout({ mobile }: Props) {
  const { oktaAuth } = useOktaAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const dispatch = useAppDispatch()
  const logoutHelper = new LogoutHelper(oktaAuth, dispatch)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
      await logoutHelper.handleLogout()
  }

  return (
    <>
      <IconButton
        id="account-button"
        aria-controls={open ? 'user' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid="Dashboard-OktaBtn"
      >
        <AccountCircleOutlinedIcon fontSize="large" color={mobile ? 'action' : 'primary'} />
      </IconButton>
      <Menu
        id="user"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-button',
        }}
      >
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{logoutHelper.getLogoutButtonLabel()}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default Logout
