/* eslint-disable react/jsx-one-expression-per-line */
import { Box, Grid, Stack, Typography } from '@mui/material'
import Card from '../../../../shared/layout/Card'
import { CalloutDetails } from '../../../../models/enhancement'
import Paragraph from '../../../../shared/UI/Paragraph'

const callOutStrings = {
  calledOutBy: 'Called Out By',
  requireEscalation: 'Require Escalation',
  jiraReference: 'Jira/Incident reference',
  product: 'Product',
  issueType: 'Issue Type',
  callOuts: 'Call Outs',
  escalationType: 'Escalation Type',
  escalatedTo: 'Escalated To',
  transferred: 'Transferred to another team?',
  team: 'Team',
  conclusion: 'Conclusion',
  codeChange: 'Code change released?',
  logIn: 'Log in required?',
}

interface CallOutDetailProps {
  callOutDetails?: CalloutDetails
}

export function CallOutDetail({ callOutDetails }: CallOutDetailProps) {
  function boolToString(input: boolean): string {
    return input ? 'Yes' : 'No'
  }

  const getDetailItem = (label: string, value: string) => (
    <Typography
      component="div"
      sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Paragraph weight="bold" style={{ display: 'inline' }}>
          {label}:
        </Paragraph>
      </Box>
      <Box>
        <span>{value}</span>
      </Box>
    </Typography>
  )

  const callOutCountString = `${callOutDetails?.callOutPosition} of ${callOutDetails?.totalCallOutCount}`

  return callOutDetails ? (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          xl={12}
          style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
        >
          <Card title="Detail">
            <Grid container spacing={4}>
              <Grid item xs={12} textAlign="left">
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(callOutStrings.calledOutBy, callOutDetails.calledOutBy)}
                </Stack>
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(
                    callOutStrings.requireEscalation,
                    boolToString(callOutDetails.escalationRequired)
                  )}
                </Stack>
                {callOutDetails.jiraReference ? (
                  <Stack direction="row" spacing={1} mb={1}>
                    {getDetailItem(callOutStrings.jiraReference, callOutDetails.jiraReference!)}
                  </Stack>
                ) : (
                  <></>
                )}
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(callOutStrings.product, callOutDetails.product)}
                </Stack>
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(callOutStrings.issueType, callOutDetails.issueType)}
                </Stack>
                {callOutDetails?.callOutPosition && callOutDetails?.totalCallOutCount ? (
                  <Stack direction="row" spacing={1} mb={1}>
                    {getDetailItem(callOutStrings.callOuts, callOutCountString)}
                  </Stack>
                ) : (
                  <></>
                )}
                {callOutDetails.escalationType ? (
                  <Stack direction="row" spacing={1} mb={1}>
                    {getDetailItem(callOutStrings.escalationType, callOutDetails.escalationType!)}
                  </Stack>
                ) : (
                  <></>
                )}
                {callOutDetails.escalatedTo ? (
                  <Stack direction="row" spacing={1} mb={1}>
                    {getDetailItem(callOutStrings.escalatedTo, callOutDetails.escalatedTo!)}
                  </Stack>
                ) : (
                  <></>
                )}
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(
                    callOutStrings.transferred,
                    boolToString(callOutDetails.transferToAnotherTeam)
                  )}
                </Stack>
                {callOutDetails.transferToAnotherTeam && callOutDetails.team ? (
                  <Stack direction="row" spacing={1} mb={1}>
                    {getDetailItem(callOutStrings.team, callOutDetails?.team)}
                  </Stack>
                ) : (
                  <></>
                )}
                {callOutDetails.conclusion ? (
                  <Stack direction="row" spacing={1} mb={1}>
                    {getDetailItem(callOutStrings.conclusion, callOutDetails.conclusion)}
                  </Stack>
                ) : (
                  <></>
                )}
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(
                    callOutStrings.codeChange,
                    boolToString(callOutDetails.codeChange)
                  )}
                </Stack>
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(callOutStrings.logIn, boolToString(callOutDetails.logInRequired))}
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  )
}
