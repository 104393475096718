
import { Stack, Box } from '@mui/material'
import { LocationOn, Event } from '@mui/icons-material'
import { BookingPopover } from './components'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import Tab from '../../../shared/UI/Tab'
import localCss from '../booking.module.css'


function HeaderTabContent (props: any) {
  return <Tab
    titleAndContent={props.tabSelectionProps()}
    tabClass={props.isOwnBooking ? localCss.bookingPopover : ''}
    onChange={idx => props.setSelectedTab(idx === 0 ? 'booking' : 'features')}
    isOwnBooking={props.isOwnBooking}
    isMobile={props.isMobile}
  />
}

function BookingPopoverComponent (props: any) {

  return (
    <BookingPopover
      isMobile={props.isMobile}
      popoverPosition={props.popoverPosition}
      popupWidth={props.popoverWidth}
      popupHeight={props.popoverHeight}
      isOwnBooking={props.isOwnBooking}
    >
      {props.isOwnBooking && (
        <>
          <div
            style={{
              backgroundColor: '#20C5A0',
              width: '100%',
              height: '65px',
              position: 'absolute',
              top: 0,
              left: 0,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <LocationOn
              style={{
                color: 'white',
                fontSize: '30px',
                position: 'absolute',
                right: '22px',
                top: '15px',
              }}
            />
          </div>
          <div
            style={{
              backgroundColor: '#20C5A0',
              width: '100%',
              height: '65px',
              position: 'absolute',
              top: 0,
              left: 0,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <LocationOn
              style={{
                color: 'white',
                fontSize: '30px',
                position: 'absolute',
                right: '22px',
                top: '15px',
              }}
            />
          </div>
        </>
      )}
      {!props.isOwnBooking && props.isMobile && <Box marginTop="20px" />}
      {props.isMobile &&
        <Box style={{margin: "auto 0px"}}>
          <HeaderTabContent {...props} />
        </Box>
      }
      {!props.isMobile && <HeaderTabContent {...props} />}
      <Stack
        direction="row"
        gap={4}
        justifyContent="space-between"
        sx={{
          position: 'absolute',
          width: '100%',
          left: '0',
          height: '26px',
          paddingInline: '24px',
          boxSizing: 'border-box',
          bottom: props.isMobile ? '64px' : '24px',
        }}
      >
        {!props.isMobile && <ButtonSmall
          color="secondary"
          label="Close"
          minWidth="88px"
          sx={{ padding: '1.5px 30px', fontSize: '11px', margin: '0' }}
          onClick={props.onClickCallback}
        />}
        {props.getSubmitLabel() !== 'Disabled'
          && !props.getSubmitDisabledState() &&
          <ButtonSmall
            label={props.getSubmitLabel()}
            minWidth={props.isMobile ? "100%" : "88px"}
            sx={{
              ...(
                props.isMobile ? {
                  padding: '30.5px 50px',
                  fontSize: '16px',
                  margin: '0px auto'
                } : {
                  padding: '1.5px 30px',
                  fontSize: '11px',
                  margin: '0'
                }
              ), ...{
                  opacity: props.getSubmitDisabledState() ? 0.5 : 1,
                }
            }}
            disabled={props.getSubmitDisabledState()}
            onClick={props.onButtonClick}
          />
        }
      </Stack>
    </BookingPopover>
  )
}


export default BookingPopoverComponent
