import Popper from "@mui/material/Popper/Popper"
import { TabDetails } from "../../../shared/UI/Tab"
import { BookingZIndexLayers } from "../Shared/enums"
import { MobileOrientationPopOverOptions } from "../consts"


interface PopoverWrapper {
  isMobile: boolean
  popoverPosition: any
  popoverWidth: number
  popoverHeight: number
  isOwnBooking: boolean
  getSubmitLabel: () => string
  getSubmitDisabledState: () => boolean
  tabSelectionProps: () => TabDetails[]
  onButtonClick: () => void
  onClickCallback: () => void
}

interface PopperProps {
  open: boolean
  anchorEl: SVGElement | undefined
  placement: any | undefined
  style: object
  popoverProps: PopoverWrapper
}

const {
  BOOKING_MOBILE_FLOORPLAN_VIEW_FEATURE_POPOVER
} = BookingZIndexLayers

const PopoverWrapper = (popoverFixed: boolean) => (WrappedComponent: any) => {
  const WithPopoverWrapper = function (props: PopperProps) { 

    const { popoverPosition } = props.popoverProps
    const mobilePortaitCode = MobileOrientationPopOverOptions.MOBILE_PORTRAIT
    const mobilePortraitIsActive = popoverPosition === mobilePortaitCode

    return popoverFixed ? 
    (
      <div 
        style={{
          position: 'fixed',
          zIndex: BOOKING_MOBILE_FLOORPLAN_VIEW_FEATURE_POPOVER,
          ...(mobilePortraitIsActive ? {
            bottom: '-2px',
            left: '0px',
            height: `320px`,
            width: `100%`,
          } : {
            bottom: '-2px',
            right: '-1px',
            height: `calc(100% - 70px)`,
            width: `50%`,
          })
        }}
      >
        <WrappedComponent {...props?.popoverProps} />
      </div>
    ) : (
      <Popper
        {...props}
      >
        <WrappedComponent {...props?.popoverProps} />
      </Popper>
    )
  }
  
  WithPopoverWrapper.displayName = `PopoverWrapper(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return WithPopoverWrapper

}


export default PopoverWrapper
