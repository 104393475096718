import { Grid, Stack, TextField } from '@mui/material'
import { format } from 'date-fns'
import Card from '../../../../shared/layout/Card'
import CardTitle from '../../../../shared/UI/CardTitle'
import { DateRange } from '../../../../models/enhancement/date-range'
import { getDetailItem } from '../../TotalHours/TotalHoursCard'

interface CallOutDurationProps {
  totalHours: string
  dateRange: DateRange
  comment?: string
}

export function CallOutDuration({ totalHours, dateRange, comment = '' }: CallOutDurationProps) {
  const displayTotalHours = Number(totalHours).toFixed(2).toString()

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          xl={12}
          style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
        >
          <Card title="Duration" icon={<CardTitle title={displayTotalHours} />}>
            <Grid container spacing={4}>
              <Grid item xs={12} textAlign="left">
                <Stack direction="row" spacing={1} mb={1}>
                  {getDetailItem(
                    'Date from',
                    format(new Date(dateRange.start), 'dd/MM/yyyy HH:mm')
                  )}
                  {getDetailItem('Date to', format(new Date(dateRange.end), 'dd/MM/yyyy HH:mm'))}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Issue Description"
                  fullWidth
                  multiline
                  rows={4}
                  disabled
                  value={comment}
                  data-testid="description"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
