import { Grid, Stack, TextField, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Card from '../../../../shared/layout/Card'
import { latestApproverComment } from '../../../../utils/SharedMethods/getCommentsFromStatusHistory'
import {
  DateRange,
  EnhancementDay,
  EnhancementPutBody,
  StatusResponse,
  SubmitStatus,
} from '../../../../models/enhancement'
import Paragraph from '../../../../shared/UI/Paragraph'
import Button from '../../../../shared/UI/Button'
import {
  disableDeclineEnhancement,
  isReadOnlyForEmployeeEnhancement,
  isReadOnlyForManagerEnhancement,
} from '../../../../utils/SharedMethods/isReadOnlyEnhancement'
import { ValidationErrors } from '../../../CallOutEnhancement/validations'
import Modal from '../../../../shared/UI/Modal'
import {
  hideModal,
  SetShowModalPayload,
  showModalDialog,
} from '../../../../redux/reducers/appSettingsReducer'
import { RootStore, useAppDispatch } from '../../../../redux/store'
import { v2MyActionsService } from '../../../../services/myActionsServiceV2'
import { showErrorMessage, showSuccessMessage } from '../../../../redux/reducers/snackbarReducer'

interface CallOutApprovalProps {
  statusHistory: StatusResponse[]
  currentStatus: SubmitStatus
  initialTotalHours: number
  id: number
  days?: EnhancementDay[]
  dateRange: DateRange
  onStatusChangeSuccess: () => void
}

export function CallOutApproval({
  statusHistory,
  currentStatus,
  initialTotalHours,
  id,
  days,
  dateRange,
  onStatusChangeSuccess,
}: CallOutApprovalProps) {
  const [comments, setComments] = useState<string>(latestApproverComment(statusHistory))
  const [overrideHours, setOverrideHours] = useState<number>(initialTotalHours)
  const [formErrors, setFormErrors] = useState<ValidationErrors>()
  const dispatch = useAppDispatch()
  const isReadOnlyRequest = isReadOnlyForManagerEnhancement(currentStatus)

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((State: RootStore) => State.appSettings.modalProps)

  const hasChanged = useMemo(
    () => initialTotalHours !== overrideHours,
    [initialTotalHours, overrideHours]
  )

  const resetForm = () => {
    setOverrideHours(initialTotalHours)
  }

  const buildApprovalBody = (submitStatus: SubmitStatus): EnhancementPutBody => {
    let body: EnhancementPutBody = {
      id,
      days,
      dateRange,
      status: {
        submitStatus,
        comments,
      },
    }
    if (submitStatus === SubmitStatus.DECLINED) {
      resetForm()
      return body
    }

    if (hasChanged) {
      body = {
        ...body,
        paidHoursOverride: overrideHours,
      }
    }

    return body
  }

  const putRequest = (submitStatus: SubmitStatus, messageText: string) => {
    v2MyActionsService
      .putEnhancementManual(buildApprovalBody(submitStatus))
      .then(() => {
        dispatch(showSuccessMessage(`Request successfully ${messageText}`))
        onStatusChangeSuccess()
      })
      .catch(() => {
        dispatch(showErrorMessage(`Failed request has not been ${messageText}`))
      })
      .finally(() => {
        dispatch(hideModal())
      })
  }

  const approveRequest = () => {
    if (hasChanged && !comments) {
      setFormErrors(prev => ({
        ...prev,
        comments: true,
      }))
      return
    }
    if (hasChanged) {
      putRequest(SubmitStatus.AMENDED, 'amended')
    } else {
      putRequest(SubmitStatus.APPROVED, 'approved')
    }
  }

  const displayDeclineModal = () => {
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message:
          'By declining this request, you confirm that you will review all callouts for this employee to ensure correct payments are made',
        buttonLabel: 'Decline',
        type: 'question',
        showModal: true,
      })
    )
  }

  const declineRequest = () => {
    putRequest(SubmitStatus.DECLINED, 'declined')
  }

  const handleOverrideHoursChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const hours = Number(e.target.value)
    setOverrideHours(hours)
    setFormErrors(prevState => ({
      ...prevState,
      duration: hours < 0,
    }))
  }

  const isButtonDisabled = (): boolean | undefined =>
    (isReadOnlyForEmployeeEnhancement(currentStatus!) && !hasChanged) || formErrors?.duration

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Card title="Approval" testId="enhancement-approval-form">
            <Grid container spacing={1} mb={1}>
              <Grid item xs={12} textAlign="left">
                <TextField
                  label="Reviewer Comments"
                  fullWidth
                  multiline
                  rows={4}
                  value={comments}
                  disabled={isReadOnlyRequest}
                  onChange={e => {
                    setComments(e.target.value)
                    setFormErrors(prev => ({
                      ...prev,
                      comments: false,
                    }))
                  }}
                  error={formErrors?.comments}
                  data-testid="reviewer-comments"
                />
              </Grid>
              <Grid item xs={12} textAlign="left">
                <Typography sx={{ display: 'block', gap: 1 }}>
                  <Stack direction="column" gap={3}>
                    <Stack direction="column">
                      <Paragraph weight="bold" style={{ display: 'inline' }}>
                        Optional
                      </Paragraph>
                      <Paragraph style={{ display: 'inline' }}>
                        You can adjust the amount of hours to be paid in this occurrence by amending
                        the below value.
                      </Paragraph>
                    </Stack>
                    <TextField
                      type="number"
                      InputProps={{
                        inputProps: { step: '0.1', min: 0.1, max: 24 },
                      }}
                      fullWidth
                      label="Override hours"
                      defaultValue={overrideHours}
                      value={overrideHours}
                      onChange={handleOverrideHoursChange}
                      disabled={isReadOnlyRequest}
                      error={formErrors?.duration}
                    />
                    <Stack
                      direction={{ xs: 'column-reverse', md: 'row' }}
                      justifyContent="space-between"
                      gap={{ xs: 0, md: 4 }}
                    >
                      <Button
                        label="Decline"
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          if (!comments) {
                            setFormErrors(prev => ({
                              ...prev,
                              comments: true,
                            }))
                            return
                          }
                          displayDeclineModal()
                        }}
                        disabled={disableDeclineEnhancement(currentStatus!)}
                        dataTestId="decline-button"
                      />
                      <Button
                        label={hasChanged ? 'Approve With Changes' : 'Approve'}
                        variant="outlined"
                        color="primary"
                        onClick={approveRequest}
                        disabled={isButtonDisabled()}
                        dataTestId="approve-button"
                      />
                    </Stack>
                  </Stack>
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={declineRequest}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </>
  )
}
