import { ReactElement } from 'react'

export interface ErrorListProps {
  name: string | RegExp
  message: string | ReactElement
  link: boolean
  alert?: boolean
}

export const ErrorList: ErrorListProps[] = [
  {
    name: 'EntitlementYearUnavailable',
    message: 'Entitlement Period is currently unavailable.  Please contact your manager.',
    link: false,
  },
  {
    name: 'DeskAlreadyBookedForTheDay',
    message: 'You have already booked a desk for the day. Please cancel the existing booking.',
    link: false,
  },
  {
    name: 'OtherAlreadyHasDeskBookedForTheDay',
    message: 'This desk has already been booked for today. Please choose another desk.',
    link: false,
  },
  {
    name: 'AbsenceId',
    message: 'This absence cannot be found. Please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'ContactByDate',
    message: 'No Contact by Date provided',
    link: false,
  },
  {
    name: 'DuplicateAbsenceDay',
    message: 'An absence already exists for this user in this period',
    link: false,
  },
  {
    name: 'AbsenceDayId',
    message:
      'There has been an issue retrieving the absence details, please retry.  If the issue persists, please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'EmployeeNotFound',
    message:
      'There has been an issue retrieving the employee details, please retry.  If the issue persists, please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'EntitlementPeriod',
    message: 'Entitlement Period is currently unavailable.  Please contact your manager.',
    link: false,
  },
  {
    name: 'LateId',
    message:
      'There has been an issue retrieving the late details, please retry.  If the issue persists, please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'Rtw',
    message:
      'There has been an issue retrieving the RTW details, please retry.  If the issue persists, please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'Sca',
    message:
      'There has been an issue retrieving the SCA details, please retry.  If the issue persists, please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'RequestId',
    message:
      'There has been an issue retrieving the request details, please retry.  If the issue persists, please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'RequestLocked',
    message: 'Request is locked by another user',
    link: false,
  },
  {
    name: 'EmployeeId',
    message:
      'There has been an issue retrieving the employee details, please retry.  If the issue persists, please raise a BSS issue via ',
    link: true,
  },
  {
    name: 'DateFrom',
    message: 'Please enter a valid Date from',
    link: false,
  },
  {
    name: 'DateTo',
    message: 'Please enter a valid Date to',
    link: false,
  },
  {
    name: 'ThresholdHours',
    message: 'Please enter threshold hours',
    link: false,
  },
  {
    name: 'DuplicateThreshold',
    message: 'Threshold already exists',
    link: false,
  },
  {
    name: 'RequestDays',
    message: 'Please select at least one day for this request',
    link: false,
  },
  {
    name: 'Shift',
    message: 'Shift details not found',
    link: false,
  },
  {
    name: 'PlatformType',
    message: 'Notification Error',
    link: false,
  },
  {
    name: 'InvalidRecipients',
    message: 'No valid recipients for notification',
    link: false,
  },
  {
    name: 'InsufficientEntitlement',
    message: 'Total Hours exceeds remaining entitlement',
    link: false,
  },
  {
    name: 'EntitlementRange',
    message: 'Request dates cannot span more than two financial years.',
    link: false,
  },
  {
    name: /Days\[[0-9]\].Hours/,
    message: 'The field Hours must be between 0 and 24.',
    link: false,
  },
  {
    name: 'AbsentHours',
    message: 'The field AbsentHours must be between 0.1 and 24',
    link: false,
  },
  {
    name: 'NumberOfHours',
    message: 'Hours must be between 0.1 and 24',
    link: false,
  },
  {
    name: 'EmploymentRange',
    message: 'Request must be within employment start and end date',
    link: false,
  },
  {
    name: 'Department',
    message: "Your department doesn't support this type of request",
    link: false,
  },
  {
    name: 'BirthdayDateFrom',
    message: 'You already have a birthday booked in the selected entitlement period',
    link: false,
  },
  {
    name: 'NumberOfHoursExceeds',
    message: 'The submitted number of hours exceeds the number of hours available',
    link: false,
  },
  {
    name: 'Days',
    message: 'At least 1 day must be selected',
    link: false,
  },
  {
    name: 'BeforeEntitlementRange',
    message: 'Date must not be in a previous holiday entitlement period.',
    link: false,
  },
  {
    name: 'AfterEntitlementRangeSimple',
    message: 'Date must be within the next 5 holiday entitlement periods.',
    link: false,
  },
  {
    name: 'AfterEntitlementRangeStandard',
    message: 'Date must be within the next 2 holiday entitlement periods.',
    link: false,
  },
  {
    name: 'SickPayHoursRemaining',
    message: 'Hours to be paid must not be greater than sick hours remaining',
    link: false,
  },
  {
    name: 'HoursLost',
    message: 'Hours to be paid must not be greater than hours lost',
    link: false,
  },
  {
    name: 'CurrentlyUnavailable',
    message: 'Currently unavailable.  Please contact your manager.',
    link: false,
    alert: false,
  },
  {
    name: 'NegativeEntitlement',
    message: 'Action will result in a negative entitlement for this user',
    link: false,
    alert: true,
  },
  {
    name: 'UpdateNegativeEntitlement',
    message: 'Total hours exceeds remaining entitlement',
    link: false,
    alert: true,
  },
  {
    name: 'NotSufficientEntitlement',
    message: 'Total hours exceeds remaining entitlement',
    link: false,
    alert: true,
  }
]
