import { Grid, Typography } from "@mui/material"
import { format } from "date-fns"
import { PayPeriodContent } from "../../shared/UI/PayPeriodContent/PayPeriodContent"
import { StatusChip } from "../../shared/UI/StatusChip/StatusChip"
import { PayPeriodResponse } from "../../models/enhancement/pay-period-response"

type Props = {
  isManager: boolean | undefined
  payPeriod: PayPeriodResponse | undefined
}

function PayPeriod({isManager = false, payPeriod}: Props) {
  const now = new Date()
    let cutOffDateTime = new Date(isManager ? payPeriod!.managerCutOff : payPeriod!.employeeCutOff)
    let selectedPeriod = payPeriod!.currentPeriod.period
    let selectedDateRange = payPeriod!.dateRange

    const isAfterCutOff = cutOffDateTime < now
    if (isAfterCutOff) {
      cutOffDateTime = new Date(isManager ? payPeriod!.nextPeriodManagerCutOffDate : payPeriod!.nextPeriodEmployeeCutOffDate)
      selectedPeriod = payPeriod?.nextPeriod?.period ?? selectedPeriod
      selectedDateRange = payPeriod?.nextPeriodDateRange ?? selectedDateRange
    }

    return (
      <Grid
        item
        xs={12}
        gap={1}
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Typography variant="body2" color="#000000">
          <PayPeriodContent
            payPeriod={selectedPeriod}
            dateRange={selectedDateRange}
          />
        </Typography>
        <StatusChip
          label={`
            Submit by ${format(
              cutOffDateTime,
              'HH:mm'
            )}
            on ${format(
              cutOffDateTime,
              'do MMMM'
            )}
          `}
          type="pending"
        />
      </Grid>
    )
}

export default PayPeriod