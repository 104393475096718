import { format } from 'date-fns'
import { Entitlement, ManagementModel, RequestType } from '../models'
import { EntitlementPeriodType, SyndicateTypeType } from '../utils/constants'
import { httpServiceV1 } from './httpServiceV1'
import { EmployeeDetailsResponse } from '../types/employee'
import { BaseResponse } from '../types/base-response'
import { MySummaryResponse } from '../types/my-summary'
import { WhosAwayResponse } from '../types/whos-away-type'
import { DashboardMyActionsResponse } from '../types/dashboard-my-actions-response'
import { EntitlementYearDatesResponse } from '../types/entitlement'
import { MyPendingRequests } from '../types/my-pending-requests'
import { TimeSpan } from '../types/time-span'
import { Day } from '../types/wallchart'
import { LocationGroups } from '../types/location-groups'
import { httpServiceV2 } from "./httpServiceV2"

const dashboardBaseURL = 'dashboard'
const v2DashboardBaseURL = 'v2/dashboard'
export interface Employee {
  employeeId: number
  employeeName: string
  departmentId: number
  teamId: number
  entitlementPeriod?: EntitlementPeriodType
  entitlementYearDates?: EntitlementYearDatesResponse | undefined
  syndicateType?: SyndicateTypeType
  locationGroupName?: string
  directReport?: boolean
}

export interface SelectOption {
  value: number
  displayValue: string
  associatedValue?: number
  entitlementPeriod?: EntitlementPeriodType
  entitlementYearDates?: EntitlementYearDatesResponse | undefined
  syndicateType?: SyndicateTypeType
  locationGroupName?: string
}

export interface Team {
  teamId: number
  teamName: string
  departmentId: number | undefined
}

export interface SelectValues {
  optionsType: string
  options: SelectOption[]
}

export interface TeamOptions extends SelectOption {
  employees: SelectValues
  teamEmployees: SelectValues
}
export interface TeamsResponse {
  optionsType: string
  options: TeamOptions[]
}
export interface Department extends SelectOption {
  employees: SelectValues
  teams: TeamsResponse
}

export interface CurrentEntitlementPeriodResponse {
  optionsType: string
  entitlementPeriod: string
  startDate: Date
  endDate: Date
  year: number
}

export interface CalendarDetailsResponse {
  calendarDetails: CurrentEntitlementPeriodResponse[]
}

export interface SelectValuesResponse extends BaseResponse {
  selectValues: SelectValues[]
}

export interface ComingUpRequest {
  date: string
  duration: number
  manualRequestType: string
  requestId: number
  requestType: RequestType
}

export interface ComingUpCalendarRequest {
  id: number
  dateFrom: string
  dateTo: string
  manualRequestType: string
  requestId: number
  requestType: string
  flattenedRequestTypeId: number
  flattenedRequestType: string
  submitDateTime: string
  statusDescription: string
  actionedBy: string
  actionedDate: string
  hours: number
  isCancellation: boolean
  isQueried: boolean
  shiftStartTimeStr?: string
  shiftStartTime?: TimeSpan
  days: Day[]
}

export interface ComingUpRequestResponse extends BaseResponse {
  requests: ComingUpCalendarRequest[]
}

export interface DepartmentValues {
  departmentId: number
  departmentName: string
  managementModel: SyndicateTypeType
}

export interface TeamValues {
  departmentId: number
  teamId: number
  teamName: string
}

export interface EmployeeValues {
  departmentId: number
  teamId?: number
  employeeId: number
  employeeName: string
  locationGroupName: LocationGroups
  locationId: number
  entitlementPeriod: EntitlementPeriodType
  isInHierarchy?: boolean
}

export interface EntitlementDateValue {
  endDate: Date
  entitlementPeriod: EntitlementPeriodType
  startDate: Date
  syndicateType: SyndicateTypeType
}

export interface DepartmentValuesResponse extends BaseResponse {
  departments: DepartmentValues[]
  teams: TeamValues[]
  employees: EmployeeValues[]
  entitlementDates: EntitlementDateValue[]
}

const dashboardService = {
  getHolidaySnapshot: async (): Promise<Entitlement> =>
    httpServiceV1.get(`${dashboardBaseURL}/snapshot`),
  getComingUpRequests: async (
    requestTypeIds: number[],
    manualTypeIds: number[]
  ): Promise<ComingUpRequestResponse> => {
    let requestURL = `${dashboardBaseURL}/ComingUpRequests?`
    requestTypeIds.forEach(id => {
      requestURL = `${requestURL}RequestTypeIds=${id}&`
    })
    manualTypeIds.forEach(id => {
      requestURL = `${requestURL}ManualTypeIds=${id}&`
    })
    requestURL = requestURL.substring(0, requestURL.length - 1)
    return httpServiceV1.get(requestURL)
  },
  getComingUpRequestsV2: async (
      requestTypeIds: number[],
      manualTypeIds: number[]
  ): Promise<ComingUpRequestResponse> => {
    let requestURL = `${v2DashboardBaseURL}/ComingUpRequests?`
    requestTypeIds.forEach(id => {
      requestURL = `${requestURL}RequestTypeIds=${id}&`
    })
    manualTypeIds.forEach(id => {
      requestURL = `${requestURL}ManualTypeIds=${id}&`
    })
    requestURL = requestURL.substring(0, requestURL.length - 1)
    return httpServiceV2.get(requestURL)
  },
  getEmployeeSummary: async (): Promise<MySummaryResponse> =>
    httpServiceV1.get(`${dashboardBaseURL}/MySummary`),
  getSelectValues: async (): Promise<SelectValuesResponse> => httpServiceV1.get('selectValues'),
  getEntitlementPeriodResponse: async (): Promise<CurrentEntitlementPeriodResponse> =>
    httpServiceV1.get('selectValues/CurrentEntitlementPeriod'),
  getCalendarDetails: async (): Promise<CalendarDetailsResponse> =>
    httpServiceV1.get('selectValues/CalendarDetails'),
  getSelectValuesDepartments: async (): Promise<DepartmentValuesResponse> =>
    httpServiceV1.get('selectValues/SelectValuesDepartments'),
  getEmployeeDetails: async (): Promise<EmployeeDetailsResponse> =>
    httpServiceV1.get('SelectValues/EmployeeDetails'),
  getWhosAway: async (): Promise<WhosAwayResponse> =>
    httpServiceV1.get(`${dashboardBaseURL}/WhoAway`),
  getMyActions: async (): Promise<DashboardMyActionsResponse> =>
    httpServiceV1.get(`${dashboardBaseURL}/MyActions`),
  getMyActionsV2: async (): Promise<DashboardMyActionsResponse> =>
    httpServiceV2.get(`${v2DashboardBaseURL}/MyActions`),
  getMyPendingRequests: async (): Promise<MyPendingRequests> =>
    httpServiceV1.get(`${dashboardBaseURL}/MyPendingRequests`),
  getComingUpCalendar: async (
    dateFrom: Date,
    dateTo: Date,
    requestTypeIds: number[],
    manualTypeIds: number[]
  ): Promise<ComingUpRequestResponse> => {
    let requestURL = `${dashboardBaseURL}/ComingUpCalendar`
    requestURL = `${requestURL}?DateFrom=${format(dateFrom, 'yyyy-MM-dd')}`
    requestURL = `${requestURL}&DateTo=${format(dateTo, 'yyyy-MM-dd')}`
    requestTypeIds.forEach(id => {
      requestURL = `${requestURL}&RequestTypeIds=${id}`
    })
    manualTypeIds.forEach(id => {
      requestURL = `${requestURL}&ManualTypeIds=${id}`
    })
    return httpServiceV1.get(requestURL)
  },
  getComingUpCalendarV2: async (
      dateFrom: Date,
      dateTo: Date,
      requestTypeIds: number[],
      manualTypeIds: number[]
  ): Promise<ComingUpRequestResponse> => {
    let requestURL = `${v2DashboardBaseURL}/ComingUpCalendar`
    requestURL = `${requestURL}?DateFrom=${format(dateFrom, 'yyyy-MM-dd')}`
    requestURL = `${requestURL}&DateTo=${format(dateTo, 'yyyy-MM-dd')}`
    requestTypeIds.forEach(id => {
      requestURL = `${requestURL}&RequestTypeIds=${id}`
    })
    manualTypeIds.forEach(id => {
      requestURL = `${requestURL}&ManualTypeIds=${id}`
    })
    return httpServiceV2.get(requestURL)
  },
}
export { dashboardService, LocationGroups }
