import { Link, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { RootStore } from '../../../redux/store'

import { ErrorList } from './errorList'
import { ResponsiveDetectionMobileState } from '../../../types/responsive-detection-mobile'


export interface ErrorMessageProps {
  name?: string
}

const DEFAULT_ERROR_MESSAGE =
'This action could not be completed. Please raise an issue for investigation via the '
const REPORT_ACTION_NAME =
'Service Hub.'
const DEFAULT_SERVICEHUB_URL =
'https://bet365-dwp.onbmc.com/dwp/rest/share/OJSXG33VOJRWKVDZOBST2Q2BKRAUYT2HL5BUCVCFI5HVEWJGORSW4YLOOREWIPKBI5DUQTCQIZEUCMZVIQ3UCUSGKVMFUU2SIZKVQWSTIE3UUNJGOJSXG33VOJRWKSLEHUYTANRQGITHG33VOJRWKVDZOBST2U2CIUTHA4TPOZUWIZLSKNXXK4TDMVHGC3LFHVJUERI='


function BookingErrorMessage({ name }: ErrorMessageProps): ReactElement {

  const {
    mobileDetection
  } = useSelector((state: RootStore) => state.responsiveDetection)

  const {
    isMobile,
  }:ResponsiveDetectionMobileState = mobileDetection

  const foundItem = ErrorList.find((item: ErrorMessageProps) => item.name === name)

  const foundErrorItemWithMessage = foundItem?.link && foundItem.message
  const notFoundErrorItemMessage = !foundItem
  const errorItemExceptionOrMappedMessage = notFoundErrorItemMessage || foundErrorItemWithMessage

  const showServiceHubLinkInternalOnly = !isMobile && errorItemExceptionOrMappedMessage

  return (
    <>
      <Typography style={{ fontSize: '13px' }}>
        {foundItem ? foundItem.message : DEFAULT_ERROR_MESSAGE}

        {!showServiceHubLinkInternalOnly &&
          notFoundErrorItemMessage && REPORT_ACTION_NAME
        }

        {showServiceHubLinkInternalOnly && (
          <Link href={DEFAULT_SERVICEHUB_URL} target="_blank">
            {REPORT_ACTION_NAME}
          </Link>
        )}
      </Typography>
    </>
  )
}
export default BookingErrorMessage
