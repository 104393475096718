import { useState, useMemo, useEffect } from 'react'
import {
  DefaultHoursResponse,
  DefaultHoursParams,
} from '../../models/enhancement/default-department-hours'
import { RequestDay } from '../../models/request-day'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import { RequestType } from '../../models'

interface UseDefaultHoursProps {
  departmentId: number
  selectedDays: RequestDay[] | null
  type: RequestType
}

export const useDefaultHours = ({ departmentId, selectedDays, type }: UseDefaultHoursProps) => {
  const [defaultHours, setDefaultHours] = useState<DefaultHoursResponse | null>(null)

  const dates = useMemo(() => {
    if (!selectedDays) return []

    return selectedDays
      .filter(day => day.checked && day.day)
      .map(day => day.day!.toISOString().slice(0, 10))
      .sort()
  }, [selectedDays])

  const getDefaultDepartmentHours = v2MyActionsService.getDefaultDepartmentOnCallHours

  useEffect(() => {
    const fetchDefaultHours = async () => {
      try {
        const bodyObject: DefaultHoursParams = { departmentId, dates }
        const response = await getDefaultDepartmentHours(bodyObject)
        setDefaultHours(response)
      } catch (error) {
        console.error('Error fetching department hours:', error)
      }
    }

    const isOnCallType = type === RequestType.ON_CALL || type === RequestType.ON_CALL_M

    if (dates.length > 0 && isOnCallType) {
      fetchDefaultHours()
    }
  }, [dates, departmentId, getDefaultDepartmentHours, type])

  const getDefaultDayHours = (day: { day?: Date | null; hours?: number }): string => {
    const isOnCallType = type === RequestType.ON_CALL || type === RequestType.ON_CALL_M

    if (!isOnCallType) {
      return (day.hours || 0).toFixed(2)
    }

    const dateKey = day.day ? day.day.toISOString().slice(0, 10) : ''
    const hoursFromAPI = dateKey ? defaultHours?.defaultHours[dateKey] : undefined
    return (hoursFromAPI !== undefined ? hoursFromAPI : day.hours || 0).toFixed(2)
  }

  return {
    defaultHours,
    getDefaultDayHours,
    dates,
  }
}
