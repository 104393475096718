import { useRef } from 'react'
import { useSelector } from 'react-redux'

import { Collapse } from '@mui/material'
import { ArrowCircleUpRounded as ArrowCircleUpRoundedIcon, } from '@mui/icons-material'

import { RootStore } from '../../../redux/store'
import { FabBackToTop } from './component'


export default function ScrollToTopButton() {
  const buttonRef = useRef(null)

  const {
    showScrollToTopButton,
  } = useSelector((state: RootStore) => state.deskBooking)

  if (!showScrollToTopButton) return (<></>)

  return (
    <Collapse 
      ref={buttonRef}
      in={showScrollToTopButton}
      appear
      unmountOnExit
      style={{
        transformOrigin: '0 0 0',
        overflow: showScrollToTopButton ? 'visible' : 'hidden'
      }}
      easing={{
        enter: "2s cubic-bezier(0,0,.58,1)",
        exit: "3s cubic-bezier(0,0,.58,1)"
      }}
      timeout={{
        enter: 3000,
        exit: 2000
      }}
    >
      <FabBackToTop
        disabled={!showScrollToTopButton}
        size="small"
        color="secondary"
        aria-label="Back to Top"
      >
        <ArrowCircleUpRoundedIcon
          className='scrollToTopButton'
          ref={buttonRef}
          sx={{
            fontSize: {
              xs: 30, sm: 35, md: 40
            }
          }}
        />
      </FabBackToTop>
    </Collapse>
  )

}
