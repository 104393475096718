import { DataGridPro } from '@mui/x-data-grid-pro'
import styled from 'styled-components'
import NoResult from '../../shared/UI/NoResult'

export const SytledMyActionsGrid = styled(DataGridPro)(() => ({
  '& ::-webkit-scrollbar': {
    width: '6px',
  },
  '& ::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '& ::-webkit-scrollbar-thumb': {
    backgroundColor: '#f5f5f5',
    borderRadius: '3px',
  },
  '& div[data=field="__detail_panel_toggle__"]': {
    display: 'none',
  },
  '&.MuiDataGrid-root': {
    border: 'none !important ',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    minHeight: '200px!important',
  },
  '.header, .MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgb(247, 247, 247)',
  },
  '& .MuiDataGrid-row': {
    '&.expanded': {
      opacity: '0.5',
    },
    '&.expanded .MuiDataGrid-cell': {
      borderBottomColor: 'transparent',
    },
  },
  '.MuiDataGrid-detailPanel': {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  cursor: 'pointer',
}))

export const SytledEnhancementsGrid = styled(DataGridPro)(() => ({
  '& ::-webkit-scrollbar': {
    width: '6px',
  },
  '& ::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '& ::-webkit-scrollbar-thumb': {
    backgroundColor: '#f5f5f5',
    borderRadius: '3px',
  },
  '& div[data=field="__detail_panel_toggle__"]': {
    display: 'none',
  },
  '&.MuiDataGrid-root': {
    border: 'none !important ',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    minHeight: '100px!important',
  },
  '.header, .MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgb(247, 247, 247)',
  },
  '& .MuiDataGrid-row': {
    '&.expanded': {
      opacity: '0.5',
    },
    '&.expanded .MuiDataGrid-cell': {
      borderBottomColor: 'transparent',
    },
  },
  '.MuiDataGrid-detailPanel': {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  cursor: 'pointer',
}))


const baseButtonStyles = {
  marginTop: 0,
  marginBottom: 0,
  height: '30px',
  padding: '7px 7px 7px 15px',
  fontSize: '11px',
  fontWeight: '700',
  minWidth: 'auto',
}
export const approveButtonStyles = {
  '&.MuiButtonBase-root.MuiButton-root': {
    ...baseButtonStyles,
    '&: hover': {
      '& .MuiButton-endIcon .MuiChip-root': {
        backgroundColor: '#fff',
        color: '#e75e5e',
      },
    },
  },
}
export const declineButtonStyles = {
  '&.MuiButtonBase-root.MuiButton-root': {
    ...baseButtonStyles,
    '&: hover': {
      '& .MuiButton-endIcon .MuiChip-root': {
        color: '#000',
      },
    },
  },
}

export const countChipStyles = {
  height: '20px',
  width: '21px',
  fontSize: '12px !important',
  cursor: 'pointer',
  padding: 0,
  fontWeight: 'normal',
  '& .MuiChip-label': {
    padding: 0,
  },
}

export const bulkActionsContainer = {
  display: { xs: 'none', md: 'flex' },
  flexDirection: { md: 'column', lg: 'row' },
  gap: { md: 1, lg: 2 },
  minWidth: { md: '155px', lg: '315px' },
}

  export function NoResultComponent() {
    return <NoResult message="No Requests" showImage />
}