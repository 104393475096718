import Chip from '@mui/material/Chip'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import DoneIcon from '@mui/icons-material/Done'
import styled from 'styled-components'

export const statusChipThemes = {
  submitted: {
    textColor: '#F2FFF2',
    bgColor: '#1E4620',
    icon: <DoneIcon />,
  },
  pending: {
    textColor: '#FFF9EA',
    bgColor: '#A27403',
    icon: <WarningAmberIcon />,
  },
}

interface StatusChipProps {
  label: string
  type: keyof typeof statusChipThemes
}

export function StatusChip({ type, label }: StatusChipProps) {
  const theme = statusChipThemes[type]
  if (!theme) return null
  const StyledChip = styled(Chip)`
&.MuiChip-root {
  background-color: ${theme.bgColor}
  color: ${theme.textColor}
  padding: 4px 0px;
  height: auto;
  font-size: 12px;
  .MuiChip-icon {
    color: ${theme.textColor};
    font-size: 14px
  }
  .MuiChip-label {
    white-space: normal
  }
`
  return <StyledChip icon={theme.icon} label={label} data-testid="status-chip" />
}
