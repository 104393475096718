import { Typography as MuiTypography, useTheme } from '@mui/material'
import styled from 'styled-components'

type Props = {
  sx?: object
  weight?: 'medium' | 'bold'
  children?: any
  color?: string
  padding?: string
  style?: any
  textTransform?: string
  size?: null | '10px' | '11px' | '13px' | '16px' | '19px' | '21px' | '23px' | '35px'
}

function Paragraph({
  sx = {},
  weight,
  children,
  color,
  padding = '',
  style = {},
  size = !sx ? '13px' : null,
  textTransform = '',
  ...props
}: Props) {
  const theme = useTheme()

  const StyledTypography = styled(MuiTypography)`
    letter-spacing: 0.2px !important;
  `

  let parsedWeight
  let colorOverride = theme.palette.text.primary

  switch (weight) {
    case 'medium':
      parsedWeight = '500'
      break
    case 'bold':
      parsedWeight = '700'
      colorOverride = '#000000'
      break
    default:
      parsedWeight = '400'
  }

  if (color) {
    colorOverride = color
  }

  let fontSizeStyle = {}
  if (size) {
    fontSizeStyle = {
      fontSize: size
    }
  }

  return (
    <StyledTypography
      sx={{ ...sx }}
      style={{ ...style, ...fontSizeStyle, fontWeight: parsedWeight, color: colorOverride, padding, textTransform }}
      {...props}
    >
      {children}
    </StyledTypography>
  )
}

export default Paragraph
