import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isBefore,
  startOfMonth,
  subDays,
  isToday,
} from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ComingUpMegaPhone from '../../../assets/ComingUpMegaphone.svg'
import ComingUpMegaPhoneBg from '../../../assets/ComingUpMegaphoneBg.svg'
import { PageContext } from '../../../context/MyRequestsPageContext'
import {
  hideMyRequestsDrawer,
  setActiveRoute,
  showMyRequestsDrawer,
} from '../../../redux/reducers/appSettingsReducer'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import { setDefaultDateRange } from '../../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../../redux/store'
import {
  ComingUpCalendarRequest,
  ComingUpRequestResponse,
  dashboardService,
} from '../../../services/dashboardService'
import { DashboardDataGrid } from '../../../shared/UI/DashboardDataGrid'
import Drawer from '../../../shared/UI/Drawer'
import FullComponentLoadingIcon from '../../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { BaseResponse } from '../../../types/base-response'
import UserErrorMessage from '../../../utils/errorFilter'
import { DrawerComponentProps } from '../../MyRequests/MyRequestsGrid/types'
import NewRequest from '../../NewRequest'
import {
  ComingupCalendarContainer,
  ComingupHeaderBgContainer,
  ComingupHeaderImageContainer,
  ComingupHeaderOuterContainer,
  ComingUpImage,
  ComingUpImageBg,
  ComingupLowerContainer,
  StyledCard,
} from './components'
import { getPalleteTypeByProp } from '../../../theme/palette'
import { PlottingCalendarDates, PlottingCalendarRequest } from '../../PlottingCalendarCustom/types'
import { PlottingCalendars } from '../../PlottingCalendarCustom/PlottingCalendars'
import { buildData } from '../../PlottingCalendarCustom/utils'
import { StyledLink } from '../../../shared/UI/Link/components'
import theme from '../../../theme/theme'
import { isMobileDown } from '../../../theme/deviceChecks'
import NoResult from '../../../shared/UI/NoResult'
import { formatDateWithTimeZone } from '../../../utils/date-utils'
import { COMING_UP_MANUAL_TYPE_IDS, COMING_UP_REQUEST_TYPE_IDS } from '../../../utils/constants'

function NoRowsOverlay() {
  return <NoResult message="You have no events" />
}

function getComingUpRequests(isEnhancementsSettingOn: boolean) {
  return isEnhancementsSettingOn
      ? dashboardService.getComingUpRequestsV2(COMING_UP_REQUEST_TYPE_IDS, COMING_UP_MANUAL_TYPE_IDS)
      : dashboardService.getComingUpRequests(COMING_UP_REQUEST_TYPE_IDS, COMING_UP_MANUAL_TYPE_IDS)
}

function ComingUp() {
  const [gridData, setGridData] = useState<ComingUpRequestResponse | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [disabledDates, setDisabledDates] = useState<Date[]>([])
  const [plottingDates, setPlottingDates] = useState<PlottingCalendarDates[]>([])
  const [plottingDateRange, setPlottingDateRange] = useState<{
    start: Date
    end: Date
    length: number
  }>()
  const [showViewDrawer, setShowViewDrawer] = useState<boolean>(false)
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps | null>(null)
  const [apiData, setApiData] = useState<PlottingCalendarRequest[]>()
  const [plottingFilters, setPlottingFilters] = useState<string[]>([])

  const currentDay = new Date().getDate()
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

  useMemo(() => {
    setPlottingDateRange({
      start: new Date(currentYear, currentMonth, currentDay),
      end: new Date(currentYear, currentMonth + 1, 0),
      length: 1,
    })
  }, [currentDay, currentMonth, currentYear])

  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.showMyRequestsDrawer
  )

  const dispatch = useAppDispatch()

  const mobile = useMediaQuery(isMobileDown())
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const isEnhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements ?? false, [userSettings])

  useEffect(() => {
    const fetchComingUpData = async () => {
      const OnSuccess = (data: ComingUpRequestResponse) => setGridData(data)
      const onError = (err: any) => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
        setGridData(undefined)
      }
      getComingUpRequests(isEnhancementsSettingOn).then(OnSuccess).catch(onError)
    }
    fetchComingUpData().catch(error => console.error("Unhandled error in fetchComingUpData:", error))
  }, [dispatch, isEnhancementsSettingOn])
  
  
  const showDuration = (requestType: string, manualRequestType: string) => {
    const noDurationRequestType = ['day off', 'shift', 'birthday', 'on call', 'on call']
    const noDurationManualRequestType = ['maternity', 'paternity']
    
    const noDurationTypes = requestType.toLowerCase() === 'manual' ? noDurationManualRequestType  : noDurationRequestType
    
    return !noDurationTypes.find(x=> x === manualRequestType.toLowerCase())
  }

  const getType = (requestType: string, manualRequestType: string) => {
    if (requestType.toLowerCase() === 'manual') {
      return (
        <TypeLabel label={getPalleteTypeByProp(manualRequestType).label} type={manualRequestType} />
      )
    }
    const requestTypeStr = requestType.toLowerCase() === 'lieu day' ? 'lieu' : requestType

    return <TypeLabel label={getPalleteTypeByProp(requestTypeStr).label} type={requestType} />
  }

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date From',
      flex: 2,
      align: 'left',
      renderCell: cellValues => new Date(cellValues.row.date).toLocaleDateString(),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'requestType',
      headerName: 'Type',
      flex: 2,
      align: 'left',
      sortable: false,
      disableColumnMenu: true,
      renderCell: cellValues =>
        getType(cellValues.row.requestType, cellValues.row.manualRequestType),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      disableColumnMenu: true,
      renderCell: cellValues =>
        showDuration(cellValues.row.requestType, cellValues.row.manualRequestType)
          ? cellValues.row.duration.toFixed(2)
          : '',
    },
  ]
  const transformRequests = (requests: ComingUpCalendarRequest[]): PlottingCalendarRequest[] =>
      requests.map(m => ({
        id: m.id,
        submitDateTime: m.submitDateTime,
        dateFrom: m.dateFrom,
        dateTo: m.dateTo || m.dateFrom,
        statusDescription: m.statusDescription,
        isCancellation: m.isCancellation || false,
        isQueried: m.isQueried || false,
        requestType: m.requestType,
        days: m.days.map(d => ({
          date: format(new Date(d.date), 'yyyy-MM-dd'),
          hours: d.hours,
        })),
      }))
  

  const getRequestsData = useCallback(async () => {
    const onSuccess = (data: ComingUpRequestResponse) => {
      if (data?.requests) {
        const requests = transformRequests(data.requests)
        setPlottingFilters(requests.map(m => m.requestType))
        setApiData(requests)
      }
    }
    const onError = (err: any) => {
      const response: BaseResponse = err.response.data
      response.errors.forEach(error => {
        dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
      })
    }

    const fetchCalendarData = async () => {
      const startDate = new Date(currentYear, currentMonth, 1)
      const endDate = new Date(currentYear, currentMonth, lastDayOfMonth)
      const calendarMethod = isEnhancementsSettingOn ? dashboardService.getComingUpCalendarV2 : dashboardService.getComingUpCalendar

      try {
        const data = await calendarMethod(startDate, endDate, COMING_UP_REQUEST_TYPE_IDS, COMING_UP_MANUAL_TYPE_IDS)
        onSuccess(data)
      } catch (err) {
        onError(err)
      }
    }

    await fetchCalendarData()
  }, [currentMonth, currentYear, dispatch, lastDayOfMonth, isEnhancementsSettingOn])


  useEffect(() => {
    if (!apiData) {
      return
    }

    const now = new Date()
    const removedOldRequests = apiData.filter(a =>
      a.days.length
        ? a.days.some(
            d =>
              isBefore(formatDateWithTimeZone(now), formatDateWithTimeZone(d.date)) ||
              isToday(formatDateWithTimeZone(d.date))
          )
        : isToday(formatDateWithTimeZone(a.dateFrom))
    )

    const currentAndFutureRequests = removedOldRequests.map(request => (
      {
      ...request,
      days: request.days.filter (day => isBefore(formatDateWithTimeZone(now), formatDateWithTimeZone(day.date)) ||
      isToday(formatDateWithTimeZone(day.date)))
    }))

    const dates = buildData({
      requests: currentAndFutureRequests,
      filters: plottingFilters,
    })
    setPlottingDates(dates)
    setIsLoading(false)
  }, [apiData, plottingFilters])

  useEffect(() => {
    getRequestsData()
  }, [dispatch, getRequestsData])

  const viewEvent = useCallback((component: DrawerComponentProps | null) => {
    setDrawerComponent(component)
    setShowViewDrawer(true)
  }, [])

  const closeViewDrawer = useCallback(() => {
    setShowViewDrawer(false)
  }, [])

  const contextData = useMemo(
    () => ({ getRequestsData, viewEvent, closeViewDrawer }),
    [closeViewDrawer, getRequestsData, viewEvent]
  )

  useEffect(() => {
    if (!plottingDateRange) {
      return
    }

    let dates: Date[] = []
    let disabledDateStart
    let disabledDateEnd
    if (plottingDateRange.start.getDate() > 1) {
      // If first day of range is after the 1st,
      // then we need to disable those pre range-start dates
      disabledDateStart = startOfMonth(plottingDateRange.start)
      disabledDateEnd = subDays(plottingDateRange.start, 1)
      dates = eachDayOfInterval({
        start: disabledDateStart,
        end: disabledDateEnd,
      })
    }
    disabledDateEnd = endOfMonth(plottingDateRange.end)
    if (plottingDateRange.end.getDate() < disabledDateEnd.getDate()) {
      // If last day of range is before the last day of that month,
      // then we need to disable those post range-end dates
      const finalPlottingDate = new Date(plottingDateRange.end)
      finalPlottingDate.setDate(finalPlottingDate.getDate() + 1)
      dates = dates.concat(eachDayOfInterval({ start: finalPlottingDate, end: disabledDateEnd }))
    }
    setDisabledDates(dates)
  }, [plottingDateRange])

  return (
    <>
      <PageContext.Provider value={contextData}>
        <Drawer
          isOpen={showViewDrawer}
          onClose={() => {
            setShowViewDrawer(false)
          }}
          title={drawerComponent?.title || ''}
          status={drawerComponent?.status || ''}
          showOptions={false}
        >
          <Grid item p={4} pr={0}>
            {drawerComponent?.component}
          </Grid>
        </Drawer>
        <StyledCard>
          <ComingupHeaderOuterContainer>
            <ComingupHeaderBgContainer>
              <Typography variant="h4">Coming Up!</Typography>
              <Typography variant="subtitle2" sx={{ maxWidth: '50%' }}>
                Your next three events are shown&nbsp;here
              </Typography>
              <ComingUpImageBg src={ComingUpMegaPhoneBg} alt="Coming Up" />
            </ComingupHeaderBgContainer>
            <ComingupHeaderImageContainer>
              <ComingUpImage src={ComingUpMegaPhone} alt="Coming Up" />
            </ComingupHeaderImageContainer>
          </ComingupHeaderOuterContainer>
          <ComingupLowerContainer>
            <Box>
              <FullComponentLoadingIcon loading={isLoading} bgColor="transparent">
                <DashboardDataGrid
                  showHeaders
                  getRowId={row => row.requestId}
                  rows={gridData?.requests || []}
                  columns={columns}
                  density="comfortable"
                  disableSelectionOnClick
                  hideFooter
                  rowHeight={30}
                  headerHeight={40}
                  autoHeight
                  components={{ NoRowsOverlay }}
                  sx={{
                    '&.MuiDataGrid-root': {
                      border: 'none !important ',
                    },
                    '.MuiDataGrid-cell': {
                      borderBottom: 'none',
                    },
                    '.MuiDataGrid-columnHeaders': {
                      borderBottom: 'none',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      color: '#000',
                      fontSize: '13px',
                    },
                    '.MuiDataGrid-columnSeparator': {
                      visibility: 'hidden',
                    },
                    '.MuiDataGrid-columnHeaderTitleContainer': {
                      padding: '0px !important',
                    },
                    '.MuiDataGrid-row': {
                      '&:hover': {
                        backgroundColor: '#ffffff',
                      },
                    },
                    '.MuiDataGrid-virtualScroller': {
                      minHeight: '118px',
                    },
                  }}
                />
              </FullComponentLoadingIcon>

              {Boolean(gridData?.requests.length) && (
                <Link
                  to="/myavailability"
                  onClick={() => {
                    dispatch(setActiveRoute('/myavailability'))
                  }}
                  style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                >
                  <StyledLink>View All</StyledLink>
                </Link>
              )}
            </Box>
            {!mobile && (
              <ComingupCalendarContainer>
                <PlottingCalendars
                  dateRange={plottingDateRange || { start: new Date(), length: 1 }}
                  requestDates={plottingDates}
                  showEndDates={false}
                  disabledDates={disabledDates}
                  onBlankDayClick={(date: Date | null) => {
                    dispatch(setDefaultDateRange([date, date]))
                    dispatch(showMyRequestsDrawer())
                  }}
                  changingYear={isLoading}
                  isComingUp
                  data-testid="dashboard_calendar"
                />
              </ComingupCalendarContainer>
            )}
          </ComingupLowerContainer>
        </StyledCard>
        <Drawer
          isOpen={showSideDrawer}
          onClose={() => {
            dispatch(setDefaultDateRange(undefined))
            dispatch(hideMyRequestsDrawer())
          }}
          showOptions={false}
          title="New Request"
        >
          <Box sx={{ p: 4, pt: 0 }}>
            <NewRequest
              onClose={() => {
                dispatch(hideMyRequestsDrawer())
              }}
            />
          </Box>
        </Drawer>
      </PageContext.Provider>
    </>
  )
}

export default ComingUp
