import { useEffect, useState, useCallback, memo, useMemo } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Box, Grid, IconButton, Fab } from '@mui/material'
import { ArrowBackIosRounded as ArrowBackIosRoundedIcon } from '@mui/icons-material'
import { format } from 'date-fns'
import {
  BookingBookingPayload,
  BookingEmployeeBooking,
  BookingFeature,
  BookingSearchPayload,
} from '../../../services/booking/types'
import { BookingAvailabilityColors } from '../consts'
import { BookingNewBookingRange, PopoverProps } from '../Popover/types'
import { RootStore } from '../../../redux/store'
import { showErrorMessage, showInfoMessage } from '../../../redux/reducers/snackbarReducer'
import { FloorplanView } from './components'
import DeskPopover from '../Popover/Popover'
import DeskRectangle from '../Desks/DeskRectangle'
import { BookingZoom } from './BookingZoom'
import {
  setDeskBookingLoading,
  setDeskBookingShowMeBookingID,
  setDeskBookingShowGridView,
  setDeskBookingShowFloorplan,
  setDeskBookingInfinityBookingsResults,
} from '../../../redux/reducers/deskBookingReducer'
import BookingErrorMessage from '../utils/BookingErrorMessage'
import { formatDatTimeWithTimeZone } from '../../../utils/date-utils'
import {
  BookingFloorplanProps,
  BookingCancellationProps,
  BookingAdditionalInfoProps,
  BookingDynamicSVGCustomProps,
  BookingWeekdays,
} from './types'
import { BookingFeatureTypes, BookingStatus, BookingZIndexLayers } from './enums'
import { dateToNumber, getJson } from '../utils/utils'
import {
  getColor,
  getDatesFromBookings,
  getPortColor,
  handleSingleBooking,
  initialPopoverProps,
  sortFeatures,
} from './bookingFloorplanLogic'
import { setBookingWizardSelectedFeatureState } from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingWizardSteps } from '../BlockBooking/enums'
import FullComponentLoadingIcon from '../../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import { getDeskNameWithRow, handleSearch, handleServerError } from '../bookingLogic'
import { setDeskBookingSearchResults } from '../../../redux/reducers/deskBookingSearchReducer'
import { setSubmissionsInProgress } from '../../../redux/reducers/deskBookingStatusesReducer'
import { ErrorList } from '../../../utils/errorList'
import { bookingService } from '../../../services/booking/bookingService'
import { BaseResponse } from '../../../types/base-response'

const clamp = (min: number, num: number, max: number) => Math.max(min, Math.min(num, max))

export default function BookingFloorplanView({ onCancel }: BookingFloorplanProps) {
  const { mobileDetection } = useSelector((state: RootStore) => state.responsiveDetection)

  const { isMobile, isMobileLayout, mobilePortait } = mobileDetection

  const desktopViewBox = { width: 1000, height: 1550 }
  const mobileViewBoxLandscape = { width: 800, height: 1500 }
  const mobileViewBoxPortrait = { width: 1000, height: 2000 }

  const mobileViewBox = mobilePortait ? mobileViewBoxPortrait : mobileViewBoxLandscape
  const mobileOritentationZoomFactor = mobilePortait ? 7 : 4
  const mobileFloorplanDragSpeedFactorReduceBy = 10

  const defaultViewBox = isMobileLayout ? mobileViewBox : desktopViewBox

  const desktopZoom = 400
  const mobileZoom = 500

  const DEFAULT_ZOOM = isMobileLayout ? mobileZoom : desktopZoom

  const [touchStart, setTouchStart] = useState<React.TouchEvent | null>()
  const [touchEnd, setTouchEnd] = useState<React.TouchEvent | null>()

  const [deskPoppoverProps, setDeskPoppoverProps] = useState<PopoverProps>(initialPopoverProps())
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM)
  const [viewboxX, setViewboxX] = useState<number>(0)
  const [viewboxY, setViewboxY] = useState<number>(0)
  const [viewboxDrag, setViewboxDrag] = useState<boolean>(false)
  const [mouseDown, setMouseDown] = useState<{
    down: boolean
    at: number
    pageX: number
    pageY: number
  }>({ down: false, at: Date.now(), pageX: 0, pageY: 0 })
  const [loading, setLoading] = useState<boolean>(true)
  const [activeZYCoords, setActiveZYCoords] = useState<{
    x: number
    y: number
    w: number
    h: number
  } | null>()

  const SVGViewBoxConfigDesktop = `${viewboxX} ${viewboxY} ${defaultViewBox.width + zoom * 2} ${
    defaultViewBox.height + zoom * 2
  }`
  const SVGViewBoxConfigMobile = `${viewboxX} ${viewboxY} ${clamp(
    100,
    defaultViewBox.width - zoom / 2,
    10000
  )} ${clamp(1500, zoom * mobileOritentationZoomFactor, 10000)}`

  const SVGViewBoxConfig = isMobileLayout ? SVGViewBoxConfigMobile : SVGViewBoxConfigDesktop

  const dragFactor = 10

  const returnTouchEvents = (touchEvent: React.TouchEvent | null) => {
    if (!touchEvent) return { clientX: 0, clientY: 0 }

    const { targetTouches } = touchEvent
    const { 0: rootTouch } = targetTouches

    return rootTouch
  }

  const touchEventMismatch = (oldObj: any, newObj: any, callbackFunc: () => void) => {
    if (!oldObj || !newObj) return

    const touchStartEventXMismatch = oldObj.clientX !== newObj.clientX
    const touchStartEventYMismatch = oldObj.clientY !== newObj.clientY

    if (touchStartEventXMismatch || touchStartEventYMismatch) {
      callbackFunc()
    }
  }

  const [lastDistance, setLastDistance] = useState<number | null>(null)

  const onTouchStart = useCallback(
    (touchEvent: React.TouchEvent) => {
      setLastDistance(null)
      setTouchEnd(null)

      const touchStartEventStored = returnTouchEvents(touchStart || null)
      const touchStartEventNew = returnTouchEvents(touchEvent)

      touchEventMismatch(touchStartEventStored, touchStartEventNew, () => {
        setTouchStart(touchEvent)
      })
    },
    [touchStart]
  )

  const onTouchMove = useCallback(
    (touchEvent: React.TouchEvent) => {
      if (touchEvent.touches.length === 2) {
        // Prevent the default behavior of touch events
        touchEvent.preventDefault()

        // Calculate the distance between the two touch points
        const touch1 = touchEvent.touches[0]
        const touch2 = touchEvent.touches[1]
        const distance = Math.sqrt(
          (touch1.pageX - touch2.pageX) ** 2 + (touch1.pageY - touch2.pageY) ** 2
        )

        if (lastDistance !== null) {
          // Calculate the difference in distance between the current and last touch points
          const scaleChange = distance / lastDistance
          setZoom(prevScale => prevScale * scaleChange)
        }

        // Update the last distance for the next move event
        setLastDistance(distance)

        // don't scroll and zoom at the same time
        return
      }

      const touchEndEventStored = returnTouchEvents(touchEnd || null)
      const touchEndEventNew = returnTouchEvents(touchEvent)

      touchEventMismatch(touchEndEventStored, touchEndEventNew, () => {
        setTouchEnd(touchEvent)
      })
    },
    [lastDistance, touchEnd]
  )

  const onTouchEnd = () => {
    setLastDistance(null)
    setViewboxDrag(false)
  }

  const {
    locations,
    floorplans,
    openFloorplan,
    zones,
    showMeBookingID,
    floorplanViewingDate,
    featuresForFloorPlan,
    focussedZoneID,
    dashboardResults,
    bookingIsLoading,
    bookingSliderPosition,
    floorplanManuallySelected,
  } = useSelector((state: RootStore) => state.deskBooking)
  const { searchResults, searchParams } = useSelector((state: RootStore) => state.deskBookingSearch)
  const { submissionsInProgress } = useSelector((state: RootStore) => state.deskBookingStatus)
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)
  const {
    existingBookings,
    weekdaysSelected,
    currentStep: wizardCurrentStep,
    selectedDates,
  } = useSelector((state: RootStore) => state.deskBookingWizard)

  const dispatch = useDispatch()

  const repairTimeFrom = (from: string) => (from === '00:00:01' ? '00:00:00' : from)
  const repairTimeTo = (to: string) => (to === '23:00:00' ? '23:59:59' : to)

  const establishCurrentBookings = (featureId: number) => {
    if (wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE) {
      return searchResults?.filter(
        f =>
          f.featureId === featureId &&
          searchParams &&
          (repairTimeFrom(searchParams.from) === repairTimeFrom(f.fromTime) ||
            repairTimeTo(searchParams.to) === repairTimeTo(f.toTime))
      )
    }
    if (wizardCurrentStep === BookingWizardSteps.STEP_4_ALTERNATIVES) {
      return existingBookings.filter(
        f =>
          f.featureId === featureId &&
          dateToNumber(f.fromDate) === dateToNumber(floorplanViewingDate)
      )
    }
    return existingBookings.filter(f => f.featureId === featureId)
  }

  const isDifferentDeskFeatureBookingForEmployeeOnThisDate = (
    selectedFeatureId: number,
    thisFeature: BookingEmployeeBooking,
    thisEmployeeId: number
  ) =>
    searchParams &&
    thisFeature.feature.id !== selectedFeatureId &&
    thisFeature.employeeId === thisEmployeeId && // thisFeature.zone?.id &&
    `${thisFeature.fromDate}` === `${format(searchParams!.date, `yyyy-MM-dd`)}`

  const findExistingBookingsForEmpployeeWithinTimeframe = (
    bookingTimeFrom: string,
    bookingTimeTo: string,
    selectedFeatureId: number,
    thisEmployeeId: number
  ) =>
    dashboardResults?.results.filter(
      // need to update this and search results after cancel on mobile
      f =>
        isDifferentDeskFeatureBookingForEmployeeOnThisDate(selectedFeatureId, f, thisEmployeeId) &&
        (repairTimeFrom(bookingTimeFrom) === repairTimeFrom(f.fromTime) ||
          repairTimeTo(bookingTimeTo) === repairTimeTo(f.toTime))
    ).length

  const findIfBookingsExistForEmployee = (featureId: number, employeeId: number) =>
    (searchParams &&
      findExistingBookingsForEmpployeeWithinTimeframe(
        searchParams.from,
        searchParams.to,
        featureId,
        employeeId
      )) ||
    false

  const featureBookingWithinTimeframe = (
    featureId: number,
    singleBooking: false | BookingBookingPayload | undefined
  ) => {
    if (
      searchParams &&
      singleBooking &&
      dashboardResults?.results.filter(
        f =>
          f.feature.id === featureId &&
          (repairTimeFrom(searchParams.from) === repairTimeFrom(f.fromTime) ||
            repairTimeTo(searchParams.to) === repairTimeTo(f.toTime))
      ).length
    ) {
      return [singleBooking]
    }

    if (
      searchParams &&
      singleBooking &&
      !(
        repairTimeFrom(searchParams.from) === repairTimeFrom(singleBooking.fromTime) ||
        repairTimeTo(searchParams.to) === repairTimeTo(singleBooking.toTime)
      )
    ) {
      return []
    }

    return singleBooking ? [singleBooking] : establishCurrentBookings(featureId)
  }

  const resetPopoverProps = () => {
    setDeskPoppoverProps(current => ({ ...current, open: false }))
  }

  const applyPoppoverData = (
    e: SVGElement,
    floorplan: string,
    featureDetail: BookingFeature,
    isOwnBooking: boolean,
    isBlockBooking: boolean,
    isManager: boolean,
    availabilityColor: BookingAvailabilityColors
  ) => {
    if (!searchResults || !searchParams) {
      return
    }

    const featureId = featureDetail.id
    const featureIdIsAvailable = !searchResults.some(f => f.featureId === featureId)

    if (
      !isBlockBooking &&
      featureIdIsAvailable &&
      findIfBookingsExistForEmployee(featureId, employeeDetails.employeeId) &&
      !isManager
    ) {
      const alertObj = ErrorList.find(err => err.name === 'DeskAlreadyBookedForTheDay')
      dispatch(showInfoMessage(alertObj!.message))
      return
    }

    setDeskPoppoverProps({
      svgElement: e,
      open: true,
      floorplan,
      bookings: establishCurrentBookings(featureId),
      dateTimeFrom: formatDatTimeWithTimeZone(`${searchParams.date} ${searchParams.from}`),
      dateTimeTo: formatDatTimeWithTimeZone(`${searchParams.date} ${searchParams.to}`),
      featureDetail,
      isOwnBooking,
      isBlockBooking,
      isManager,
      availabilityColor,
    })
  }

  useEffect(() => {
    if (!showMeBookingID || showMeBookingID === undefined) {
      return
    }

    setTimeout(() => dispatch(setDeskBookingShowMeBookingID(undefined)), 2000)
  }, [dispatch, showMeBookingID && searchResults[0] && !bookingIsLoading])

  useEffect(() => {
    if (!featuresForFloorPlan || !searchParams) {
      return
    }

    setTimeout(() => {
      const heighestSVG = featuresForFloorPlan.sort((a, b) => {
        if (a.y < b.y) {
          return -1
        }
        if (a.y > b.y) {
          return 1
        }
        return 0
      })
      setViewboxY(heighestSVG[0].y + 200)
    }, 300)
  }, [dispatch, featuresForFloorPlan && searchResults[0]])

  useEffect(() => {
    resetPopoverProps()
  }, [])

  const loadingCheckOnce = useCallback(() => {
    setLoading(true)

    setTimeout(() => {
      if (searchResults !== undefined && !bookingIsLoading && dashboardResults?.results) {
        setLoading(false)
      }
    }, 300)
  }, [bookingIsLoading, searchResults, dashboardResults?.results])

  useEffect(() => {
    resetPopoverProps()

    loadingCheckOnce()
  }, [searchResults.length, dashboardResults?.results, bookingIsLoading])

  useEffect(() => {
    if (zones.length === 0 || !focussedZoneID) {
      return
    }

    setTimeout(() => {
      const zone = zones.find(f => f.id === focussedZoneID)

      if (!zone?.additionalInfo) {
        return
      }

      try {
        const json = getJson(zone?.additionalInfo)
        setViewboxX(json.svg[0].x)
        setViewboxY(json.svg[0].y)
        // console.log('tom- setViewboxX', viewboxX)
        // console.log('tom- setViewboxY', viewboxY)
      } catch (err) {
        /* empty - Ignore errors */
      }
    }, 13)
  }, [zones, focussedZoneID])

  const handleWheel = (e: React.WheelEvent<SVGSVGElement>) => {
    if (e.shiftKey) {
      if (e.deltaY > 0) {
        setViewboxX(viewboxX + e.screenX / 100)
      } else {
        setViewboxX(viewboxX - e.screenX / 100)
      }
      return
    }
    if (e.altKey) {
      if (e.deltaY > 0) {
        setViewboxY(viewboxY + e.screenX / 100)
      } else {
        setViewboxY(viewboxY - e.screenX / 100)
      }
      return
    }
    // deltaY is the amount of pixels that is moved in a vertical direction.
    // If you push the wheel forward, it's a negative number (-100) otherwise its a positive number (100).
    if (zoom + e.deltaY < -40) {
      // We have reached the largest zoom threshold allowed, so don't zoom in any more.
      return
    }
    setZoom(zoom + e.deltaY)
    // console.log('tom- handleWheel zoom', zoom)
  }

  const moreOrless = (more: number, than: number, factor: number) =>
    more + factor > than || more < than + factor

  useEffect(() => {
    if (!touchStart || !touchEnd) return

    const touchStartEvent = returnTouchEvents(touchStart)
    const touchEndEvent = returnTouchEvents(touchEnd)

    if (!touchStartEvent || !touchEndEvent) return

    const touchStartEventClientX = touchStartEvent.clientX
    const touchEndEventClientX = touchEndEvent.clientX

    const touchStartEventClientY = touchStartEvent.clientY
    const touchEndEventClientY = touchEndEvent.clientY

    if (moreOrless(touchStartEventClientX, touchEndEventClientX, dragFactor)) {
      const newXpos =
        viewboxX +
        (touchStartEventClientX - touchEndEvent.clientX) / mobileFloorplanDragSpeedFactorReduceBy

      if (viewboxX !== newXpos && moreOrless(newXpos, viewboxX, dragFactor)) setViewboxX(newXpos)
    }

    if (moreOrless(touchStartEventClientY, touchEndEventClientY, dragFactor)) {
      const newYpos =
        viewboxY +
        (touchStartEventClientY - touchEndEventClientY) / mobileFloorplanDragSpeedFactorReduceBy

      if (viewboxY !== newYpos && moreOrless(newYpos, viewboxY, dragFactor)) setViewboxY(newYpos)
    }
  }, [touchStart && touchEnd])

  /*
  useEffect(() => {

    setTimeout(() => {
      if (!isZone || !feature.x || !feature.y || !feature.width || !feature.height) {
        return
      }

      try {
        setActiveZYCoords({ x: feature.x, y: feature.y, w: feature.width, h: feature.height })
      } catch (err) {

      }
    }, 600)
  }, [isZone, feature])
  */

  /*
      onMouseEnter={e => {
        if (!isZone || !feature.x || !feature.y || !feature.width || !feature.height) {
          return
        }
        setActiveZYCoords({ x: feature.x, y: feature.y, w: feature.width, h: feature.height })
      }}
  */

  const renderRect = (feature: Partial<BookingFeature>, isZone: boolean) => {
    let colourFill = getColor(feature as BookingFeature)

    if (openFloorplan) {
      const featureWidth = feature?.width
      const featureZone = feature?.zone
      const featureZoneName = featureZone?.name
      const featureZoneId = featureZone?.id

      const isSmallFeatureWithNonNumericLabelAndZone =
        featureZoneName && feature.label === featureZoneName && featureZoneName?.match(/[A-Za-z]+/g)
      const isSmallFeatureLabelWithinZone =
        featureZoneId &&
        ((featureWidth && featureWidth > 50) || isSmallFeatureWithNonNumericLabelAndZone)
      const isSmalLFeatureLabelInOpenArea = !featureZoneId && featureZone?.additionalInfo === ''

      const featureFillIsZoneColourBadge = feature.fill === 'zone.colors.badge'

      if (isZone) return null
      if (
        featureFillIsZoneColourBadge &&
        (isSmallFeatureLabelWithinZone || isSmalLFeatureLabelInOpenArea)
      )
        return null
      if (featureFillIsZoneColourBadge) colourFill = '#B2B2B2'
    }

    return (
      <g id={String(feature?.id || 0)}>
        <rect
          id={`rect_${feature?.id}`}
          x={feature.x}
          y={feature.y}
          width={feature.width}
          height={feature.height}
          rx={feature.borderRadius}
          fill={colourFill}
        />
      </g>
    )
  }

  const renderText = (feature: BookingFeature) => {
    let parsedJson: BookingAdditionalInfoProps | null = null
    try {
      parsedJson = getJson(feature.additionalInfo)
      if (!parsedJson) {
        return null
      }
    } catch (e) {
      return null
    }
    const fontStyle = parsedJson?.fontStyle
    return (
      <text
        id={`text_${feature?.id}`}
        x={feature.x}
        y={feature.y}
        fontFamily={fontStyle?.fontFamily}
        fontSize={fontStyle?.fontSize}
        fill={feature.fill}
        style={{ userSelect: 'none' }}
      >
        {feature.label}
      </text>
    )
  }

  const renderSVGLine = (lineData: BookingDynamicSVGCustomProps) => (
    <line
      x1={lineData.x1}
      y1={lineData.y1}
      x2={lineData.x2}
      y2={lineData.y2}
      style={{ stroke: lineData.style?.stroke, strokeWidth: lineData.style?.strokeWidth }}
      strokeDasharray={lineData.strokeDasharray}
    />
  )

  const renderSVGCustom = (feature: BookingFeature) => {
    try {
      const json = getJson(feature.additionalInfo)
      return json.svg.map((m: BookingDynamicSVGCustomProps) => {
        switch (m.type.toLowerCase()) {
          case 'line':
            return renderSVGLine(m)
          default:
            return null
        }
      })
    } catch (ex) {
      return null
    }
  }

  const renderSVGType = (
    feature: string | BookingFeature,
    isZone: boolean
  ): (JSX.Element | null)[] => {
    switch ((feature as BookingFeature).typeId) {
      case BookingFeatureTypes.SVG_Rectangle:
        return [renderRect(feature as BookingFeature, isZone)]
      case BookingFeatureTypes.SVG_Text:
        return [renderText(feature as BookingFeature)]
      case BookingFeatureTypes.SVG_Generic:
        return [renderSVGCustom(feature as BookingFeature)]
      default:
        return [null]
    }
  }

  const datesFromBookings = useCallback(() => {
    let localBookings: BookingBookingPayload[]
    let localWeekdays: BookingWeekdays[] | undefined
    if (wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE) {
      localBookings = searchResults || []
    } else {
      localBookings = existingBookings || []
      localWeekdays = weekdaysSelected
    }
    return getDatesFromBookings(localBookings, localWeekdays)
  }, [wizardCurrentStep, searchResults, existingBookings, weekdaysSelected])

  if (!locations || !floorplans || !zones || !searchParams) {
    return null
  }

  const isPrivilegedUser =
    userPermissions.includes('BookingManager') || userPermissions.includes('IsSuperUser')

  const isBlockBooking = wizardCurrentStep !== BookingWizardSteps.STEP_0_INACTIVE
  const isFloorPlanView = bookingSliderPosition.buttonClick || floorplanManuallySelected
  const bookingZoomTopSingleFloorPlan = isFloorPlanView ? 5 : -20
  const bookingZoomTop = isBlockBooking ? -69 : bookingZoomTopSingleFloorPlan

  /*
  const isBlockBooking = useMemo(() => wizardCurrentStep !== BookingWizardSteps.STEP_0_INACTIVE, [wizardCurrentStep])
  const isFloorPlanView = useMemo(() => bookingSliderPosition.buttonClick || floorplanManuallySelected, [bookingSliderPosition, floorplanManuallySelected])
  const bookingZoomTopSingleFloorPlan = useMemo(() => isFloorPlanView ? 5 : -20, [isFloorPlanView])
  const bookingZoomTop = useMemo(() => isBlockBooking ? -69 : bookingZoomTopSingleFloorPlan, [isBlockBooking, bookingZoomTopSingleFloorPlan])
*/
  const controller = new AbortController()

  const handleSearchError = (err: string) => {
    dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
  }

  const getByQuerySearchResults = (
    floorplanId: number,
    date: Date,
    selectedRange: BookingNewBookingRange
  ) => {
    const selectedDate = format(date, 'yyyy-MM-dd')

    return bookingService
      .search(controller, {
        floorplanId,
        date: selectedDate,
        ...(deskPoppoverProps.dateTimeTo && {
          selectedDate,
        }),
        from: selectedRange.from,
        to: selectedRange.to,
      })
      .then(result => result)
      .catch(err => {
        const response: BaseResponse = handleServerError(err.response.data)
        if (response.status === 500) {
          dispatch(showErrorMessage(`Search Floorplan ${floorplanId} bookings failure`))
        }
        response.errors.forEach(error => {
          handleSearchError(error.name)
        })

        dispatch(setDeskBookingLoading(false))
      })
  }

  const isAlreadyBooked = (result: BookingSearchPayload, featureId: number) =>
    result.bookings.filter(
      (x: BookingBookingPayload) =>
        x.featureId === featureId && x.statusId === BookingStatus.ACCEPTED
    ).length > 0

  const { BOOKING_MOBILE_FLOORPLAN_VIEW_OVERLAY, BOOKING_MOBILE_FLOORPLAN_VIEW_BACK_BUTTON } =
    BookingZIndexLayers

  return (
    <FloorplanView container id="floorplan">
      {isMobile && isMobileLayout && deskPoppoverProps.open && (
        <Box
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(170,163,163,0.5144192122186495) 0%, rgba(74,74,74,0.8552552250803859) 64%)',
            opacity: 0.7,
            zIndex: BOOKING_MOBILE_FLOORPLAN_VIEW_OVERLAY,
          }}
          onClick={() => resetPopoverProps()}
        />
      )}
      {isMobileLayout && (
        <Fab
          style={{
            position: 'fixed',
            top: '80px',
            left: '20px',
            background: 'white',
            color: '#747474',
            opacity: 0.9,
            zIndex: BOOKING_MOBILE_FLOORPLAN_VIEW_BACK_BUTTON,
          }}
          size="small"
          color="secondary"
          aria-label="Back to results"
        >
          <IconButton
            color="default"
            size="small"
            onClick={() =>
              batch(() => {
                dispatch(setDeskBookingInfinityBookingsResults([]))
                dispatch(setDeskBookingShowFloorplan(false))
                dispatch(setDeskBookingShowGridView(true))
              })
            }
          >
            <ArrowBackIosRoundedIcon color="primary" />
          </IconButton>
        </Fab>
      )}
      <FullComponentLoadingIcon
        loading={loading}
        noData={!searchResults || !floorplans}
        bgColor="transparent"
      >
        <Grid item xs={12}>
          {!isMobileLayout && (
            <BookingZoom
              top={bookingZoomTop}
              factor={250}
              onZoom={factor => {
                setZoom(zoom - factor)
                // console.log('tom- BookingZoom', zoom)
              }}
            />
          )}
          <Box
            width="auto"
            height="100%"
            style={{ opacity: loading ? 0.25 : 1, cursor: mouseDown.down ? 'grab' : 'move' }}
          >
            {Boolean(featuresForFloorPlan?.length) && (
              <>
                <DeskPopover
                  svgElement={deskPoppoverProps.svgElement}
                  open={deskPoppoverProps.open}
                  featureDetail={deskPoppoverProps.featureDetail}
                  bookings={deskPoppoverProps.bookings}
                  isOwnBooking={deskPoppoverProps.isOwnBooking}
                  isBlockBooking={deskPoppoverProps.isBlockBooking}
                  isManager={deskPoppoverProps.isManager}
                  floorplan={deskPoppoverProps.floorplan}
                  dateTimeFrom={deskPoppoverProps.dateTimeFrom}
                  dateTimeTo={deskPoppoverProps.dateTimeTo}
                  availabilityColor={deskPoppoverProps.availabilityColor}
                  onClose={resetPopoverProps}
                  onSubmit={async (feature, deskName, { selectedRange, date }) => {
                    dispatch(setSubmissionsInProgress([...submissionsInProgress, feature.id]))
                    resetPopoverProps()
                    if (!searchResults || !userInfo?.name) {
                      return
                    }

                    const searchResultsResponse = await getByQuerySearchResults(
                      feature.floorPlanId,
                      date,
                      selectedRange
                    )

                    if (
                      searchResultsResponse &&
                      isAlreadyBooked(searchResultsResponse, feature.id)
                    ) {
                      const alertObj = ErrorList.find(
                        err => err.name === 'OtherAlreadyHasDeskBookedForTheDay'
                      )
                      dispatch(showErrorMessage(alertObj!.message))
                      dispatch(
                        setDeskBookingSearchResults([
                          ...searchResults.filter(f => f.statusId === BookingStatus.ACCEPTED),
                          ...searchResultsResponse.bookings.filter(
                            f => f.statusId === BookingStatus.ACCEPTED
                          ),
                        ])
                      )
                      return
                    }

                    await handleSingleBooking(
                      feature,
                      getDeskNameWithRow(feature),
                      { selectedRange, date },
                      locations,
                      floorplans,
                      employeeDetails.employeeId,
                      searchParams,
                      userInfo?.name,
                      floorplanViewingDate,
                      dispatch,
                      err => {
                        dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
                      },
                      submissionsInProgress
                    ).then(async () => {
                      if (isMobile) {
                        const searchResultsResponseNew = await getByQuerySearchResults(
                          feature.floorPlanId,
                          date,
                          selectedRange
                        )

                        if (searchResultsResponseNew) {
                          dispatch(
                            setDeskBookingSearchResults([
                              ...searchResults.filter(f => f.statusId === BookingStatus.ACCEPTED),
                              ...searchResultsResponseNew.bookings.filter(
                                f => f.statusId === BookingStatus.ACCEPTED
                              ),
                            ])
                          )
                        }
                      }
                    })
                  }}
                  onCancel={({
                    bookingID,
                    deskName,
                    floorPlanName,
                    location,
                    onCallBack,
                    byManager,
                  }: BookingCancellationProps) => {
                    if (!searchResults) {
                      return
                    }
                    onCancel?.({
                      bookingID,
                      deskName,
                      floorPlanName,
                      location,
                      byManager,
                      date: floorplanViewingDate,
                      onCallBack: success => {
                        dispatch(
                          setDeskBookingSearchResults(searchResults.filter(f => f.id !== bookingID))
                        )
                        resetPopoverProps()
                        onCallBack(success)
                      },
                    })
                  }}
                />
                <svg
                  width="100%"
                  height="100%"
                  style={{ touchAction: 'none' }}
                  // Desktop
                  //  viewBox={`${viewboxX} ${viewboxY} ${defaultViewBox.width + zoom * 2} ${
                  //    defaultViewBox.height + zoom * 2
                  //  }`}
                  // Mobile
                  // viewBox={`${viewboxX} ${viewboxY} ${defaultViewBox.width - zoom / 2} ${
                  //   zoom * 7
                  // }`}
                  viewBox={SVGViewBoxConfig}
                  onMouseDown={e => {
                    setMouseDown({
                      down: true,
                      at: Date.now(),
                      pageX: viewboxX + e.pageX,
                      pageY: viewboxY + e.pageY,
                    })
                    if (
                      isBlockBooking &&
                      wizardCurrentStep !== BookingWizardSteps.STEP_4_ALTERNATIVES
                    ) {
                      return dispatch(setBookingWizardSelectedFeatureState(undefined))
                    }
                  }}
                  onMouseUp={e => setMouseDown(current => ({ ...current, down: false }))}
                  onMouseMove={e => {
                    if (mouseDown.down) {
                      setViewboxX(mouseDown.pageX - e.pageX)
                      setViewboxY(mouseDown.pageY - e.pageY)
                    }
                  }}
                  onWheel={handleWheel}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={onTouchEnd}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {searchResults &&
                    searchParams &&
                    zones
                      .filter(f => f.floorPlanId === searchParams.floorplanId)
                      .map(m => {
                        let json
                        try {
                          json = getJson(m.additionalInfo)
                        } catch (err) {
                          /* empty */
                        }
                        if (json && json.svg && json.svg.length > 0) {
                          return (json.svg as Partial<BookingFeature>[]).map(ai => {
                            if (openFloorplan && ai.fill === 'zone.colors.badge') return null
                            return renderRect(
                              {
                                id: m.id,
                                x: ai.x || 0,
                                y: ai.y || 0,
                                width: ai.width || 0,
                                height: ai.height || 0,
                                borderRadius: ai.borderRadius || 0,
                                fill: ai.fill || '0',
                              },
                              true
                            )
                          })
                        }
                        return renderSVGType(m.additionalInfo, true)
                      })}
                  {featuresForFloorPlan &&
                    sortFeatures(featuresForFloorPlan).map(feature => {
                      const singleBooking =
                        wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE &&
                        searchResults?.find(f => f.featureId === feature.id)
                      const from = new Date(
                        `${format(floorplanViewingDate, 'yyyy-MM-dd')} 09:00:00`
                      )
                      const to = new Date(`${format(floorplanViewingDate, 'yyyy-MM-dd')} 17:30:00`)

                      /*
                      const isInRenderCoords = () => {
                        if (
                          !activeZYCoords ||
                          !feature.x ||
                          !feature.y ||
                          !feature.width ||
                          !feature.height
                        ) {
                          return false
                        }
                        return (
                          feature.x > activeZYCoords?.x &&
                          feature.y > activeZYCoords.y &&
                          feature.x < Number(activeZYCoords?.x) + Number(activeZYCoords?.w) &&
                          feature.y < Number(activeZYCoords?.y) + Number(activeZYCoords?.h)
                        )
                      }
                        */

                      return (
                        <>
                          {feature.typeId === 1 && (
                            <DeskRectangle
                              featureDetail={feature}
                              isActive={focussedZoneID === feature.zone.id} //  || isInRenderCoords()
                              requestedSlot={{
                                bookingRange: [from, to],
                              }}
                              availabilityColor={
                                !openFloorplan &&
                                ((feature.zone.id !== searchParams.zoneId &&
                                  feature.zone.id !== focussedZoneID) ||
                                  feature.zone.id !== focussedZoneID)
                                  ? BookingAvailabilityColors.NOT_ACTIVE
                                  : getPortColor(
                                      wizardCurrentStep === BookingWizardSteps.STEP_2_SELECT_DESK
                                        ? selectedDates.map(m => m.dateNumber)
                                        : [
                                            dateToNumber(
                                              floorplanViewingDate ||
                                                searchParams?.date ||
                                                new Date()
                                            ),
                                          ],
                                      datesFromBookings()
                                        .filter(
                                          f =>
                                            f.featureId === feature.id &&
                                            (repairTimeFrom(searchParams.from) ===
                                              repairTimeFrom(f.fromTime) ||
                                              repairTimeTo(searchParams.to) ===
                                                repairTimeTo(f.toTime))
                                        )
                                        .map(m => m)
                                    )
                              }
                              existingBookings={featureBookingWithinTimeframe(
                                feature.id,
                                singleBooking
                              )}
                              isBlockBooking={isBlockBooking}
                              onActionMouseEnter={(
                                e,
                                _availability,
                                isOwnBooking,
                                availabilityColor,
                                _bookings
                              ) => {
                                if (submissionsInProgress.some(s => s === feature.id)) {
                                  return resetPopoverProps()
                                }
                                if (mouseDown.down && Date.now() - mouseDown.at > 1000) {
                                  return
                                }
                                if (
                                  feature !== deskPoppoverProps.featureDetail &&
                                  deskPoppoverProps.open
                                ) {
                                  return resetPopoverProps()
                                }
                                // Stops you clicking desks in other zones that dont belong to you, even though dropdown lists allow you
                                /*
                                if (feature.zone.id !== searchParams.zoneId) {
                                  return
                                }
                                */
                                applyPoppoverData(
                                  e,
                                  floorplans.find(f => f.id === feature.floorPlanId)?.name || '',
                                  feature,
                                  isOwnBooking,
                                  isBlockBooking,
                                  isPrivilegedUser,
                                  availabilityColor
                                )
                              }}
                            />
                          )}
                          {(feature.typeId === BookingFeatureTypes.SVG_Rectangle ||
                            feature.typeId === BookingFeatureTypes.SVG_Text ||
                            feature.typeId === BookingFeatureTypes.SVG_Generic) &&
                            renderSVGType(feature, false)}
                        </>
                      )
                    })}
                </svg>
              </>
            )}
          </Box>
        </Grid>
      </FullComponentLoadingIcon>
    </FloorplanView>
  )
}
