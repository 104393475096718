import axios from 'axios'
import { Box, Grid, Tooltip, useMediaQuery } from '@mui/material'
import { eachDayOfInterval, endOfMonth, format, isWithinInterval, startOfMonth } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore } from '../../redux/store'
import { settingsService } from '../../services/settingsService'
import { wallChartService } from '../../services/wallchartServices'
import { EventPopup } from '../../shared/UI/EventPopup'
import { PopupFields, popupFieldsDefault } from '../../shared/UI/EventPopup/types'
import { statusTranslation, typeTranslation } from '../../shared/UI/EventPopup/utils'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import NoResult from '../../shared/UI/NoResult'
import Card from '../../shared/layout/Card'
import PageHeader from '../../shared/layout/PageHeader/PageHeader'
import { isMobileDown } from '../../theme/deviceChecks'
import { getPalleteTypeByProp } from '../../theme/palette'
import { BankHoliday } from '../../types/bank-holiday'
import { BaseResponse } from '../../types/base-response'
import { DepartmentThresholdsResponse } from '../../types/department-thresholds-response'
import {
  Day,
  WallChartItems,
  WallChartQueryProps,
  WallChartResponseBody,
} from '../../types/wallchart'
import {
  formatDateWithTimeZone,
  formatDateWithTimeZoneStr,
  groupDatesIntoRanges,
} from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import LegendsFooter from '../LegendsFooter/index'
import { LegendProps } from '../LegendsFooter/types'
import { WallChartBackdrop } from './WallChartBackdrop'
import { WallChartFilters } from './WallChartFilters'
import { WallChartHeader } from './WallChartHeader'
import { WallChartRow } from './WallChartRow/WallChartRow'
import { WallChartTimespanSelector } from './WallChartTimespanSelector'
import { WallChartSelectedTimeSpan } from './WallChartTimespanSelector/types'
import {
  RowName,
  WallChartContainer,
  WallChartRowItem,
  WallChartRowsBoxContainer,
} from './components'
import {
  ThresholdApiResponse,
  ThresholdDates,
  WallChartItemProps,
  WallChartItemsGrouped,
  WallChartProps,
} from './types'
import { buildThresholdsDayList } from './utils'

function WallChart({
  title,
  isWallChartView = true,
  filterPropertiesOverride,
  timeSpanPropertiesOverride,
}: WallChartProps) {
  const dispatch = useDispatch()
  const [wallChartData, setWallChartData] = useState<WallChartResponseBody>()
  const [legends, setLegends] = useState<LegendProps[]>([])
  const [popupProps, setPopupProps] = useState<PopupFields[]>([popupFieldsDefault])
  const [filterEvents, setFilterEvents] = useState<WallChartItems[]>([])
  const [gantAreaLoading, setGantAreaLoading] = useState(false)
  const [thresholds, setThresholds] = useState<DepartmentThresholdsResponse>()
  const [thresholdDates, setThresholdDates] = useState<ThresholdDates[]>()
  const [thresholdApiResponse, setThresholdApiResponse] = useState<ThresholdApiResponse[]>()
  const [localTimeSpan, setLocalTimespan] = useState<WallChartSelectedTimeSpan>()
  const [loadCount, setLoadCount] = useState<number>(20)
  const [backdropPos, setBackdropPos] = useState(0)

  const { selectedParams, selectedTimeSpan } = useSelector((state: RootStore) => state.wallChart)

  useEffect(() => {
    setBackdropPos(0)
    if (isWallChartView) {
      setLocalTimespan(selectedTimeSpan)
    } else {
      setLocalTimespan(timeSpanPropertiesOverride)
    }
  }, [selectedTimeSpan])

  const mobile = useMediaQuery(isMobileDown())

  const bankHolidays = useSelector<RootStore, BankHoliday[]>(
    (state: RootStore) => state.appSettings.bankHolidays
  )

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)

  const isManager = useMemo(
    () => userPermissions.includes('ViewManagerDirectReport'),
    [userPermissions]
  )

  useEffect(() => {
    if (!selectedParams.selectedDepartment || selectedParams.selectedDepartment.value === 0) {
      setLegends([])
      return
    }
    settingsService
      .getThresholds(selectedParams.selectedDepartment.value)
      .then(result => {
        setThresholds(result)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        if (response.errors && response.errors.length > 0) {
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        }
      })
  }, [selectedParams.selectedDepartment])

  const batchDateRanges = (item: WallChartItems): WallChartItemsGrouped => {
    const groupedItems: WallChartItemProps[] = []
    item.items.forEach(it => {
      const dateRanges = groupDatesIntoRanges(it.days.map(d => d.date))
      dateRanges.forEach(range => {
        if (!range) {
          return
        }
        const [from, to] = range
        const days: Day[] = []
        it.days.forEach(itd => {
          const itemTimestamp = formatDateWithTimeZone(itd.date).getTime()
          if (itemTimestamp >= from.getTime() && itemTimestamp <= to.getTime()) {
            days.push(itd)
          }
        })
        const hours = days.reduce((a, b) => a + b.hours, 0)
        groupedItems.push({
          id: it.id,
          dateFrom: new Date(range?.[0] || ''),
          dateTo: new Date(range?.[1] || ''),
          recordTypeStr: it.recordTypeStr,
          status: it.status,
          hours,
          days,
          isCancellation: it.isCancellation,
          isQueried: it.isQueried,
        })
      })
    })
    return {
      userDisplayName: item.userDisplayName,
      employeeId: item.employeeId,
      items: groupedItems.length ? [...groupedItems] : [],
    }
  }

  const buildDaysArray = (users: WallChartItems[]) => {
    const result = users.map(user => {
      const absenceWithDays = user.items.filter(item => item.days.length)
      const absenceWithNoDays = user.items.filter(item => !item.days.length)
      const absenceWithDaysAdded = absenceWithNoDays.map(absence => ({
        ...absence,
        days: eachDayOfInterval({
          start: new Date(absence.dateFrom),
          end: new Date(absence.dateTo || absence.dateFrom),
        }).map(day => ({
          date: day,
          hours: 0,
        })),
      }))
      return {
        userDisplayName: user.userDisplayName,
        employeeId: user.employeeId,
        items: absenceWithDays.concat(absenceWithDaysAdded),
      }
    })
    return result
  }

  const filterData = (result: WallChartResponseBody): WallChartResponseBody => {
    let filtered = result.items.map(item => ({
      userDisplayName: item.userDisplayName,
      employeeId: item.employeeId,
      items: item.items.filter(
        it =>
          it.dateFrom &&
          it.status?.toLowerCase() !== 'cancelled' &&
          it.status?.toLowerCase() !== 'declined' &&
          it.recordTypeStr?.toLowerCase() !== 'workfromhome' &&
          it.recordTypeStr?.toLowerCase() !== 'late'
      ),
    }))
    if (filtered.length) {
      filtered = buildDaysArray(filtered)
      filtered = filtered.map(item => batchDateRanges(item))

      return { items: filtered, thresholds: result.thresholds } as WallChartResponseBody
    }
    return { items: filtered, thresholds: result.thresholds } as WallChartResponseBody
  }

  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const isEnhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements ?? false, [userSettings])
  
  useEffect(() => {
    let dataQuery: WallChartQueryProps
    if (!isWallChartView && filterPropertiesOverride) {
      // This is being displayed from a request occurrence view
      dataQuery = filterPropertiesOverride
    } else if (
      // No params are currently set in state
      !selectedParams ||
      !selectedParams.selectedDepartment ||
      !selectedParams.selectedYear
    ) {
      return
    } else {
      // This is being displayed in the main WallChart page
      const currDate = new Date()
      const dateFrom = formatDateWithTimeZoneStr(
        selectedTimeSpan.startDate || startOfMonth(currDate)
      )
      const dateTostr = format(selectedTimeSpan.endDate, 'yyyy-MM-dd')

      const dateTo =
        dateTostr || formatDateWithTimeZoneStr(endOfMonth(formatDateWithTimeZone(currDate)))
      dataQuery = {
        dateFrom,
        dateTo,
        departmentId: selectedParams.selectedDepartment.value,
        directReportees: userPermissions.includes('ViewManagerDirectReport')
          ? selectedParams.directReport
          : false,
        departmentTeamIds: selectedParams.selectedTeams.map(team => team.teamId),
        employees: selectedParams.selectedEmployees,
      }
    }
    setGantAreaLoading(true)
    setLoadCount(20)
    const controller = new AbortController()
    const wallChartServiceMethod = isEnhancementsSettingOn && isManager ? wallChartService.getWallChartDataV2 : wallChartService.getWallChartData
    wallChartServiceMethod(dataQuery, controller)
      .then(result => {
        const filtered = filterData(result)
        setThresholdApiResponse(filtered.thresholds)
        setWallChartData(filtered)
        setGantAreaLoading(false)
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return
        }

        const response: BaseResponse = err.response.data
        if (response.errors && response.errors.length > 0) {
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        }
        setGantAreaLoading(false)
      })

    return () => {
      controller.abort()
    }
  }, [dispatch, selectedParams, selectedTimeSpan, isEnhancementsSettingOn])

  const isInRange = useCallback(
    (itemStartDate: Date, itemEndDate: Date) => {
      if (
        !localTimeSpan ||
        itemStartDate.getFullYear() < 2000 ||
        itemEndDate.getFullYear() < 2000
      ) {
        return false
      }
      return (
        // Is current event's start date within the timespan?
        isWithinInterval(itemStartDate, {
          start: localTimeSpan.startDate,
          end: localTimeSpan.endDate,
        }) ||
        // Is current event's end date within the timespan?
        isWithinInterval(itemEndDate, {
          start: localTimeSpan.startDate,
          end: localTimeSpan.endDate,
        }) ||
        // Do the current event's start and end dates straddle the entire timespan?
        isWithinInterval(localTimeSpan.startDate, {
          start: itemStartDate,
          end: itemEndDate,
        })
      )
    },
    [localTimeSpan]
  )

  useEffect(() => {
    const filtered: WallChartItems[] = wallChartData?.items || []
    const sortedWithEvents = filtered
      .filter(item => item.items.length)
      .sort((a, b) => (a.userDisplayName > b.userDisplayName ? 1 : -1))
    const sortedWithoutEvents = filtered
      .filter(item => !item.items.length)
      .sort((a, b) => (a.userDisplayName > b.userDisplayName ? 1 : -1))
    const derivedEvents = sortedWithEvents.concat(sortedWithoutEvents)
    setFilterEvents(derivedEvents)
    // At present, we only use colouring threshold indicators when a single team is selected
    if (localTimeSpan && selectedParams?.selectedTeams?.length === 1 && thresholds) {
      const deptThresholds = buildThresholdsDayList(
        thresholdApiResponse,
        localTimeSpan.startDate,
        localTimeSpan.endDate,
        selectedParams.selectedTeams[0].teamId,
        thresholds
      )
      setThresholdDates(deptThresholds)
    } else {
      setThresholdDates([])
    }
  }, [isInRange, wallChartData?.items])

  useEffect(() => {
    const types: LegendProps[] = []
    const multiRequestsLabel = getPalleteTypeByProp('multipleRequests')
    const pendingRequestLabel = getPalleteTypeByProp('pendingRequests')
    types.push(multiRequestsLabel, pendingRequestLabel)

    filterEvents?.forEach(person => {
      person?.items?.forEach(absenceEvent => {
        const localType = absenceEvent.recordTypeStr.replace(' (M)', '').replace(' Day', '')
        let recordTypeStr = getPalleteTypeByProp(localType)
        if (!recordTypeStr) {
          recordTypeStr = getPalleteTypeByProp('unknown')
        }
        return !types.includes(recordTypeStr) && types.push(recordTypeStr)
      })
    })
    setLegends(types)
  }, [filterEvents])

  const truncateName = (label: string): string => {
    if (label.indexOf(' ') < 0) {
      return label
    }
    const nameSplit = label.split(' ')
    return `${nameSplit[0]} ${nameSplit[1].substring(0, 1)}`
  }

  const getRelatedEvent = (
    hoverPointDate: Date | undefined,
    userDisplayName: string
  ): WallChartItemsGrouped | undefined => {
    if (!hoverPointDate) {
      return
    }
    let coincidingEvents = filterEvents.filter(item => item.userDisplayName === userDisplayName)[0]
    // Create non-referenced clone
    coincidingEvents = JSON.parse(JSON.stringify(coincidingEvents))
    // Check for coinciding events
    coincidingEvents.items = coincidingEvents.items.filter(eventItem =>
      // Is hovered event inside of another
      isWithinInterval(new Date(format(new Date(hoverPointDate), 'yyyy-MM-dd')), {
        start: new Date(format(new Date(eventItem.dateFrom), 'yyyy-MM-dd')),
        end: new Date(format(new Date(eventItem.dateTo), 'yyyy-MM-dd')),
      })
    )
    return coincidingEvents
  }

  const handleScroll = (e: any) => {
    setPopupProps([popupFieldsDefault])
    setBackdropPos(e.target.scrollTop)
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10
    if (bottom) {
      return setLoadCount(current => current + 10)
    }
    const top = e.target.scrollTop === 0
    if (top) {
      setLoadCount(20)
    }
  }

  const calculateStatusLabel = (prop: WallChartItemProps) => {
    if (!prop.status) return

    if (prop.isQueried) {
      return 'Queried'
    }
    return statusTranslation(prop.status)
  }

  return (
    <>
      {isWallChartView && <PageHeader title="Wall Chart" />}
      <Grid container>
        <Card title={title}>
          <>{isWallChartView && <WallChartFilters directReportEnabled={!gantAreaLoading} />}</>
          <>
            {isWallChartView && selectedParams && (
              <WallChartTimespanSelector directReportEnabled={!gantAreaLoading} />
            )}
          </>
          <>
            <LoadingIndicator show={gantAreaLoading} margin="50px 0" />
            {!gantAreaLoading && (
              <>
                <WallChartContainer
                  margin={isWallChartView ? '30px 0 0 0' : ''}
                  onMouseLeave={e => setPopupProps([popupFieldsDefault])}
                >
                  <>
                    {popupProps[0]?.open && (
                      <EventPopup
                        fields={popupProps}
                        baseUrl="wallchart"
                        onClose={() => setPopupProps([popupFieldsDefault])}
                      />
                    )}
                    {selectedParams && localTimeSpan ? (
                      <WallChartHeader
                        timespanStartDate={localTimeSpan?.startDate}
                        timespanSegments={localTimeSpan?.segments}
                        dayIndexOffset={
                          localTimeSpan?.startDate.getDay() === 1
                            ? 0
                            : Number(localTimeSpan?.startDate.getDay()) + 1
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <WallChartRowsBoxContainer onScroll={handleScroll}>
                      <WallChartBackdrop
                        thresholdDates={thresholdDates}
                        bankHolidays={bankHolidays}
                        scrollPosition={backdropPos}
                        selectedTimeSpan={localTimeSpan || selectedTimeSpan}
                      />
                      {selectedParams &&
                        localTimeSpan &&
                        wallChartData &&
                        filterEvents.slice(0, loadCount).map((employee, idx) => (
                          <Box display="flex">
                            <WallChartRowItem
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100px',
                                paddingTop: '16px',
                                zIndex: 1,
                              }}
                            >
                              <Tooltip title={employee.userDisplayName} placement="right">
                                <RowName>{truncateName(employee.userDisplayName)}</RowName>
                              </Tooltip>
                            </WallChartRowItem>
                            <WallChartRowItem flexGrow={1}>
                              <WallChartRow
                                timespanStartDate={localTimeSpan.startDate}
                                timespanEndDate={localTimeSpan.endDate}
                                timespanSegments={localTimeSpan.segments}
                                eventItem={employee}
                                onItemInfo={(target, _item, userDisplayName, hoverDate) => {
                                  if (_item?.recordTypeStr === '') {
                                    return
                                  }
                                  const popUpProps = getRelatedEvent(hoverDate, userDisplayName)
                                  if (!popUpProps || !isManager) {
                                    return
                                  }
                                  setPopupProps(
                                    popUpProps.items.map(item => {
                                      const { id, hours, recordTypeStr, isCancellation } = item
                                      return {
                                        open: true,
                                        id,
                                        hours: hours || 0,
                                        eventType: typeTranslation(recordTypeStr),
                                        requestType: recordTypeStr,
                                        anchorEl: target,
                                        disableEdit:
                                          employeeDetails.employeeId === employee.employeeId,
                                        eventStatus: isCancellation
                                          ? 'Cancellation Requested'
                                          : calculateStatusLabel(item),
                                      }
                                    })
                                  )
                                }}
                                onRowClick={() => {
                                  setPopupProps([popupFieldsDefault])
                                }}
                              />
                            </WallChartRowItem>
                          </Box>
                        ))}
                    </WallChartRowsBoxContainer>
                    {!wallChartData?.items.length && <NoResult message="No Results" showImage />}
                  </>
                </WallChartContainer>
                {isManager && (
                  <LegendsFooter
                    legendProps={legends}
                    onSelected={item => undefined}
                    sx={{ marginTop: '24px' }}
                  />
                )}
              </>
            )}
          </>
        </Card>
      </Grid>
    </>
  )
}

export default WallChart
