export type IndicatorTheme = {
  label: string
  fontColor: string
  bgColor: string
  borderColor: string
}
export const trendIncdicatorThemes = {
  overtime: {
    label: 'Overtime',
    fontColor: '#F0870C',
    bgColor: '#FEF8F1',
    borderColor: '#F0870C30',
  },

  night: {
    label: 'Night',
    fontColor: '#313057',
    bgColor: '#F4F4F6',
    borderColor: '#31305730',
  },

  onCall: {
    label: 'On Call',
    fontColor: '#401BBA',
    bgColor: '#F6F5FC',
    borderColor: '#401BBA30',
  },

  callOut: {
    label: 'Call Out',
    fontColor: '#BC18AF',
    bgColor: '#FBF4FB',
    borderColor: '#ebc0e5',
  },

  total: {
    label: 'Total',
    fontColor: '#555555',
    bgColor: '#FFFFFF',
    borderColor: '#55555530',
  },
}
export const titleStyles = {
  fontSize: '18px',
  color: '#000000',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '-0.72px',
  marginBottom: '16px',
}
export const totalCountStyles = {
  fontSize: '28px',
  color: '#000000',
  fontWeight: '600',
  letterSpacing: '-1.4px',
  lineHeight: '28px',
  marginLeft: '16px',
  marginRight: '4px',
}

export const cardStyles = {
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  boxShadow: '1px 2px 10px #00000015',
  flex: 1,
  borderRadius: '16px',
}
