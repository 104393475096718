import styled from '@emotion/styled'
import { Box, Button, Grid } from '@mui/material'
import { ToggleButtonProps } from './types'

export const ToggleButtonSlider = styled.div<ToggleButtonProps>`
  ${props => `
    @keyframes slide {
        0% {
        left: ${props.x}%;
        }
        100% {
            left: ${Number(props.x) + Number(props.width)}%;
        }
    }
    @keyframes slideBack {
        0% {
            left: ${Number(props.x) + Number(props.width)}%;
        }
        100% {
            left: ${props.x}%;
        }
    }
    & {
      background-color: white;
      width: ${props.width}%;
      height: 40px;
      position: absolute;
      border-radius: 4px;
      box-shadow: 1px 2px 3px 0 #0000000D;
      z-index: 0;
    }
    &.slider{
        animation: slideBack 250ms;
        left: ${props.x}%;
        }
    &.slidermove {
        animation: slide 250ms;
        left: ${Number(props.x) + Number(props.width)}%;
    }
    `}
`

export const ToggleTextButton = styled.button<ToggleButtonProps>`
  ${props => `
    & {
        background-color: transparent;
        width: ${props.width}%;
        height: 40px;
        position: relative;
        cursor: ${props.disabled ? 'default' : 'pointer'};
        left: ${props.x}px;
        top: 0;
        border-radius: 4px;
        border: none;
        padding: 0;
        opacity: ${props.opacity};
        z-index: 10;
        }
 `}
`

export const SelectButtonArrow = styled.div<{ enabled: boolean }>`
  ${props => `
  @keyframes bookingSelectArrowsfadeIn {
      0% {
        background-color: #fff;
      }
      100% {
        background-color: #C2EBE1;
      }
    }
    @keyframes fadeOut {
      0% {
        background-color: #C2EBE1;
      }
      100% {
        background-color: #fff;
      }
    }
    & {
      display: flex;
      justifycontent: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: ${props.enabled ? 'pointer' : 'default'};
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    &.fadedIn {
      animation: bookingSelectArrowsfadeIn 150ms;
      background-color: #C2EBE1;
    }
    &.fadedOut {
      animation: fadeOut 150ms;
      background-color: #fff;
    }
`}
`

export const CheckInButton = styled.a`
  ${props => `
    & { 
      font-family: Poppins;
      font-size: 11px;
      font-weight: bold;
      border-radius: 14px;
      width: 94px;
      text-align: center;
      color: white;
      padding: 6px 0;
      cursor: pointer;
    }
    &.bookingCheckIn{ background-color: #5BCF8B; }
    &.bookingCheckedIn{ 
      background-color: #8D8D8D;
      cursor: default;
    }
`}
`
export const BookingBullet = styled.span<{ color?: string }>`
  ${props => `
  & {
    height: 9px;
    width: 9px;
    background-color: ${props.color || '#BA7070'};
    border-radius: 50%;
    display: inline-block;
  }
`}
`

export const BookingZoomButton = styled(Button)`
  & {
    width: 42px;
    height: 44px;
  }
`

const BaseView = styled(Grid)`
  & {
    height: 800px;
  }
`

export const GridView = styled(BaseView)`
  & {
    z-index: 110;
  }
`

export const FloorplanView = styled(BaseView)`

  @media (max-height: 940px) {
    min-height: 790px;
    max-height: 790px;
    height: 100%;
  }

  @media (max-height: 700px) {
    min-height: 520px;
    max-height: 520px;
    height: 100%;
  }

  @media (max-height: 430px) {
    min-height: 280px;
    max-height: 280px;
    height: 100%;
  }

  & {
    z-index: 105;
    width: 99%;
    margin: 0 10px;
  }
`
export const BookingIllustrationContainer = styled(Box)`
  height: 160px;
  position: absolute;
  bottom: 0;
  right: -13px;
  bottom: -7px;
  opacity: 1;
`

export const BookingNoIllustrationContainer = styled(Box)`
  height: 165px;
  position: absolute;
  bottom: 0;
  left: 100px;
  bottom: -13px;
  opacity: 1;
`

export const BookingIllustrationImg = styled('img')`
  height: 100%;
  width: 100%;
`

export const NoDesk = styled('img')`
  height: auto;
  width: 70%;
  margin-left: -50px;
`

export const BookinResetSearch = styled('button')`
  & {
    border: none;
    background-color: white;
    color: #2c2965;
    margin-top: 8px;
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
  }
  &:hover svg {
    transform: rotate(-180deg);
    transition: transform ease 1s;
  }
`
