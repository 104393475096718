
import { ResponsiveDetectionMobileState } from '../../types/responsive-detection-mobile'

export const responsiveDetectionMobileStateInitialState: ResponsiveDetectionMobileState = {
  isMobile: false,
  isMobileLayout: false,
  mobilePortait: false,
  mobileLandscape: false,
  detectMobileDeviceLandscape: false,
  detectOritentationLandscape: false,
}

export interface ResponsiveDetectionState {
  mobileDetection: ResponsiveDetectionMobileState
}

export const responsiveDetectionStateInitialState: ResponsiveDetectionState = {
  mobileDetection: responsiveDetectionMobileStateInitialState
}

const SET_RESPONSIVE_DETECTION = 'SET_RESPONSIVE_DETECTION'


interface SetResponsiveDetectionState {
  type: typeof SET_RESPONSIVE_DETECTION
  payload: ResponsiveDetectionMobileState 
}


type responsiveDetectionDespatchTypes =
  | SetResponsiveDetectionState

export const setResponsiveDetection = (mobileDetection: ResponsiveDetectionMobileState): SetResponsiveDetectionState => ({
  type: SET_RESPONSIVE_DETECTION,
  payload: mobileDetection,
})

export const responsiveDetectionReducer = (
  state: ResponsiveDetectionState = responsiveDetectionStateInitialState,
  action: responsiveDetectionDespatchTypes
): ResponsiveDetectionState => {
  switch (action.type) {
    case SET_RESPONSIVE_DETECTION:
      return { ...state, mobileDetection: action.payload }
    default:
      return state
  }
}
