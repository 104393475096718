import { Grid, SelectChangeEvent, TextField } from '@mui/material'
import { format, isEqual, isWeekend } from 'date-fns'
import { useState, useEffect, useCallback, useRef, useMemo, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { DateRange } from '@mui/x-date-pickers-pro'
import { RequestType } from '../../models/request-type'
import { hideModal, hideDrawer, hideHeaderDrawer } from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { setAlerts, setSelectedDays } from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { Employee, SelectOption } from '../../services/dashboardService'
import { requestsService } from '../../services/requestsService'
import AutocompleteList from '../../shared/UI/AutocompleteList'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import Modal from '../../shared/UI/Modal'
import Paragraph from '../../shared/UI/Paragraph'
import { BaseResponse, ConflictMatch } from '../../types/base-response'
import { AbsenceCreateRequest, AbsenceOccurrence } from '../../types/absence'
import { getSelectValuesByType } from '../../utils/app-utils'
import {
  CLASH_OVERRIDE_MESSAGE,
  REQUIRED_MESSAGE,
  submitCancelButtons,
  absenceFormLabels,
  absenceFormModalClash,
} from '../../utils/constants'
import {
  formatDatTimeWithTimeZone,
  getLocalDateString,
  nextWorkingDay,
} from '../../utils/date-utils'
import { useForm } from '../../utils/useForm'
import MultiDayRequest from '../MultiDayRequest'
import {
  absenceComponentValidationSchema,
  AbsenceDrawerValidationErrors,
  AbsenceRequestModel,
  absenceRequestModelDefaultState,
} from './model'
import { manualRequestsService } from '../../services/myActionsService'
import UserErrorMessage from '../../utils/errorFilter'
import RequestRangeConflict from '../../types/request-range-conflict'
import Alert from '../../shared/UI/Alert/Alert'
import { stringifyDaysArray } from '../../utils/conversion'
import { calculatePartDayAbsence } from './AbsenceDrawerFunctions'
import { LocationGroups } from '../../types/location-groups'
import AbsenceFormFactory from './LocationAbsenceForm/absenceFormFactory'
import { settingsService } from '../../services/settingsService'
import { DateFormats } from '../../api/absence/tempTypes/generalprops'
import { AbsenceReasonTypesEnum, AbsenceTypesEnum } from '../Absence/types'

type Props = {
  extendAbsenceData?: AbsenceOccurrence
  onAbsenceSubmit?: () => void
}

export interface ShiftData {
  hours: number
  startTime: Date
  desc: string
  success: boolean
}

export const createAbsentFromDate = (shiftDate: Date): Date => {
  const newShiftDate = new Date(shiftDate)
  const now = new Date()
  const newAbsentFrom = new Date(
    newShiftDate.getFullYear(),
    newShiftDate.getMonth(),
    newShiftDate.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  )

  return newAbsentFrom
}

export const handleAbsentFromChanges = (
  isOpen: boolean,
  absentFromDate: Date | undefined,
  shiftDate: Date,
  setAbsentFromDate: (value: Date | undefined) => void,
  absenceData: AbsenceRequestModel,
  setData: (value: AbsenceRequestModel) => void
): void => {
  if (isOpen && !absentFromDate) {
    const newAbsentFrom = createAbsentFromDate(shiftDate)

    setData({ ...absenceData, absentFrom: newAbsentFrom })
    setAbsentFromDate(newAbsentFrom)
  }

  if (!isOpen && absentFromDate) {
    setAbsentFromDate(undefined)
  }
}

function AbsenceDrawerComponent({ extendAbsenceData, onAbsenceSubmit }: Props) {
  const dispatch = useAppDispatch()
  const [currentTypeAssociatedValueId, setCurrentTypeAssociatedValueId] = useState<number>(
    extendAbsenceData?.absenceDays[0].absenceType.reasonTypeId || 0
  )
  const [validationErrors, setValidationErrors] = useState<AbsenceDrawerValidationErrors>({})
  const { showModal, title, message, type, buttonLabel } = useSelector(
    (state: RootStore) => state.appSettings.modalProps
  )
  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)
  const { selectedDays } = useSelector((state: RootStore) => state.timeOff)
  const { directReports } = useSelector((state: RootStore) => state.appSettings)
  const [open, setOpen] = useState(false)
  const [isEmployeeSelected, setIsEmployeeSelected] = useState<boolean>(false)
  const [absenceReasonTypeList, setAbsenceReasonTypeList] = useState<SelectOption[]>([])
  const [absenceTypesList, setAbsenceTypesList] = useState<SelectOption[]>([])
  const [hasConflict, setHasConflict] = useState<boolean>()
  const [showClashModal, setShowClashModal] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [isDisableShiftDescription, setIsDisableShiftDescription] = useState<boolean>(false)
  const [absentFromDate, setAbsentFromDate] = useState<Date>()
  const [shiftData, setShiftData] = useState<ShiftData>()
  const [selectedEmployeeLocationName, setSelectedEmployeeLocationName] = useState<string>('')
  const [locationAbsenceTypesList, setLocationAbsenceTypesList] = useState<SelectOption[]>([])
  const [departmentWorksWeekends, setDepartmentWorksWeekends] = useState<boolean>(false)

  const [dateRange, setDateRange] = useState<DateRange<Date>>(
    extendAbsenceData?.endDate
      ? [
          nextWorkingDay(extendAbsenceData?.endDate, departmentWorksWeekends),
          nextWorkingDay(extendAbsenceData?.endDate, departmentWorksWeekends),
        ]
      : [new Date(), new Date()]
  )

  const { allEmployees } = useSelector((state: RootStore) => state.appSettings)

  const popperText = useMemo(
    () => (
      <>
        <Paragraph>Use this option to record the time the absence started.</Paragraph>
      </>
    ),
    []
  )

  const { alerts } = useSelector((state: RootStore) => state.timeOff)

  const gridContainerRef = useRef(null)

  const establishData = () => {
    let localData
    if (extendAbsenceData && dateRange[1] !== null) {
      localData = {
        absenceId: extendAbsenceData.id,
        employeeId: extendAbsenceData.employeeResponse.id,
        employeeName: extendAbsenceData.employeeResponse.displayName,
        absenceDate: extendAbsenceData.startDate,
        absentFrom: extendAbsenceData.startDate,
        endDate: extendAbsenceData.endDate,
        absenceTypeId: extendAbsenceData.absenceDays[0].absenceType.absenceTypeId || 0,
        absenceCategoryId: extendAbsenceData.absenceDays[0].absenceType.reasonTypeId || 0,
        shiftDescription: extendAbsenceData.absenceDays[0].expectedShift.shiftString || '',
        comments: extendAbsenceData.absenceDays[0].comments || '',
        contactDateTime: extendAbsenceData.absenceDays[0].contactDateTime,
        contactedByTypeId: extendAbsenceData.absenceDays[0].contactedByTypeId,
        contactComments: extendAbsenceData.absenceDays[0].contactComments || '',
        expectedReturn: nextWorkingDay(dateRange[1], departmentWorksWeekends),
        partDayAbsence: false,
        toWorkBack: extendAbsenceData.absenceDays[0].toWorkBack || false,
        sendFdn: false,
        hasHadLunch: false,
        days: extendAbsenceData.absenceDays.map(day => ({
          checked: true,
          day: day.date,
          hours: day.hoursLost,
        })),
        startTime: extendAbsenceData.absenceDays[0].expectedShift.shiftDate,
      }
      return localData
    }
    localData = absenceRequestModelDefaultState
    return localData
  }

  useEffect(() => {
    async function onLoad() {
      setAbsenceReasonTypeList(getSelectValuesByType('AbsenceReasonType'))
      setAbsenceTypesList(getSelectValuesByType('AbsenceType'))
      if (extendAbsenceData) {
        setIsEmployeeSelected(true)
        setSelectedEmployeeLocationName(extendAbsenceData.employeeResponse.locationGroupName || '')
        await settingsService.getSettings(extendAbsenceData.departmentId).then(res => {
          setDepartmentWorksWeekends(res.areWeekendsWorked)
          if (!res.areWeekendsWorked) {
            return
          }
          setDateRange([
            nextWorkingDay(extendAbsenceData?.endDate, res.areWeekendsWorked),
            nextWorkingDay(extendAbsenceData?.endDate, res.areWeekendsWorked),
          ])
        })
      }
    }

    onLoad()
  }, [])

  const {
    handleSubmit,
    handleChange,
    handleDateChange,
    data: absenceData,
    setData,
    handleCheckboxChange,
    updateState,
  } = useForm<AbsenceRequestModel>({
    initialValues: establishData(),

    validations: {
      ...absenceComponentValidationSchema,
      days: {
        custom: {
          isValid: (value: any) =>
            value && value.filter((day: any) => day.checked && day.hours <= 0).length === 0,
          message: REQUIRED_MESSAGE,
        },
      },
      contactDateTime: {
        custom: {
          isValid: value =>
            absenceData.contactedByTypeId === 0 ||
            (absenceData.contactedByTypeId !== 0 && value !== null),
          message: REQUIRED_MESSAGE,
        },
      },
      absentFrom: {
        custom: {
          isValid: value => {
            if (!absenceData.partDayAbsence) {
              return true
            }
            if (absenceData.partDayAbsence && value !== null) {
              return true
            }
            return false
          },
          message: REQUIRED_MESSAGE,
        },
      },
    },
  })

  const getShiftLength = useCallback(
    async (employeeId: number, shiftDate: Date) =>
      requestsService
        .getShiftDescription(employeeId, format(shiftDate, 'yyyy-MM-dd hh-mm-ss').slice(0, 10))
        .then(data => ({
          hours: data.shiftMinutes / 60,
          startTime: data.startTime,
          desc: data.shiftDescription ? data.shiftDescription : '9.00 - 17.30',
          success: true,
        }))
        .catch(err => {
          if (err.response.status === 404) {
            return {
              hours: 8,
              startTime: new Date(),
              desc: '9.00 - 17.30',
              success: false,
            }
          }
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        }),
    [dispatch]
  )

  const isDayChecked = (date: Date) => {
    if (departmentWorksWeekends) {
      return true
    }
    return !isWeekend(date)
  }

  const [isLoadingDays, setIsLoadingDays] = useState<boolean>(false)

  useEffect(() => {
    if (!absenceData.employeeId) {
      setHasConflict(false)
    }
    if (dateRange && dateRange[0] && dateRange[1] && absenceData.employeeId !== 0) {
      const currentDate = new Date(dateRange[0])
      const range: Date[] = []

      const setShiftLengths = async (dates: Date[]) => {
        let foundShift = false

        const allDays = dates.map(async date => {
          const shift = await getShiftLength(absenceData.employeeId, date)
          setShiftData(shift)
          if (!foundShift && shift?.success) {
            foundShift = true
          }
          if (isEqual(date, dateRange[1]!)) {
            setData({
              ...absenceData,
              shiftDescription: shift?.desc || '',
              startTime: formatDatTimeWithTimeZone(shift?.startTime || new Date()),
              absenceFrom: formatDatTimeWithTimeZone(shift?.startTime || new Date()),
            })
          }
          return {
            day: date,
            hours: shift?.hours ? shift.hours : 8,
            checked: isDayChecked(date),
          }
        })

        setIsLoadingDays(true)
        dispatch(setSelectedDays(await Promise.all(allDays)))
        setIsLoadingDays(false)
        setIsDisableShiftDescription(allDays.length === 1 && foundShift)
      }

      while (currentDate <= new Date(dateRange[1])) {
        range.push(new Date(format(currentDate, DateFormats.DATE_AND_TIME)))
        currentDate.setDate(currentDate.getDate() + 1)
      }
      setShiftLengths(range)
      manualRequestsService
        .getRequestRangeConflicts({
          employeeId: absenceData.employeeId,
          dateFrom: dateRange[0],
          dateTo: dateRange[1]!,
        })
        .then(data => {
          if (data.conflicts.length === 0) {
            setHasConflict(false)
          } else {
            dispatch(setAlerts(data.conflicts))
          }
        })
        .catch(err => {
          if (err.response.status === 409) {
            const mappedConflicts = err.response.data.conflicts.map(
              (conflict: RequestRangeConflict) => ({
                conflictType: conflict.type,
                requestType: conflict.requestTypeName,
                conflictDates: [conflict.date],
              })
            )
            dispatch(setAlerts(mappedConflicts))
            setHasConflict(true)
          }
        })
    } else {
      dispatch(setSelectedDays(null))
    }
  }, [
    dateRange,
    dispatch,
    getShiftLength,
    extendAbsenceData?.employeeResponse.id,
    absenceData.employeeId,
    setData,
  ])

  const calculateTotalDays = (): number => {
    if (selectedDays?.some(day => day.checked)) {
      const daysToCalculate = selectedDays.filter(x => x.checked)
      if (daysToCalculate.length === 0) {
        return 0
      }
      return selectedDays
        .filter(x => x.checked)
        .map(selectedDay => (!Number.isNaN(selectedDay.hours!) ? selectedDay.hours! : 0))
        .reduce((accumulator, currentValue) => {
          if (!accumulator && accumulator !== 0) {
            return 0
          }
          if (!currentValue) {
            return accumulator
          }
          return accumulator + currentValue
        })
    }
    return 0
  }

  const updateHours = useCallback(
    (selectedDay: Date, hours: string | null) => {
      if (selectedDays) {
        const updatedDay = selectedDays.find(day => day.day!.getTime() === selectedDay.getTime())
        if (updatedDay) {
          let hoursToFloat = hours ? parseFloat(hours) : 0
          if (hoursToFloat < 0) {
            hoursToFloat = 0.0
          }
          updatedDay.hours = hoursToFloat
          dispatch(setSelectedDays([...selectedDays]))
        }
      }
    },
    [dispatch, selectedDays]
  )

  const updateCheckedDay = (selectedDay: Date, hours: string) => {
    if (selectedDays) {
      const updatedDay = selectedDays.find(day => day.day!.getTime() === selectedDay.getTime())
      if (updatedDay) {
        updatedDay.checked = !updatedDay.checked
        dispatch(setSelectedDays([...selectedDays]))
      }
    }
  }

  useEffect(() => {
    if (stringifyDaysArray(selectedDays) !== stringifyDaysArray(absenceData.days)) {
      updateState('days', selectedDays)
    }
  }, [absenceData.days, selectedDays, updateState])

  function getNextScheduledShift(employeeId: number, date: Date): Promise<Date> {
    return new Promise<Date>((resolve, reject) => {
      requestsService
        .getNextScheduledShift(employeeId, format(new Date(date), 'yyyy-MM-dd'))
        .then(async (data: any) => {
          resolve(new Date(data.nextShift))
        })
        .catch(err => {
          const response: BaseResponse = err.response.data

          if (response.status === 404) {
            resolve(date)
          } else {
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          }
        })
    })
  }

  const handleEmployeeChange = async (event: any, employee: Employee | null) => {
    if (employee) {
      const expectedReturn: Date = await getNextScheduledShift(
        employee.employeeId,
        new Date(absenceData.endDate!)
      )
      const shift = await getShiftLength(employee.employeeId, new Date(absenceData.endDate!))

      setData({
        ...absenceData,
        employeeId: employee.employeeId,
        shiftDescription: shift?.desc || '',
        partDayAbsence: false,
        expectedReturn,
      })

      setIsEmployeeSelected(true)

      setSelectedEmployeeLocationName(employee.locationGroupName || '')
      settingsService.getSettings(employee.departmentId).then(res => {
        setDepartmentWorksWeekends(res.areWeekendsWorked)
      })
    } else {
      setData({ ...absenceData, employeeId: 0 })
      setIsEmployeeSelected(false)
      setSelectedEmployeeLocationName('')
    }
  }

  const handleDateRangeChange = async (newValue: DateRange<Date>) => {
    if (newValue[0] && newValue[1] === null) {
      setDateRange([newValue[0], null])
    }

    if (newValue[0] && newValue[1]) {
      try {
        const expectedReturn: Date = await getNextScheduledShift(
          absenceData.employeeId,
          newValue[1]
        )
        const shiftDescription = await getShiftLength(
          absenceData.employeeId,
          extendAbsenceData ? newValue[1] : newValue[0]
        )

        const newAbsentFrom = createAbsentFromDate(shiftDescription?.startTime ?? newValue[0])

        setData({
          ...absenceData,
          shiftDescription: shiftDescription?.desc || '',
          expectedReturn,
          absentFrom: newAbsentFrom,
          endDate: newValue[1],
          partDayAbsence: false,
        })

        setOpen(false)
        setDateRange(newValue)
        dispatch(setAlerts(null))
      } catch (error) {
        dispatch(showErrorMessage('Get request failed for scheduled shift data and description'))
      }
    }
  }

  const transformRequestBody = (): AbsenceCreateRequest => {
    const transformed = {
      absenceId: absenceData.absenceId,
      employeeId: absenceData.employeeId,
      absenceDate:
        typeof absenceData.absentFrom === 'string'
          ? absenceData.absentFrom
          : format(absenceData.absentFrom, DateFormats.DATE_AND_TIME),
      endDate:
        typeof absenceData.endDate === 'string'
          ? absenceData.endDate
          : format(absenceData.endDate, 'yyyy-MM-dd'),
      shiftDescription: absenceData.shiftDescription,
      absenceTypeReasonId: absenceData.absenceCategoryId, // Sickness, non-sickess etc
      absenceTypeId: absenceData.absenceTypeId, // The actual reason (Cold, Paternity, Broken-limb etc)
      comments: absenceData.comments,
      contactDateTime:
        typeof absenceData.contactDateTime === 'string'
          ? absenceData.contactDateTime
          : format(absenceData.contactDateTime, DateFormats.DATE_AND_TIME),
      contactedByTypeId: absenceData.contactedByTypeId,
      contactComments: absenceData.contactComments,
      expectedReturn:
        typeof absenceData.expectedReturn === 'string'
          ? absenceData.expectedReturn
          : format(absenceData.expectedReturn, 'yyyy-MM-dd'),
      toWorkBack: absenceData.toWorkBack,
      covidIsolationIndicator: absenceData.covidIsolationIndicator,
      sendFDN: absenceData.sendFdn,
      hasHadLunch: absenceData.hasHadLunch || false,
      overrideRequestDays: absenceData.overrideRequestDays,
      days: selectedDays
        ? selectedDays?.map(day => ({
            day: typeof day.day === 'string' ? day.day : format(day.day, DateFormats.Date),
            hours: day.hours,
            checked: day.checked,
          }))
        : [],
      partDayAbsence: absenceData.partDayAbsence,
    }
    return transformed
  }

  function submitAbsence(conflictOverride?: boolean) {
    setValidationErrors({})
    const errors: AbsenceDrawerValidationErrors = {}
    const isUKBasedAndUKTAOn = selectedEmployeeLocationName === LocationGroups.UK && territoryAttendanceUK
    const isNotSicknessAndReasonTypeNotSelected = absenceData.absenceCategoryId !== AbsenceTypesEnum.Sickness && absenceData.absenceTypeId === AbsenceReasonTypesEnum.NotSelected
    const hasNotSelectedReasonType = absenceData.absenceTypeId === AbsenceReasonTypesEnum.NotSelected

    if (absenceData.employeeId === 0) {
      errors.employeeId = true
    }

    if (
      isUKBasedAndUKTAOn ? isNotSicknessAndReasonTypeNotSelected : hasNotSelectedReasonType
    ) {
      errors.absenceTypeId = true
    }

    if (absenceData.absenceCategoryId === 0) {
      errors.absenceTypeReasonId = true
    }

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return
    }

    if (calculateTotalDays() === 0) {
      const gridItem = gridContainerRef.current! as any
      gridItem.scrollIntoView()
      dispatch(
        showErrorMessage(
          "Unable to save due to 'Total Hours' must be more than zero hours. Please ensure this is corrected and try again."
        )
      )
      return
    }

    if (hasConflict && !conflictOverride) {
      setShowClashModal(true)
      absenceData.overrideRequestDays = true
      return
    }

    setSubmitLoading(true)
    if (extendAbsenceData) {
      manualRequestsService
        .extendAbsence(transformRequestBody())
        .then(data => {
          setSubmitLoading(false)
          if (data.status === 200 && data.errors.length > 0) {
            data.errors.forEach(error => {
              dispatch(showErrorMessage('An absence already exists for this user in this period'))
            })
          } else {
            dispatch(showSuccessMessage('Extend absence has been successfully updated'))
            dispatch(hideHeaderDrawer())
            dispatch(hideDrawer())
          }
          if (onAbsenceSubmit) {
            onAbsenceSubmit!()
          }
        })
        .catch(err => {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
      return
    }

    if (selectedDays !== null) {
      requestsService
        .createAbsenceRequest(transformRequestBody(), false)
        .then(data => {
          setSubmitLoading(false)
          if (data.status === 200 && data.errors.length > 0) {
            data.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} description={error.description} />))
            })
          } else {
            dispatch(showSuccessMessage('Absence request has been sent'))
            dispatch(hideHeaderDrawer())
            dispatch(hideDrawer())
          }
        })
        .catch(err => {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }

  useEffect(() => {
    setData(current => {
      const { contactDateTime, ...rest } = current
      return { contactDateTime: new Date(), ...rest }
    })
  }, [absenceData.contactedByTypeId])

  const populateShiftHours = useCallback(async () => {
    if (selectedDays?.length !== 1) {
      return
    }

    await calculatePartDayAbsence(absenceData, selectedDays, dispatch, setData, absentFromDate)
  }, [absenceData, absentFromDate, dispatch, selectedDays, setData])

  const onShiftStringBlur = useCallback(
    async (
      e:
        | ChangeEvent<HTMLInputElement & HTMLSelectElement>
        | SelectChangeEvent<string>
        | SelectChangeEvent<number>
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      populateShiftHours()
    },
    [populateShiftHours]
  )

  useEffect(() => {
    populateShiftHours()
  }, [absenceData.partDayAbsence, absentFromDate, absenceData.hasHadLunch])

  useEffect(() => {
    if (!isEmployeeSelected || absenceData.employeeId === 0) {
      return
    }
    manualRequestsService
      .getAbsenceReasonsByEmployeeId(absenceData.employeeId)
      .then(response => {
        setLocationAbsenceTypesList(response.locationAbsenceReasons.options)
      })
      .catch(err => {
        setLocationAbsenceTypesList([])
      })
  }, [absenceData.employeeId, isEmployeeSelected])

  const absenceFormProps = {
    onShiftStringBlur,
    handleChange,
    handleCheckboxChange,
    popperText,
    isDisableShiftDescription,
    absenceReasonTypeList,
    absenceTypesList,
    open,
    setOpen,
    handleDateChange,
    dateRange,
    handleDateRangeChange,
    absenceData,
    extendAbsenceData,
    setData,
    setAbsentFromDate,
    currentTypeAssociatedValueId,
    setCurrentTypeAssociatedValueId,
    validationErrors,
    shiftData,
    setShiftData,
  }

  if (!absenceData) {
    return null
  }

  handleAbsentFromChanges(
    open,
    absentFromDate,
    dateRange[0] ?? new Date(),
    setAbsentFromDate,
    absenceData,
    setData
  )

  const handleClashMessage = (clashes: ConflictMatch[]) => {
    const selectedEmployee = allEmployees.find(
      emp => emp.employeeId === absenceData.employeeId
    )?.employeeName

    const requestTypes = clashes.map(clash => clash.requestType)
    const uniqueRequests = requestTypes.filter(
      (value, index, array) => array.indexOf(value) === index
    )

    const conflictDates = clashes.flatMap(clash =>
      getLocalDateString(clash.conflictDates.toString())
    )
    const dates = conflictDates.map(date => `${date}`)

    return `${selectedEmployee} has an existing ${uniqueRequests} request(s) on ${dates
      .join(', ')
      .toString()}. 
      Click Submit to override any existing request(s) on this date(s).`
  }

  return (
    <Grid container spacing={4} ref={gridContainerRef}>
      {hasConflict && alerts && (
        <Grid item xs={12}>
          <Alert severity="error" message={handleClashMessage(alerts)} />
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <Grid container xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paragraph weight="bold">{absenceFormLabels.absenceDetail}</Paragraph>
            </Grid>
            {extendAbsenceData ? (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Paragraph style={{ display: 'inline-flex' }}>
                  {absenceFormLabels.continuationOfAbsence}
                </Paragraph>
                <Paragraph style={{ display: 'inline-flex' }}>
                  {`${getLocalDateString(extendAbsenceData?.startDate)}`}
                </Paragraph>
              </Grid>
            ) : null}

            {extendAbsenceData ? (
              <Grid item xs={12}>
                <TextField
                  id="employee"
                  label={absenceFormLabels.employee}
                  value={absenceData?.employeeName}
                  disabled
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <AutocompleteList
                  id="employee"
                  label={absenceFormLabels.employee}
                  data={directReports}
                  textField="employeeName"
                  value={directReports.find(emp => emp.employeeId === absenceData.employeeId)}
                  onChange={handleEmployeeChange}
                  error={validationErrors.employeeId}
                  helperText={absenceFormLabels.helperRequired}
                  dataTestId="MyActions-Absence-Employee"
                />
                {!isEmployeeSelected && (
                  <Grid item mt={2}>
                    <Alert severity="info" message={absenceFormLabels.selectEmployee} />
                  </Grid>
                )}
              </Grid>
            )}
            {!isEmployeeSelected ? (
              <></>
            ) : (
              <AbsenceFormFactory
                locationGroupName={selectedEmployeeLocationName}
                absenceFormProps={absenceFormProps}
                locationAbsenceTypesList={locationAbsenceTypesList}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {isEmployeeSelected && (
        <Grid item xs={12} lg={6} pl={4}>
          <Grid item xs={12}>
            <MultiDayRequest
              type={RequestType.HOLIDAY}
              cardTitle="Holiday"
              handleSubmit={handleSubmit}
              calculateTotalDays={calculateTotalDays}
              updateHours={updateHours}
              updateCheckedDay={updateCheckedDay}
              summaryTitle={absenceFormLabels.occurrenceSummary}
              summaryOnly
              isLoading={isLoadingDays}
              dataTestId="MyActions-Absense"
            />
          </Grid>
          {hasConflict && (
            <Grid item xs={12}>
              <Paragraph color="#E75E5E" weight="bold">
                {CLASH_OVERRIDE_MESSAGE}
              </Paragraph>
            </Grid>
          )}
        </Grid>
      )}
      <DrawerFooter>
        <Button
          color="secondary"
          label={submitCancelButtons.cancel}
          onClick={() => {
            dispatch(hideHeaderDrawer())
            dispatch(hideDrawer())
          }}
          dataTestId="MyActions-Absence-CancelBtn"
        />
        <Button
          label={submitCancelButtons.submit}
          type="submit"
          onClick={() => submitAbsence()}
          loading={submitLoading}
          dataTestId="MyActions-Absence-SubmitBtn"
        />
      </DrawerFooter>

      <Modal
        type="clash"
        open={showClashModal}
        onClose={() => {
          setShowClashModal(false)
        }}
        title={absenceFormModalClash.title}
        message={absenceFormModalClash.message}
        buttonLabel={absenceFormModalClash.button}
        onClick={() => {
          setShowClashModal(false)
          submitAbsence(true)
        }}
      />
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </Grid>
  )
}

export default AbsenceDrawerComponent
