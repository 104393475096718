import { Box, IconButton, Stack, SxProps, Theme, Tooltip } from '@mui/material'
import {
  AccessTime as AccessTimeIcon,
  LocationOn as LocationOnIcon,
  DeleteOutline as DeleteOutlineIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
} from '@mui/icons-material'
import { format, isAfter, isToday } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useState, memo } from 'react'
import { BookingContainerProps } from './types'
import Paragraph from '../../../shared/UI/Paragraph'
import { WEEKDAYS_SHORT } from '../../../utils/constants'
import { formatDateWithTimeZone } from '../../../utils/date-utils'
import { BookingAction } from '../Shared/enums'
import { setBookingFloorplanViewingDate } from '../../../redux/reducers/deskBookingReducer'
import { RootStore } from '../../../redux/store'
import { textIconStyle } from './styles'
import { dateToNumber } from '../utils/utils'

import theme from '../../../theme/theme'
import { setBookingWizardStepState } from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingWizardSteps } from '../BlockBooking/enums'

const COLOR_TODAY = '#2CC5A0'
const COLOR_FONT_BEFORE_TODAY = '#E0E0E0'
const COLOR_FONT_AFTER_TODAY = '#2c2965'
const COLOR_TODAY_BG = '#FFFFFF'
const COLOR_NOT_TODAY_BG = '#FCFCFC'
const CONTAINER_BORDER_TODAY = '2px solid #2CC5A0'
const CONTAINER_BORDER_NOT_TODAY = '1px solid #E0E0E0'

const EMPTY_STYLES:object = {}

interface STYLES_PASSTHROUGH {
  (obj: SxProps<Theme> | object): SxProps<Theme> | object;
}

const STYLES_PASSTHROUGH: STYLES_PASSTHROUGH = (obj) => obj || EMPTY_STYLES
const EMPTY_STYLES_MOBILE = () => EMPTY_STYLES
// const EMPTY_STYLES_DESKTOP = () => EMPTY_STYLES
const STYLES_MOBILE = (obj: SxProps<Theme> | object) => STYLES_PASSTHROUGH(obj)
const STYLES_DESKTOP = (obj: SxProps<Theme> | object) => STYLES_PASSTHROUGH(obj)


const dayFontColor = (date: Date) => {
  if (isAfter(formatDateWithTimeZone(new Date()), formatDateWithTimeZone(date))) {
    return COLOR_FONT_BEFORE_TODAY
  }
  if (isAfter(formatDateWithTimeZone(date), formatDateWithTimeZone(new Date()))) {
    return COLOR_FONT_AFTER_TODAY
  }
}

const bookingWindow = (from: Date, to: Date) => {
  const fullDateFrom = new Date(format(from, 'yyyy-MM-dd HH:mm:ss'))
  const fullDateTo = new Date(format(to, 'yyyy-MM-dd HH:mm:ss'))
  if (fullDateTo.getHours() <= 12) {
    return 'First half'
  }
  if (fullDateFrom.getHours() >= 12) {
    return 'Second half'
  }
  return 'Full day'
}

export function BookingContainer({
  bookingID,
  date,
  dateFrom,
  dateTo,
  zone,
  invalidBooking,
  featureLabel,
  components,
  location,
  isCheckedIn,
  bookedFor = '',
  onCancel,
  onShowMe,
  ref,
}: BookingContainerProps) {
  const [checkedIn, setCheckedIn] = useState<boolean>(isCheckedIn)
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)

  const dispatch = useDispatch()


  const {
    mobileDetection
  } = useSelector((state: RootStore) => state.responsiveDetection)

  const { isMobileLayout, detectMobileDeviceLandscape, detectOritentationLandscape } = mobileDetection


  const responsiveStyle = (mainStyleObject: any, mobileStyleObject: any) => {

    if (!detectOritentationLandscape || !isMobileLayout || !isMobileLayout && detectMobileDeviceLandscape)
      return mainStyleObject

    return { ...mainStyleObject, ...mobileStyleObject } as SxProps<Theme>
  }

  const responsiveFlexDirection = () => responsiveStyle(STYLES_MOBILE({ xs: 'column' }), STYLES_DESKTOP({ sm: 'row' }))

  const responsiveBookingDateText = () => responsiveStyle(STYLES_MOBILE({ xs: 16 }), STYLES_DESKTOP({ sm: 18 }))

  const responsiveBookingDetailsIcon = () => ({
    fontSize: responsiveStyle(STYLES_MOBILE({xs: 13, sm: 14, md: 13 }), STYLES_DESKTOP({ sm: 18 })),
    marginLeft: responsiveStyle(EMPTY_STYLES_MOBILE(), STYLES_DESKTOP({ sm: 1 })),
    marginRight: responsiveStyle(EMPTY_STYLES_MOBILE(), STYLES_DESKTOP({ sm: 0.5 })),
    marginTop: responsiveStyle(STYLES_MOBILE({xs: 0.3, md: 0.5 }), STYLES_DESKTOP({ sm: 0.2 }))
  })

  const responsiveBookingDetailsText = () => ({
    fontSize: responsiveStyle(STYLES_MOBILE({ xs: 13 }), STYLES_DESKTOP({ sm: 15 })),
    marginRight: responsiveStyle(EMPTY_STYLES_MOBILE(), STYLES_DESKTOP({ sm: 2 }))
  })


  const handleEvent = (action: BookingAction) => {
    switch (action) {
      case BookingAction.SHOW_ME:
        onShowMe()
        dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))
        return dispatch(setBookingFloorplanViewingDate(date)) // maybe?
      case BookingAction.CHECK_IN:
        return setCheckedIn(true)
      case BookingAction.CANCEL:
        return onCancel?.({
          bookingID,
          deskName: featureLabel,
          location,
          byManager: false,
          floorPlanName: location,
          date: dateFrom,
          onCallBack: (success = true) => success,
        })
      default:
    }
  }

  const compareDayInt = dateToNumber(date)
  const todayInt = dateToNumber(new Date())
  const canCheckInOut = compareDayInt === todayInt

  const selectButtonLinks = () => {
    const links = []
    links.push({
      label: 'Show me',
      bookingID,
      action: BookingAction.SHOW_ME,
      icon: <LocationOnOutlinedIcon />,
    })
    links.push({
      label: 'Cancel',
      bookingID,
      action: BookingAction.CANCEL,
      icon: <DeleteOutlineIcon />,
    })
    return links
  }

  return (
    <Box
      ref={ref}
      id={`booking_${bookingID}`}
      border={isToday(date) ? CONTAINER_BORDER_TODAY : CONTAINER_BORDER_NOT_TODAY}
      bgcolor={isToday(date) ? COLOR_TODAY_BG : COLOR_NOT_TODAY_BG}
      borderRadius={4}
      mb={2}
      height={responsiveStyle(STYLES_MOBILE({ xs: "80px" }), STYLES_DESKTOP({ sm: "60px" }))}
      display="flex"
      width="98%"
    >
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        {/* Date Detail */}
        {!isToday(date) && (
          <Box
            display="flex"
            flexDirection={responsiveFlexDirection()}
            borderRight="solid 1px #E0E0E0"
            ml={1}
            minWidth="80px"
            justifyContent="center"
            alignItems="center"
            height="54px"
          >
            <Paragraph
              sx={{
                fontSize: responsiveBookingDateText(),
                marginRight: responsiveStyle(EMPTY_STYLES_MOBILE(), STYLES_DESKTOP({ sm: 0.5 })),
              }}
              weight="bold"
              color={dayFontColor(date)}
              style={{ textAlign: 'center', lineHeight: 1 }}
            >
              {WEEKDAYS_SHORT[date.getDay()]}
            </Paragraph>
            <Paragraph
              sx={{
                fontSize: responsiveStyle(STYLES_MOBILE({ xs: 35 }), STYLES_DESKTOP({ sm: 18 }))
              }}
              weight="bold"
              color={dayFontColor(date)}
              style={{ textAlign: 'center', lineHeight: 1.1 }}
            >
              {date.getDate()}
            </Paragraph>
          </Box>
        )}
        {isToday(date) && (
          <Box
            display="flex"
            flexDirection={responsiveFlexDirection()}
            borderRight="solid 1px #E0E0E0"
            ml={1}
            minWidth="80px"
            justifyContent="center"
            alignItems="center"
            height="54px"
          >
            <Paragraph
              sx={{
                fontSize: responsiveBookingDateText()
              }}
              weight="bold"
              color={COLOR_TODAY}
              style={{ textAlign: 'center' }}
            >
              Today
            </Paragraph>
          </Box>
        )}
        {/* Date Detail END */}
        {/* Booking Detail */}
        <Box display="flex" flexDirection={responsiveFlexDirection()} ml={1} flexGrow={1} overflow="hidden">
          <Stack direction="row" display="flex" alignItems="center" justifyContent="left">
            <AccessTimeIcon
              style={textIconStyle}
              sx={responsiveBookingDetailsIcon()}
            />
            <Box sx={{ width: 120 }}>
              <Paragraph
                sx={responsiveBookingDetailsText()}
                color="#747474"
                style={{
                  marginLeft: '8px',
                }}
              >
                {`${bookingWindow(dateFrom, dateTo)}`}
              </Paragraph>
            </Box>
          </Stack>
          <Stack direction="row" display="flex" alignItems="center" justifyContent="left">
            <LocationOnIcon
              style={textIconStyle}
              sx={responsiveBookingDetailsIcon()}
            />
            <Paragraph
              color="#747474"
              style={{
                marginLeft: '8px',
                textWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left'
              }}
              sx={responsiveBookingDetailsText()}
            >
              {`${location}, ${featureLabel}${zone !== '' ? ', ' : ''}${zone}`}
            </Paragraph>
          </Stack>
        </Box>
        {/* Booking Detail END */}
        {/* Actions */}
        <Box display="flex" flexDirection="row" width="80px" mr={1} justifyContent="flex-end">
          {!invalidBooking && (
            <IconButton
              onClick={() => {
                handleEvent(BookingAction.SHOW_ME)
              }}
            >
              <Tooltip title="Show me" placement="top">
                <LocationOnOutlinedIcon sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              handleEvent(BookingAction.CANCEL)
            }}
          >
            <Tooltip title="Cancel desk" placement="top">
              <DeleteOutlineIcon sx={{ color: theme.palette.primary.main }} />
            </Tooltip>
          </IconButton>
        </Box>
        {/* Actions END */}
      </Box>
    </Box>
  )
}
