import {} from 'react-dom'
import { PlottingCalendarDayProps } from './types'
import { PlottingCalendarButton } from './components'

export function PlottingCalendarDay({
  date,
  isDisabled,
  showInCalendar,
  requests,
  onClick,
}: PlottingCalendarDayProps) {
  return (
    <>
      {showInCalendar && (
        <PlottingCalendarButton
          disabled={isDisabled}
          isPlotted={Boolean(requests)}
          type="button"
          onClick={e => onClick?.(e, date, requests)}
          data-testid={`calendar_day_${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`}
        >
          {showInCalendar && date.getDate()}
        </PlottingCalendarButton>
      )}
    </>
  )
}
