import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { hasUserAccessToRoute } from '../../../utils/app-utils'
import { RootStore } from '../../../redux/store'
import { EmployeeDetailsResponse } from '../../../types/employee'
import { v2MyActionsService } from '../../../services/myActionsServiceV2'
import { EnhancementGetResponse } from '../../../models/enhancement'
import { useDefaultErrorHandler } from '../../../utils/Hooks/useDefaultErrorHandler'
import { calculateTotalHours } from '../../MyRequests/utils'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import NoDataFound from '../../../shared/UI/NoDataFound'
import MyActionsPageHeader from '../../../shared/UI/MyActionsPageHeader'
import InstanceStatusBanner from '../../../shared/UI/InstanceStatusBanner'
import { getRequestStatusDisplayName } from '../../../utils/SharedMethods/getRequestStatusDisplayName'
import { CallOutDuration } from './CallOutDuration'
import { CallOutDetail } from './CallOutDetail'
import { CallOutApproval } from './CallOutApproval'

export function CallOutRequestDetail() {
  const { id: enhancementId } = useParams<{ id: string }>()
  const [enhancement, setEnhancement] = useState<EnhancementGetResponse>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalHours, setTotalHours] = useState<string>('')
  const defaultErrorHandler = useDefaultErrorHandler()

  const pageURL = '/myactions'
  const navigate = useNavigate()
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])

  // Check if user has the permissions to view this page and the feature toggle is on
  useEffect(() => {
    if (!userPermissions || !userSettings) {
      return
    }

    if (
      !hasUserAccessToRoute(pageURL, userPermissions, employeeDetails.isContractor, userSettings)
    ) {
      navigate('/dashboard')
    }
  }, [employeeDetails.isContractor, userSettings, enhancementsSettingOn, navigate, userPermissions])

  const getEnhancementDetails = () => {
    v2MyActionsService
      .getEnhancementForManager(Number(enhancementId))
      .then((response: EnhancementGetResponse) => {
        setTotalHours(calculateTotalHours(response.days!).toFixed(2))
        setEnhancement(response)
      })
      .catch(defaultErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (enhancementId) {
      setIsLoading(true)
      getEnhancementDetails()
    }
  }, [enhancementId])

  const dataAvailable = enhancement && enhancement.currentStatus

  return dataAvailable ? (
    <>
      <MyActionsPageHeader title={`${enhancement?.enhancementType || ''} Request`} hideNewRequest />
      <Box mb={3}>
        <InstanceStatusBanner
          showBackButton
          displayName={enhancement.employeeDetails?.displayName}
          status={getRequestStatusDisplayName(enhancement.currentStatus)}
          requestId={enhancement.id}
        />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <CallOutDuration
            totalHours={totalHours}
            dateRange={enhancement.dateRange}
            comment={enhancement.calloutDetails?.reason}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CallOutDetail callOutDetails={enhancement.calloutDetails} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CallOutApproval
            statusHistory={enhancement.statusHistory}
            currentStatus={enhancement.currentStatus!}
            initialTotalHours={enhancement.calloutDetails?.paidHours ?? Number(totalHours)}
            id={enhancement.id}
            dateRange={enhancement.dateRange}
            days={enhancement.days}
            onStatusChangeSuccess={getEnhancementDetails}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <NoDataFound show={!isLoading} />
      <LoadingIndicator show={isLoading} />
    </>
  )
}
