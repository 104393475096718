import { Grid, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { MyRequestsCustomChip, MyRequestsLegendsStickyContainer } from './components'
import { MyRequstsLegendsFilterProps } from './types'
import TypeLabel from '../../../shared/UI/TypeLabel'
import Card from '../../../shared/layout/Card'
import { IOSStyleSwitch } from '../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import { MyRequestsBanner } from '../MyRequestsBanner'
import { getPalleteTypeByProp } from '../../../theme/palette'
import FullComponentLoadingIcon from '../../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import { onlyUnique, setFilters } from './utils'
import RefreshButton from '../../../shared/UI/RefreshButton'

export function MyRequestsLegendsFilter({
  margin,
  eventTypes,
  loading,
  onChange,
  predefinedFilters,
  isHtlContractor,
  didFlipTheFilterSwitch,
}: MyRequstsLegendsFilterProps) {
  const [plottingFilters, setPlottingFilters] = useState<{ event: string; show: boolean }[]>([])
  const theme = useTheme()

  if (!plottingFilters.length && eventTypes) {
    setPlottingFilters(setFilters(predefinedFilters))
  }

  useEffect(() => {
    onChange(plottingFilters.filter(f => f.show).map(m => m.event))
  }, [onChange, plottingFilters])

  return (
    <>
      <MyRequestsLegendsStickyContainer margin={margin}>
        {!isHtlContractor && <MyRequestsBanner />}
        <Card
          title="Filters"
          icon={
            <RefreshButton
              title="Reset Filters"
              onClick={() => setPlottingFilters(setFilters())}
              dataTestId="MyAvailability-Grid-RefreshBtn"
            />
          }
        >
          <FullComponentLoadingIcon loading={Boolean(loading)}>
            <Grid container rowSpacing={2} data-testid='MyAvailability-Grid-Filters'>
              {eventTypes?.filter(onlyUnique).map(eventType => (
                <Grid item xs={12} display="flex" justifyContent="space-between">
                  <TypeLabel type={eventType} label={getPalleteTypeByProp(eventType).label} />
                  <IOSStyleSwitch
                    checked={plottingFilters?.some(s => s.event === eventType && s.show)}
                    onChange={(_e, checked) => {
                      didFlipTheFilterSwitch()
                      setPlottingFilters?.(current => {
                        const local: { event: string; show: boolean }[] = JSON.parse(
                          JSON.stringify(current)
                        )
                        const idx = local.findIndex(f => f.event === eventType)
                        local.splice(idx, 1, { event: eventType, show: checked })
                        return local
                      })
                    }}
                    data-testid="MyAvailability-Filters-ToggleBtn"
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <MyRequestsCustomChip label="Multiple Requests" />
              </Grid>
              <Grid item xs={12}>
                <MyRequestsCustomChip
                  label="Pending Requests"
                  backgroundColor="white"
                  color="black"
                  showHatch
                />
              </Grid>
              <Grid item xs={12}>
                <MyRequestsCustomChip
                  label="Bank Holiday"
                  backgroundColor="white"
                  borderColor="rgb(112, 112, 112)"
                  color="black"
                />
              </Grid>
              <Grid item xs={12}>
                <MyRequestsCustomChip
                  label="Today"
                  backgroundColor={theme.palette.primary.light}
                  borderColor={theme.palette.primary.light}
                  color="black"
                />
              </Grid>
            </Grid>
          </FullComponentLoadingIcon>
        </Card>
      </MyRequestsLegendsStickyContainer>
    </>
  )
}
