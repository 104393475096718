import React, {useCallback} from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import {addDays} from 'date-fns'
import { Box, Grid, useMediaQuery } from '@mui/material'
import {useSelector} from "react-redux"
import {DateRange} from "@mui/x-date-pickers-pro"
import Card from '../../../shared/layout/Card'
import Button from '../../../shared/UI/Button'
import {enhancementsContent} from '../../../utils/constants'
import { EnhancementStub, SubmissionPeriod } from '../../../models/enhancement'
import { SytledEnhancementsGrid} from '../../MyActionsGrid/StyledMyActionsGrid'
import { isMobileDown } from '../../../theme/deviceChecks'
import { NoResultComponent } from '../../MyActionsGrid/StyledMyActionsGrid'
import {EnhancementMobileColumns, EnhancementColumns} from "./EnhancementsTableColumns"
import ButtonSmall from "../../../shared/UI/ButtonSmall"
import { MyActionsSelectedStatus } from '../../MyActionsGrid/Shared/enums'
import {setFilterStates} from "../../../redux/reducers/myActionsReducer"
import {RootStore, useAppDispatch} from "../../../redux/store"
import {enhancementCheckboxSettings} from "../../MyActionsGrid/myactionsFlitering"


const baseUrl = '/enhancements'
const testid = 'enhancement-summary-'
const resolveToPath = (type: string, id: number) => {
  const resolver: { [key: string]: string } = {
    'on call': 'oncallrequest',
    'call out': 'calloutrequest',
    night: 'nightrequest',
    overtime: 'overtimerequest',
  }
  return `${baseUrl}/${resolver[type.toLowerCase()]}/${id}`
}

export type EnhancementsSummaryTableProps = {
  data?: EnhancementStub[]
  currentPeriod?: SubmissionPeriod
  pendingEnhancements: number
  disableSubmitButton: boolean
  isPeriodOpenForSubmissions: boolean
  totalEnhancementsToSubmit: number
  dateRange? : DateRange<Date>
  handleSubmitToPayroll: () => void
 isAfterSubmitBy: boolean

}

export function EnhancementsSummaryTable({
    data,
    currentPeriod,
    pendingEnhancements,
    disableSubmitButton,
    isPeriodOpenForSubmissions,
    totalEnhancementsToSubmit,
    handleSubmitToPayroll,
    dateRange,
    isAfterSubmitBy
    }: EnhancementsSummaryTableProps) {
  const navigate = useNavigate()
  const mobile = useMediaQuery(isMobileDown())
  const dispatch = useAppDispatch()

  const handleRowClick = (type: string, id: number) => {
    if (currentPeriod?.year && currentPeriod?.period) {
      navigate(
          {
            pathname: baseUrl,
            search: `?${createSearchParams({
              year: currentPeriod?.year.toString(),
              period: currentPeriod?.period.toString(),
            })}`,
          },
          { replace: true }
      )
    }
    navigate(resolveToPath(type, id), {
      state: { previousLocation: baseUrl, params: { ...currentPeriod } },
    })
  }

  const { selectedDepartmentFilter, filterStates } = useSelector(
      (state: RootStore) => state.myActions
  )
  const setPendingEnhancementsSearchParams = (selectedDateRange: DateRange<Date>, directReports:boolean, requestStatusId: number):void=>{
    if (selectedDateRange[0] === undefined || selectedDateRange[1] === undefined|| directReports === undefined || requestStatusId === undefined) {
      return
    }

    const modifiedDateRange: DateRange<Date> = [
      selectedDateRange[0],
      addDays(selectedDateRange[1]!, -1) 
    ]

    const enhancementDateFrom = new Date('01-01-0001')
    const enhancementDateTo = modifiedDateRange[1]
    const enhancementsRequestStatus = Number(requestStatusId)


    const newFilterStates = {
      ...filterStates,
      checkboxSettings: enhancementCheckboxSettings(),
      selectionState: {
        team: null,
        status: requestStatusId,
        department: selectedDepartmentFilter,
        dateRange: selectedDateRange,
        directReportees: directReports,
      },
      selectionStateDefault: {
        team: null,
        status: requestStatusId,
        department: selectedDepartmentFilter,
        dateRange: selectedDateRange,
        directReportees: directReports,
      },
      requestParams: {
        ...filterStates.requestParams,
        directReportees: directReports!,
        dateFrom: enhancementDateFrom!,
        dateTo: enhancementDateTo!,
        requestStatusId: enhancementsRequestStatus!,
      },
      requestParamsDefaults:{
        ...filterStates.requestParams,
        directReportees: directReports!,
        dateFrom: enhancementDateFrom!,
        dateTo: enhancementDateTo!,
        requestStatusId: enhancementsRequestStatus!,
      },
      metaData:{
        ...filterStates.metaData,
        initialised: true
      }
    }
    dispatch(setFilterStates(newFilterStates))
  }
  
  const resetFilterAndShowPendingApprovals = useCallback(() => {
    const newFilterStates = {
      ...filterStates,
      checkboxSettings: filterStates.checkboxSettings.map(setting => ({ ...setting, checked: false })),
      selectionState: { ...filterStates.selectionStateDefault },
      requestParams: { ...filterStates.requestParamsDefaults },
    }
    
    dispatch(setFilterStates(newFilterStates))
    
    if (dateRange === undefined){
      return
    }
    const startDate = dateRange[0]
    const endDate = dateRange[1]

    const allTimeDateRange: DateRange<Date> = [startDate, endDate]

    setPendingEnhancementsSearchParams(allTimeDateRange, false, MyActionsSelectedStatus.AWAITING_ACTION)
    navigate({
      pathname: '/myactions',
    })
  }, [navigate, dateRange, dispatch, filterStates, setPendingEnhancementsSearchParams])


  const getSubmitButtonLabel = () => {
    if (isPeriodOpenForSubmissions && totalEnhancementsToSubmit === 0) {
      return enhancementsContent.submitToPayrollNothingToSubmit
    }

    if (disableSubmitButton) {
      return enhancementsContent.submitToPayrollPeriodClosed
    }

    return enhancementsContent.submitToPayrollActive(totalEnhancementsToSubmit)
  }
 
  const rows = data?.map((item, index) => ({ ...item, id: index })) || [] 
  
  
  return (
    <>
      <Card padding="24px 28px" noDivider>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <ButtonSmall
            label={!pendingEnhancements || isAfterSubmitBy
              ? `Approvals Pending 0`
              : `Approvals Pending ${pendingEnhancements}`}
            onClick={() => resetFilterAndShowPendingApprovals()}
            variant="outlined"
            color="secondary"
            disabled={!pendingEnhancements || isAfterSubmitBy}
            sx={{
              '&.MuiButtonBase-root.MuiButton-root': {
                margin: 0,
                height: '27px',
              },
            }}
          />
        </div>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box
              sx={{
                marginTop: '24px',
                minHeight: '100px',
                maxHeight: '350px',
                height: '500px',
                overflowX: mobile ? 'auto' : 'hidden', 
                '& .MuiDataGrid-root': {
                  minHeight: '100px',
                },
              }}
              data-testid={`${testid}table`}
            >
              <SytledEnhancementsGrid
                rows={rows}
                columns={mobile ? EnhancementMobileColumns : EnhancementColumns}
                onRowClick={params =>
                  handleRowClick(params.row.enhancementType, params.row.enhancementId)
                }
                pinnedColumns={{ left: mobile ? ['displayName'] : [] }}
                sx={{
                  cursor: 'pointer',
                  '& .MuiDataGrid-row:hover': {
                   backgroundColor: 'rgba(0, 0, 0, 0.04)',
                   },
                }}
                rowHeight={52}
                headerHeight={48}
                pageSize={rows.length}
                hideFooter
                components={{
                  NoRowsOverlay: NoResultComponent,
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            label={getSubmitButtonLabel()}
            type="submit"
            className="freetext"
            dataTestId="submit-to-payroll"
            onClick={handleSubmitToPayroll}
            disabled={disableSubmitButton}
          />
        </Grid>
      </Card>
    </>
  )
}