const isLocalHost = window.location.origin.toLowerCase().includes('localhost')
let CLIENT_ID = process.env.REACT_APP_LOCALHOST_CLIENT_ID
let ISSUER = process.env.REACT_APP_LOCALHOST_ISSUER
const REDIRECT_URI = `${window.location.origin}/login/callback`
const USE_INTERACTION_CODE = process.env.USE_INTERACTION_CODE === 'true' || false

const currentLocation = window.location.hostname
let postLogoutRedirectUri = 'https://portal.bss.hillsidenewmedia.com'

switch (currentLocation) {
  case 'planner365.bss.dev.pub.gcp':
  case 'planner365.bss.dev.hillsidenewmedia.com':
    CLIENT_ID = process.env.REACT_APP_DEV_CLIENT_ID
    ISSUER = process.env.REACT_APP_DEV_ISSUER
    postLogoutRedirectUri = 'https://portal.bss.dev.hillsidenewmedia.com/'
    break
  case 'planner365.bss.uat.pub.gcp':
  case 'planner365.bss.uat.hillsidenewmedia.com':
    CLIENT_ID = process.env.REACT_APP_UAT_CLIENT_ID
    ISSUER = process.env.REACT_APP_UAT_ISSUER
    postLogoutRedirectUri = 'https://portal.bss.uat.hillsidenewmedia.com/'
    break
  case 'planner365.bss.preprod.pub.gcp':
  case 'planner365.bss.preprod.hillsidenewmedia.com':
    CLIENT_ID = process.env.REACT_APP_PREPROD_CLIENT_ID
    ISSUER = process.env.REACT_APP_PREPROD_ISSUER
    postLogoutRedirectUri = 'https://portal.bss.preprod.hillsidenewmedia.com/'
    break
  case 'planner365.bss.gcp':
  case 'planner365.bss.hillsidenewmedia.com':
    CLIENT_ID = process.env.REACT_APP_PROD_CLIENT_ID
    ISSUER = process.env.REACT_APP_PROD_ISSUER
    break
  default:
    break
}

const oktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    audience: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    postLogoutRedirectUri,
  },
  widget: {
    baseUrl: ISSUER!.replace('/oauth2/default', ''),
    clientId: CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    useInteractionCodeFlow: USE_INTERACTION_CODE,
    pkce: false,
    helpLinks: {
      forgotPassword: 'mailto:ServiceDesk.Mailbox@bet365.com',
      custom: [
        {
          text: 'Planner365',
        },
      ],
    },
  },
}

export default oktaConfig
