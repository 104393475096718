import { DateRange } from '@mui/x-date-pickers-pro'
import { format, eachDayOfInterval, differenceInMinutes, startOfDay, addHours } from 'date-fns'
import { AbsenceDayRequest } from '../../types/absence'
import { formatDateWithTimeZone } from '../date-utils'

export const calculateCalloutDays = (dateRange: DateRange<Date>) => {
  const [start, end] = dateRange
  let allDays: AbsenceDayRequest[] = []
  if (!start || !end || start >= end) return allDays
  const totalHours = differenceInMinutes(end, start) / 60
  if (format(start, 'yyyy-MM-dd') === format(end, 'yyyy-MM-dd')) {
    allDays = [
      {
        day: formatDateWithTimeZone(format(start, 'yyyy-MM-dd')),
        hours: totalHours,
        checked: true,
      },
    ]
  } else {
    const dates = eachDayOfInterval({ start, end })
    allDays = dates.map((date, index) => {
      let hours
      if (index === 0) {
        hours =
          differenceInMinutes(startOfDay(new Date(date.getTime() + 24 * 60 * 60 * 1000)), start) /
          60
      } else if (index === dates.length - 1) {
        hours = differenceInMinutes(end, startOfDay(date)) / 60
      } else {
        hours = 24
      }
      return {
        day: formatDateWithTimeZone(format(date, 'yyyy-MM-dd')),
        hours,
        checked: true,
      }
    })
  }
  return allDays
}
