import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { cardStyles, titleStyles, totalCountStyles, IndicatorTheme } from './TrendStyles'
import { TrendIcon } from './TrendIcon'

export const trendIndicator = 'trend-indicator'

export interface TrendIndicatorStats {
  percentage: number
  hours: number
}

export interface TrendIndicatorProps {
  testId: string
  theme: IndicatorTheme
  stats: TrendIndicatorStats
  filterArg?: string
  active: (filterArg: string | undefined) => boolean
  onClick?: (enhancementType: string | undefined) => void
}
export function TrendIndicator({
  testId,
  theme,
  stats,
  filterArg,
  active,
  onClick,
}: TrendIndicatorProps) {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleMouseClick = () => {
    if (onClick) onClick(filterArg)
  }

  const { label, fontColor, bgColor, borderColor } = theme

  return (
    <Box
      sx={{
        ...cardStyles,
        outline: active(filterArg) ? `1px solid ${borderColor}` : 'none',
        transform: isHovered ? 'translateY(-3px)' : 'none',
        background: bgColor,
        padding: '24px',
        ':not(:last-child)': {
          marginRight: '16px',
        },
        boxSizing: 'content-box',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      data-testid={`${trendIndicator}-${testId}`}
    >
      <Typography sx={titleStyles} data-testid={`${trendIndicator}-${testId}-title`}>
        {label}
      </Typography>
      <Box display="flex" alignItems="start">
        <TrendIcon percentage={stats.percentage} fill={fontColor} />
        <Typography sx={totalCountStyles} data-testid={`${trendIndicator}-${testId}-hours`}>
          {stats.hours}
        </Typography>
        <Typography
          sx={{ fontSize: '14px', color: fontColor, fontWeight: 'regular', lineHeight: 1.2 }}
          data-testid={`${trendIndicator}-${testId}-percentage`}
        >
          {`${stats.percentage <= 0 ? '' : '+'}${stats.percentage}%`}
        </Typography>
      </Box>
    </Box>
  )
}
