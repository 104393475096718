import { Grid, Stack, TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
import { EnhancementGetResponse, SubmitStatus } from '../../../models/enhancement'
import CardTitle from '../../../shared/UI/CardTitle'
import { globalContent, callOutFromContent, enhancementTypes } from '../../../utils/constants'
import Card from '../../../shared/layout/Card'
import Paragraph from '../../../shared/UI/Paragraph'
import { findLatestCommentByStatus } from '../../../utils/SharedMethods/getCommentsFromStatusHistory'

export const getDetailItem = (label: string, value: string) => (
  <Typography sx={{ width: '50%', display: 'flex', gap: 1 }}>
    <Paragraph weight="bold" style={{ dispaly: 'inline' }}>
      {label}
    </Paragraph>
    <span>{value}</span>
  </Typography>
)

export function TotalHoursCard({
  enhancementType,
  dateRange,
  calloutDetails,
  statusHistory,
  totalHours,
}: EnhancementGetResponse & { totalHours: string }) {
  const isCalloutEnhancment = enhancementType === enhancementTypes.callOut

  const displayTotalHours =
    Number(totalHours) > 0 ? Number(totalHours).toFixed(2).toString() : '0.00'
  const comment = () =>
    isCalloutEnhancment
      ? calloutDetails?.reason
      : findLatestCommentByStatus(statusHistory, SubmitStatus.PENDING)

  const displayDateType = isCalloutEnhancment ? 'dd/MM/yyyy HH:mm ' : 'dd/MM/yyyy'
  return (
    <Card
      title={globalContent.totalHours}
      icon={<CardTitle title={displayTotalHours} />}
      testId="total-hours"
    >
      <Grid container spacing={4}>
        <Grid item xs={12} textAlign="left">
          {isCalloutEnhancment && calloutDetails ? (
            <>
              <Stack direction="row" spacing={1} mb={1}>
                {getDetailItem(
                  globalContent.dateFrom,
                  format(new Date(dateRange.start), displayDateType)
                )}
                {getDetailItem(
                  globalContent.dateTo,
                  format(new Date(dateRange.end), displayDateType)
                )}
              </Stack>
              <Stack direction="row" spacing={1} mb={1}>
                {getDetailItem(callOutFromContent.calledOut, calloutDetails.calledOutBy)}
                {getDetailItem(callOutFromContent.product, calloutDetails.product)}
              </Stack>
              <Stack direction="row" spacing={1} mb={1}>
                {getDetailItem(
                  callOutFromContent.requireEscalation,
                  calloutDetails.escalationRequired ? 'Yes' : 'No'
                )}
                {getDetailItem(callOutFromContent.issueType, calloutDetails.issueType)}
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ display: 'flex', gap: 2 }}>
                  <Paragraph weight="bold" style={{ dispaly: 'inline' }}>
                    {callOutFromContent.jiraReference}
                  </Paragraph>
                  <span>{calloutDetails.jiraReference}</span>
                </Typography>
              </Stack>
            </>
          ) : (
            <Stack direction="row" justifyContent="space-between">
              <Paragraph weight="bold">{globalContent.dateFrom}</Paragraph>
              <Paragraph>{format(new Date(dateRange.start), displayDateType)}</Paragraph>
              <Paragraph weight="bold">{globalContent.dateTo}</Paragraph>
              <Paragraph>{format(new Date(dateRange.end), displayDateType)}</Paragraph>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={
              isCalloutEnhancment
                ? callOutFromContent.issueDescription
                : globalContent.requesteComments
            }
            fullWidth
            multiline
            rows={4}
            disabled
            value={comment()}
            data-testid="description"
          />
        </Grid>
      </Grid>
    </Card>
  )
}
