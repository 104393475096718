export const palette = {
  status: {
    approved: {
      label: 'Approved',
      color: '#5BCF8B',
      type: 'Approved',
    },
    amended: {
      label: 'Amended',
      color: '#5BCF8B',
      type: 'Amended',
    },
    cancellationRequested: {
      label: 'Cancellation Requested',
      color: '#5BCF8B',
      type: 'Cancellation Requested',
    },
    capabilityIssued: {
      label: 'Capability Issued',
      color: '#5BCF8B',
      type: 'Capability Issued',
    },
    queried: {
      label: 'Queried',
      color: '#5BCF8B',
      type: 'Queried',
    },
    changeRequested: {
      label: 'Change Requested',
      color: '#5BCF8B',
      type: 'Change Requested',
    },
    cancelled: {
      label: 'Cancelled',
      color: '#7561CC',
      type: 'Cancelled',
    },
    pending: {
      label: 'Pending',
      color: '#8D8D8D',
      type: 'Pending',
    },
    submitted: {
      label: 'Submitted',
      color: '#5BCF8B',
      type: 'Submitted',
    },
    awaitingRTW: {
      label: 'Awaiting RTW',
      color: '#8D8D8D',
      type: 'Awaiting RTW',
    },
    awaitingSCA: {
      label: 'Awaiting SCA',
      color: '#8D8D8D',
      type: 'Awaiting SCA',
    },
    awaitingED: {
      label: 'Awaiting ED',
      color: '#8D8D8D',
      type: 'Awaiting ED',
    },
    noActionNecessary: {
      label: 'No Action Necessary',
      color: '#8D8D8D',
      type: 'No Action Necessary',
    },
    awaitingAction: {
      label: 'Awaiting Action',
      color: '#8D8D8D',
      type: 'Awaiting Action',
    },
    awaitingFurtherDetails: {
      label: 'Awaiting Further Details',
      color: '#8D8D8D',
      type: 'Awaiting Further Details',
    },
    preDisciplinaryWarningIssued: {
      label: 'Pre-disciplinary warning Issued',
      color: '#8D8D8D',
      type: 'Pre-disciplinary warning Issued',
    },
    preCapabilityWarningIssued: {
      label: 'Pre-capability warning Issued',
      color: '#8D8D8D',
      type: 'Pre-capability warning Issued',
    },
    declined: {
      label: 'Declined',
      color: '#F85D70',
      type: 'Declined',
    },
    meetingScheduled: {
      label: 'Meeting scheduled',
      color: '#8D8D8D',
      type: 'Meeting scheduled',
    },
    disciplinaryIssued: {
      label: 'Disciplinary Issued',
      color: '#8D8D8D',
      type: 'Disciplinary Issued',
    },
  },
  type: {
    holiday: {
      label: 'Holiday',
      color: '#00CEC9',
      type: 'Holiday',
      fontColor: 'white',
    },
    holidayM: {
      label: 'Holiday (M)',
      color: '#00CEC9',
      type: 'Holiday (M)',
      fontColor: 'white',
    },
    forcedHoliday: {
      label: 'Holiday (M)',
      color: '#00CEC9',
      type: 'ForcedHoliday',
      fontColor: 'white',
    },
    birthday: {
      label: 'Birthday',
      color: '#21BAE8',
      type: 'Birthday',
      fontColor: 'white',
    },
    lieu: {
      label: 'Lieu',
      color: '#FDCB6E',
      type: 'Lieu',
      fontColor: 'white',
    },
    lieuDay: {
      label: 'Lieu Day',
      color: '#FDCB6E',
      type: 'Lieu Day',
      fontColor: 'white',
    },
    lieuM: {
      label: 'Lieu (M)',
      color: '#FDCB6E',
      type: 'Lieu (M)',
      fontColor: 'white',
    },
    buy: {
      label: 'Buy',
      color: '#F879C7',
      type: 'Buy',
      fontColor: 'white',
    },
    sell: {
      label: 'Sell',
      color: '#867CFF',
      type: 'Sell',
      fontColor: 'white',
    },
    shift: {
      label: 'Shift',
      color: '#90477C',
      type: 'Shift',
      fontColor: 'white',
    },
    dayOff: {
      label: 'Day Off',
      color: '#A29BFE',
      type: 'Day Off',
      fontColor: 'white',
    },
    adjustment: {
      label: 'Adjustment',
      color: '#7268EF',
      type: 'Adjustment',
      fontColor: 'white',
    },
    workFromHome: {
      label: 'WFH',
      color: '#7FCEE6',
      type: 'Work From Home',
      fontColor: 'white',
    },
    course: {
      label: 'Course',
      color: '#5D77B1',
      type: 'Course',
      fontColor: 'white',
    },
    maternity: {
      label: 'Maternity',
      color: '#58BC9D',
      type: 'Maternity',
      fontColor: 'white',
    },
    maternityLeave: {
      label: 'Maternity',
      color: '#58BC9D',
      type: 'MaternityLeave',
      fontColor: 'white',
    },
    paternity: {
      label: 'Paternity',
      color: '#58BC9D',
      type: 'Paternity',
      fontColor: 'white',
    },
    paternityLeave: {
      label: 'Paternity',
      color: '#58BC9D',
      type: 'PaternityLeave',
      fontColor: 'white',
    },
    nightShiftCover: {
      label: 'Night Shift Cover',
      color: '#4B4872',
      type: 'Night Shift Cover',
      fontColor: 'white',
    },
    twilightCover: {
      label: 'Twilight Cover',
      color: '#4B4872',
      type: 'Twilight Shift Cover',
      fontColor: 'white',
    },
    late: {
      label: 'Late',
      color: '#FF7675',
      type: 'Late',
      fontColor: 'white',
    },
    absence: {
      label: 'Absence',
      color: '#CB4A4A',
      type: 'Absence',
      fontColor: 'white',
    },
    pendingRequests: {
      label: 'Pending Requests',
      color: 'transparent',
      type: 'Pending Requests',
      fontColor: 'black',
    },
    manual: {
      label: 'Manual',
      color: '#656465',
      type: 'Manual',
      fontColor: 'white',
    },
    multipleRequests: {
      label: 'Multiple Requests',
      color: '#b9b9b9',
      type: 'Multiple Requests',
      fontColor: 'white',
    },
    overtime: {
      label: 'Overtime',
      color: '#F0870C',
      type: 'Overtime',
      fontColor: 'white',
    },
    overtimeM: {
      label: 'Overtime (M)',
      color: '#313057',
      type: 'Overtime',
      fontColor: 'white',
    },
    night: {
      label: 'Night',
      color: '#313057',
      type: 'Night',
      fontColor: 'white',
    },
    nightM: {
      label: 'Night (M)',
      color: '#313057',
      type: 'Night',
      fontColor: 'white',
    },
    onCall: {
      label: 'On Call',
      color: '#401BBA',
      type: 'On Call',
      fontColor: 'white',
    },
    onCallM: {
      label: 'On Call (M)',
      color: '#401BBA',
      type: 'On Call',
      fontColor: 'white',
    },
    callOut: {
      label: 'Call Out',
      color: '#BC18AF',
      type: 'Call Out',
      fontColor: 'white',
    },
    callOutM: {
      label: 'Call Out (M)',
      color: '#BC18AF',
      type: 'Call Out',
      fontColor: 'white',
    },
    unknown: {
      label: 'Unknown',
      color: '#656465',
      type: 'unknown',
      fontColor: 'white',
    },
    other: {
      label: 'Other',
      color: '#656465',
      type: 'other',
      fontColor: 'white',
    },
  },
}

export const getPalleteTypeByKey = (property: keyof typeof palette.type) => palette.type[property]

export const getPalleteTypeByProp = (property: string) => {
  const typeKey = Object.keys(palette.type).find(
    key =>
      palette.type[key as keyof typeof palette.type].type.replace(/ /g, '').toLowerCase() ===
      property.replace(/ /g, '').toLowerCase()
  )
  const retPalette = palette.type[typeKey as keyof typeof palette.type]
  if (!retPalette) {
    return palette.type.unknown
  }
  return retPalette
}

export default palette
