import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import styled from 'styled-components'

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    background: '#F4F5F7',
    color: '#555555',
    boxShadow: '0 0 10px #00000015',
    fontSize: '13px'
  },
})
