import { Fade, Collapse } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

interface LazyCollapse {
  key: string
  in: boolean
  children: React.ReactElement // | React.ReactNode
}

function LazyCollapse (props: LazyCollapse) {

  const [ref, inView] = useInView({
    trackVisibility: true, 
    delay: 450,
    threshold: 0.3,
    triggerOnce: true
  })

  const href = useRef<HTMLInputElement>(null)
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    if (!href.current) return
    setHeight(Number((href.current?.clientHeight || 0) + 20))
  })

  const inProp = inView ? props.in : false

  return (
    <Collapse 
      key={props.key}
      ref={ref}
      in={inProp}
      appear={false}
      style={{
        transformOrigin: '0 0 0',
        height:`${height}px`,
      }}
      easing={{
        exit: "3s cubic-bezier(0,0,.58,1)"
      }}
      timeout={{
        exit: 2000
      }}
      sx={{
        minHeight: `${height}px`,
        height: `${height}px`
      }}
      unmountOnExit={inProp}
    >
      <Fade
        ref={href}
        in={inProp} 
        appear={false}
        timeout={{
          enter: 500,
          exit: 2000
        }}
      >
        {props.children}
      </Fade>
    </Collapse>
  )
}

export default LazyCollapse
