import { Collapse, Link, Stack, Tooltip } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { setActiveRoute } from '../../../redux/reducers/appSettingsReducer'
import { useAppDispatch } from '../../../redux/store'
import { PlannerRoute } from '../../../utils/Routes'
import {
  NavLink,
  NavLinkTitle,
  StyledArrow,
  StyledPopover,
  SubNavLink,
  SubNavLinkTitle,
} from './components'
import { LogoutHelper } from '../../../utils/LogoutHelper'

interface NavitemProps {
  title: string
  href: string
  isActive: boolean
  icon: JSX.Element | null
  expanded: boolean
  show: boolean
  isDropDown: boolean
  subItem?: PlannerRoute[]
  activeRoute: string
  mobile: boolean
  closeDrawer?: () => void
  externalLink?: string
  dataTestId?: string
}

function Navitem({
  title,
  isActive,
  href,
  icon,
  expanded,
  show,
  isDropDown,
  subItem,
  activeRoute,
  mobile,
  closeDrawer,
  externalLink,
  dataTestId,
}: NavitemProps) {
  const { oktaAuth } = useOktaAuth()
  const [hide, setHide] = useState<boolean>()
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [logoutActive, setLogoutActive] = useState<boolean>(false)

  const openPopover = useMemo(() => Boolean(anchorEl), [anchorEl])
  const dispatch = useAppDispatch()
  const logoutHelper = new LogoutHelper(oktaAuth, dispatch)
  
  useEffect(() => {
    if (expanded) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, [expanded])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])


  const isSubItemActive = useCallback(
    i => (subItem && subItem[i].href === activeRoute) || false,
    [activeRoute, subItem]
  )
  const anySubItemsActive = useCallback(
    () => subItem?.some(item => item.href === activeRoute) || false,
    [activeRoute, subItem]
  )

  const navClass = useCallback(() => {
    if (title === logoutHelper.getLogoutButtonLabel()) {
      return logoutActive ? 'NavLinkActive' : ''
    }
    return isActive ? 'NavLinkActive' : ''
  }, [isActive, logoutActive, title])

  if (!show) return null

  return (
    <>
      {isDropDown ? (
        <>
          <Tooltip title={hide ? '' : title} placement="right">
            <NavLink
              to="#"
              className={anySubItemsActive() ? 'NavLinkActive' : ''}
              onClick={e => {
                setOpen(!open)
                handleClick(e)
              }}
              data-testid={dataTestId}
            >
              {icon}
              {expanded && (
                <NavLinkTitle
                  isActive={anySubItemsActive()}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {title}
                  <StyledArrow className={open ? 'sideBarExpanded' : ''} />
                </NavLinkTitle>
              )}
            </NavLink>
          </Tooltip>
          {expanded && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {subItem?.map((item, i) => (
                <SubNavLink
                  key={`${item.href}${item.title}`}
                  isActive={isSubItemActive(i)}
                  to={item.href}
                  onClick={() => {
                    dispatch(setActiveRoute(item.href))
                  }}
                >
                  {expanded && (
                    <SubNavLinkTitle isActive={isSubItemActive(i)}>{item.title}</SubNavLinkTitle>
                  )}
                </SubNavLink>
              ))}
            </Collapse>
          )}
        </>
      ) : (
        <Tooltip title={hide ? '' : title} placement="right">
          {externalLink ? (
            <Link href={href} target="_blank" rel="noopener noreferrer">
              <Stack direction="row" alignItems="center">
                {icon}
                {expanded && <NavLinkTitle isActive={false}>{title}</NavLinkTitle>}
              </Stack>
            </Link>
          ) : (
            <NavLink
              className={navClass()}
              to={href}
              onClick={() => {
                if (title === logoutHelper.getLogoutButtonLabel()) {
                  setLogoutActive(true)
                  logoutHelper.handleLogout()
                } else {
                  dispatch(setActiveRoute(href))
                }

                if (closeDrawer) {
                  closeDrawer()
                }
              }}
            >
              {icon}
              {expanded && <NavLinkTitle isActive={activeRoute === href}>{title}</NavLinkTitle>}
            </NavLink>
          )}
        </Tooltip>
      )}

      {isDropDown && (
        <StyledPopover
          id="user"
          anchorEl={anchorEl}
          open={!expanded && openPopover && !mobile}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          {subItem?.map((item, i) => (
            <SubNavLink
              key={`${item.href}${item.title}`}
              isActive={isSubItemActive(i)}
              to={item.href}
              onClick={() => {
                dispatch(setActiveRoute(item.href))
              }}
              className="popoverNavLink"
            >
              <SubNavLinkTitle isActive={isSubItemActive(i)}>{item.title}</SubNavLinkTitle>
            </SubNavLink>
          ))}
        </StyledPopover>
      )}
    </>
  )
}

export default Navitem
