import { MyActionsFilterState } from '../../components/MyActions/MyActionsFilter/Types'
import { SelectOption } from '../../services/dashboardService'
import { MyActionsSelectedStatus } from '../../components/MyActionsGrid/Shared/enums'

const myActionsFilterState: MyActionsFilterState = {
  checkboxSettings: [],
  requestParams: {
    directReportees: true,
    dateFrom: new Date(),
    dateTo: new Date(),
    requestStatusId: MyActionsSelectedStatus.AWAITING_ACTION,
  },
  requestParamsDefaults: {
    directReportees: true,
    dateFrom: new Date(),
    dateTo: new Date(),
    requestStatusId: MyActionsSelectedStatus.AWAITING_ACTION,
  },
  selectionState: {
    team: {
      teamId: 0,
      teamName: '',
      departmentId: undefined,
    },
    status: MyActionsSelectedStatus.AWAITING_ACTION,
    department: {
      value: 0,
      displayValue: '',
    },
    dateRange: [null, null],
    directReportees: true,
  },
  selectionStateDefault: {
    team: {
      teamId: 0,
      teamName: '',
      departmentId: undefined,
    },
    status: MyActionsSelectedStatus.AWAITING_ACTION,
    department: {
      value: 0,
      displayValue: '',
    },
    dateRange: [null, null],
    directReportees: true,
  },
  metaData: { initialised: false },
}

export interface MyActionsState {
  selectedDepartmentFilter: SelectOption | null | undefined
  filterStates: MyActionsFilterState
}

export const myActionsInitialState: MyActionsState = {
  selectedDepartmentFilter: null,
  filterStates: myActionsFilterState,
}

const SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT'
const SET_FILTER_STATES = 'SET_FILTER_STATES'

interface SetSelectedDepartmentFilterAction {
  type: typeof SET_SELECTED_DEPARTMENT
  payload: SelectOption | null | undefined
}

interface SetFilterStatesAction {
  type: typeof SET_FILTER_STATES
  payload: MyActionsFilterState
}

type myActionsDispatchtypes = SetSelectedDepartmentFilterAction | SetFilterStatesAction

// Actions
export const setSelectedDepartmentFilter = (
  departmentId: SelectOption | null | undefined
): SetSelectedDepartmentFilterAction => ({
  type: SET_SELECTED_DEPARTMENT,
  payload: departmentId,
})

export const setFilterStates = (filterState: MyActionsFilterState): SetFilterStatesAction => ({
  type: SET_FILTER_STATES,
  payload: filterState,
})

// Reducer
export const myActionsReducer = (
  state: MyActionsState = myActionsInitialState,
  action: myActionsDispatchtypes
): MyActionsState => {
  switch (action.type) {
    case SET_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartmentFilter: action.payload }
    case SET_FILTER_STATES:
      return { ...state, filterStates: action.payload }
    default:
      return state
  }
}
