import { Checkbox, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  RequestFieldsModal,
  RequestType,
  RequestTypeNameString,
  requestFieldsDefaultState,
} from '../../models'
import { RequestDay } from '../../models/request-day'
import { hideDrawer } from '../../redux/reducers/appSettingsReducer'
import {
  resetNewRequestState,
  setComments,
  setConflicts,
  setDateRange,
  setDefaultDateRange,
  setRequestType,
} from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import DateRangePicker from '../../shared/UI/DateRangePicker'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import HeadingTwo from '../../shared/UI/HeadingTwo'
import Paragraph from '../../shared/UI/Paragraph'
import { isTabletDown } from '../../theme/deviceChecks'
import { formatRequestTypeString, getRestrictedDate } from '../../utils/app-utils'
import { REQUEST_CLASH_CANNOT_SUBMIT_MESSAGE } from '../../utils/constants'
import { formatDatesToDelimitedString } from '../../utils/date-utils'
import ManagerEmployeeDropDown from './ManagerEmployeeDropDown'
import { HoursContainer, RequestDaysTextField } from './components'
import { ConflictMatch } from '../../types/base-response'
import { PayPeriodResponse } from '../../models/enhancement'
import { isEnhancementByRequestType } from '../../utils/SharedMethods/isEnhancement'
import PayPeriod from '../PayPeriod'
import { useDefaultHours } from './DefaultHours'

export type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => void
  calculateTotalDays: () => any
  updateCheckedDay: (selectedDay: Date, hours: string) => void
  updateHours: (selectedDay: Date, hours: string, type: RequestType) => void
  type: RequestType
  cardTitle: string
  summaryTitle?: string
  handleClose?: (event?: React.FormEvent<HTMLFormElement>, submission?: boolean) => void
  doesIncludeSummary?: boolean
  isManager?: boolean
  summaryOnly?: boolean
  isHidden?: boolean
  allowIsHidden?: boolean
  disabledDates?: string[]
  isHTL?: boolean
  submitLoading?: boolean
  isLoading?: boolean
  dataTestId?: string
  payPeriod?: PayPeriodResponse
}
const StyledGrid = styled(Grid)`
  &::-webkit-scrollbar {
    width: 17px;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: content-box;
  }
`

function MultiDayRequest({
  type,
  handleSubmit,
  calculateTotalDays,
  updateCheckedDay,
  updateHours,
  handleClose,
  doesIncludeSummary = true,
  isManager = false,
  summaryOnly = false,
  summaryTitle = 'Request Summary',
  isHidden = true,
  allowIsHidden = false,
  disabledDates,
  isHTL,
  submitLoading = false,
  isLoading = false,
  dataTestId,
  payPeriod,
}: Props) {
  const dispatch = useAppDispatch()
  const [fieldsTouched, setFieldsTouched] = useState<RequestFieldsModal>(requestFieldsDefaultState)
  const [bankHolidayDatesSelected, setBankHolidayDates] = useState<string[] | undefined>([])
  const [hasConflict, setHasConflict] = useState<boolean>(false)
  const [hasErrorConflict, setHasErrorConflict] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>({})
  const isTablet = useMediaQuery(isTabletDown())
  const [selectedDepartment, setSelectedDepartment] = useState<string>()
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false)
  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd')

  const { currentEntitlementPeriodResponse, calendarDetailsResponse, allEmployees } = useSelector(
    (state: RootStore) => state.appSettings
  )
  const { employeeSelected } = useSelector((state: RootStore) => state.timeOff)

  useEffect(() => {
    dispatch(setRequestType(type))
    return () => {
      dispatch(resetNewRequestState())
    }
  }, [dispatch, type])

  const { dateRange, defaultDateRange, selectedDays, alerts, comments } = useSelector(
    (state: RootStore) => state.timeOff
  )

  const checkRequestTypesRequireComments = ![
    RequestType.HOLIDAY,
    RequestType.WORK_FROM_HOME,
    RequestType.ON_CALL,
    RequestType.ON_CALL_M,
    RequestType.NIGHT,
    RequestType.NIGHT_M,
    RequestType.OVERTIME,
    RequestType.OVERTIME_M,
  ].includes(type)
  const isFormValid = () => {
    setValidationErrors({})
    const errors: any = {}

    const [fromDate, toDate] = dateRange
    const validateDateRange = () => {
      if (!fromDate) errors.fromDate = true
      if (!toDate) errors.toDate = true
    }

    const emptyComments = comments === ''
    const validateComments = () => {
      if (checkRequestTypesRequireComments && emptyComments) {
        errors.comments = true
      }
    }
    const validateSelectedDays = () => {
      if (
        selectedDays &&
        selectedDays.some((day: any) => {
          const dayIsSelected = day.checked
          const dayHoursDoNotExist = !day.hours
          const dayHoursIsZero = day.hours === 0

          return dayIsSelected && (dayHoursDoNotExist || dayHoursIsZero)
        })
      ) {
        return false
      }
      return true
    }

    validateDateRange()
    validateComments()

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return false
    }

    setFieldsTouched({ ...fieldsTouched, date: true, comments: true })

    if (!fromDate || !toDate) {
      return false
    }

    if (!validateSelectedDays()) {
      return false
    }

    return true
  }

  useEffect(() => {
    if (!defaultDateRange) {
      return
    }
    dispatch(setDateRange(defaultDateRange))
  }, [defaultDateRange, dispatch, selectedDepartment])

  const checkErrors = useCallback(() => {
    if ((alerts?.length || 0) === 0) {
      dispatch(setConflicts(false))
    }

    if (selectedDays && alerts) {
      const days = selectedDays
        .map(x => ({ day: formatDate(x.day), hours: x.hours, checked: x.checked }))
        .filter(selectedDay => selectedDay.checked === true)

      const containsDate = (dates: string[]) =>
        days.some(day => dates.some(date => day.day === date))
      const alertMapping = (conflicts: ConflictMatch[]) =>
        conflicts.flatMap(alert =>
          alert.conflictDates.map(conflictDate => formatDate(new Date(conflictDate)))
        )

      const checkConflicts = (
        conflicts: ConflictMatch[],
        setConflict: (value: boolean) => void
      ) => {
        const conflictDates = alertMapping(conflicts)
        const conflictsExist = containsDate(conflictDates)
        setConflict(conflictsExist)
      }

      checkConflicts(alerts, setHasConflict)
      checkConflicts(
        alerts.filter(alert => alert.conflictType.toLowerCase() === 'error'),
        setHasErrorConflict
      )
    }
  }, [alerts, dispatch, selectedDays])

  useEffect(() => {
    checkErrors()
  }, [checkErrors])

  const bankHolidays = useSelector<RootStore, any[] | null>(
    (state: RootStore) => state.appSettings.bankHolidays
  )

  const checkBankHolidays = useCallback(() => {
    const bankHolidaysDates = bankHolidays?.map(x => new Date(x.holidayDate).toLocaleDateString())
    const checkedDays = selectedDays?.filter(x => x.checked)
    const checkedDateStrings = checkedDays?.map(x => x.day?.toLocaleDateString())
    setBankHolidayDates(bankHolidaysDates?.filter(x => checkedDateStrings?.includes(x)))
  }, [bankHolidays, selectedDays])

  const isDayRestricted = (day: RequestDay) =>
    disabledDates?.some(disabledDate => {
      const disabledDay = new Date(disabledDate)
      return (
        format(new Date(disabledDay), 'yyyy,MM,dd') === format(new Date(day.day!), 'yyyy,MM,dd')
      )
    })

  const isDayChecked = (day: RequestDay) => {
    if (isDayRestricted(day)) {
      return false
    }
    return day.checked
  }
  const disableHoursField = useCallback(
    (day: RequestDay) =>
      !isDayChecked(day) || type === RequestType.ON_CALL || type === RequestType.ON_CALL_M,
    [type]
  )
  const getValue = (hours: number) => hours.toFixed(2)

  const getString = () => `${calculateTotalDays().toFixed(2)} Hours`

  useEffect(() => {
    if (selectedDays) {
      checkBankHolidays()
    }
  }, [checkBankHolidays, selectedDays])

  const BHDatesToDisplay = () => bankHolidayDatesSelected?.join(' & ')

  const conflictMessage = (requestType: string, dates: Date[], manualRequestType?: string) => {
    const plural = dates.length > 1
    const requestTypeStr =
      requestType.toLowerCase() === 'manual'
        ? formatRequestTypeString(manualRequestType!)
        : formatRequestTypeString(requestType)

    return `${
      isManager
        ? allEmployees.find(emp => emp.employeeId === employeeSelected)?.employeeName
        : 'You'
    } already ${isManager ? 'has' : 'have'}${plural ? '' : ' a'} ${requestTypeStr} booking${
      plural ? 's' : ''
    } for th${plural ? 'ese' : 'is'} date${plural ? 's' : ''} ${formatDatesToDelimitedString(
      dates
    )}`
  }

  function RequestDaysTextFieldHasErrors(day: RequestDay) {
    if (!day.checked || !submitBtnClicked) {
      return false
    }

    const isDayHoursValid = day.hours && day.hours > 0 && day.hours < 24
    if (!isDayHoursValid) {
      return true
    }

    if (!alerts) {
      return false
    }

    const conflictDates = alerts.flatMap(alert => alert.conflictDates)
    const dayFound = conflictDates.some(date => formatDate(new Date(date)) === formatDate(day.day!))

    return dayFound
  }

  const getInputProps = () => {
    if (isEnhancementByRequestType(type)) {
      return { inputProps: { step: 0.25, min: 0.25, max: 24 } }
    }
    return { inputProps: { step: 0.1, min: 0, max: 24 } }
  }

  const commentTestId = `${dataTestId}-${RequestTypeNameString[type]}Tab-Comments`
  const cancelButtonTestId = `${dataTestId}-${RequestTypeNameString[type]}-CancelBtn`
  const submitButtonTestId = `${dataTestId}-${RequestTypeNameString[type]}-SubmitBtn`
  const fromDateTestId = `${dataTestId}-${RequestTypeNameString[type]}`
  const hoursTestId = `${dataTestId}-Hours`

  const isPayPeriodDataMissing = useMemo(
    () =>
      !isEnhancementByRequestType(type) ||
      !payPeriod ||
      !payPeriod.managerCutOff ||
      !payPeriod.employeeCutOff,
    [payPeriod, type]
  )

  const renderPayPeriod = useMemo(() => {
    if (isPayPeriodDataMissing) {
      return null
    }
    return <PayPeriod isManager={isManager} payPeriod={payPeriod} />
  }, [isManager, isPayPeriodDataMissing, payPeriod])

  const { employeeDetails, directReports } = useSelector((state: RootStore) => state.appSettings)

  let selectedDepartmentId

  if (isManager) {
    const selectedEmployee = directReports.find(emp => emp.employeeId === employeeSelected)
    selectedDepartmentId = selectedEmployee?.departmentId ?? employeeDetails.departmentId
  } else {
    selectedDepartmentId = employeeDetails.departmentId
  }

  const { getDefaultDayHours } = useDefaultHours({
    departmentId: selectedDepartmentId,
    selectedDays,
    type,
  })

  return (
    <Grid
      component="form"
      noValidate
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setSubmitBtnClicked(true)
        if (isFormValid()) {
          handleSubmit(event, type)
        }
      }}
    >
      <Grid container columnSpacing={6} rowSpacing={4}>
        {!summaryOnly && (
          <>
            {isManager && (
              <>
                <Grid item xs={12} lg={6}>
                  <ManagerEmployeeDropDown />
                </Grid>
                <Grid item xs={12} lg={6}>
                  {isHidden && <Alert severity="info" message="Please select an Employee" />}
                </Grid>
              </>
            )}
            {renderPayPeriod}
            {bankHolidayDatesSelected && bankHolidayDatesSelected?.length > 0 ? (
              <Grid item xs={12}>
                <Alert
                  severity="info"
                  message={`There are bank holidays on: ${BHDatesToDisplay()}`}
                />
              </Grid>
            ) : (
              <></>
            )}
            {hasConflict &&
              alerts &&
              alerts.map(alert => (
                <Grid item xs={12} key={`${alert.conflictType}-${Math.random()}`}>
                  <Alert
                    severity={alert.conflictType as 'success' | 'error' | 'info' | 'warning'}
                    message={conflictMessage(
                      alert.requestType,
                      alert.conflictDates,
                      alert.manualRequestTypeStr
                    )}
                  />
                </Grid>
              ))}
            {(!isHidden || !allowIsHidden) && (
              <Grid item xs={12} lg={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Paragraph weight="bold">Details</Paragraph>
                  </Grid>
                  <Grid item xs={12}>
                    <DateRangePicker
                      value={dateRange}
                      maxDate={getRestrictedDate(
                        isHTL || false,
                        currentEntitlementPeriodResponse?.entitlementPeriod || 'FinancialYear',
                        dateRange,
                        calendarDetailsResponse || []
                      )}
                      onChange={newValue => {
                        dispatch(setDefaultDateRange(undefined))
                        setFieldsTouched({ ...fieldsTouched, date: true })
                        dispatch(setDateRange(newValue))
                      }}
                      fromDateError={validationErrors.fromDate}
                      toDateError={validationErrors.toDate}
                      helperText="Required"
                      disabledDates={disabledDates}
                      dataTestId={fromDateTestId}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      rows={5}
                      multiline
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch(setComments(e.target.value.trim()))
                      }}
                      label="Comments"
                      helperText={checkRequestTypesRequireComments ? 'Required' : ''}
                      error={validationErrors.comments}
                      data-testid={commentTestId}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {(!isHidden || !allowIsHidden) && (
          <Grid item xs={12} lg={summaryOnly ? 12 : 6}>
            {doesIncludeSummary && (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Paragraph weight="bold">{summaryTitle}</Paragraph>
                </Grid>
                <StyledGrid
                  item
                  xs={12}
                  sx={{
                    overflowY: 'auto',
                    maxHeight: '420px',
                  }}
                >
                  {!selectedDays && !isLoading && (
                    <Alert severity="info" message="Please select a date or date range" />
                  )}

                  {selectedDays && (
                    <Grid container spacing={4}>
                      <>
                        {selectedDays.map((day: RequestDay) => (
                          <Grid item xs={12} key={day.day!.getTime()}>
                            <Stack direction="row" gap={1}>
                              <Checkbox
                                checked={isDayChecked(day)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  updateCheckedDay(day.day!, e.target.value)
                                  checkBankHolidays()
                                  checkErrors()
                                }}
                                style={{ margin: '9px 30px 9px 0' }}
                                disabled={isDayRestricted(day)}
                                disableRipple
                              />
                              <RequestDaysTextField
                                type="number"
                                InputProps={getInputProps()}
                                fullWidth
                                disabled={disableHoursField(day)}
                                label={format(day.day!, 'ccc dd/MM/yyyy')}
                                defaultValue={
                                  isDayRestricted(day)
                                    ? Number(0).toFixed(2)
                                    : day.hours!.toFixed(2)
                                }
                                value={getDefaultDayHours(day)}
                                error={RequestDaysTextFieldHasErrors(day)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  updateHours(day.day!, e.target.value, type)
                                }}
                                bankHoliday={bankHolidayDatesSelected!.some(
                                  x => x === day.day?.toLocaleDateString()
                                )}
                                checked={day.checked!}
                                onWheel={event =>
                                  event.target instanceof HTMLElement && event.target.blur()
                                }
                                data-testid={hoursTestId}
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </>
                    </Grid>
                  )}
                </StyledGrid>
                {selectedDays && (
                  <Grid item xs={12} mt={1}>
                    <HoursContainer>
                      <HeadingTwo title="Total Hours" />
                      <HeadingTwo title={getString()} />
                    </HoursContainer>
                  </Grid>
                )}
                {hasErrorConflict && (
                  <Grid item xs={12}>
                    <Paragraph color="#E75E5E" weight="bold">
                      {REQUEST_CLASH_CANNOT_SUBMIT_MESSAGE}
                    </Paragraph>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {!summaryOnly && (
        <DrawerFooter>
          {(!isHidden || !allowIsHidden) && (
            <>
              <Button
                color="secondary"
                label="Cancel"
                onClick={event => {
                  dispatch(resetNewRequestState())
                  dispatch(hideDrawer())
                  handleClose?.(event, false)
                }}
                dataTestId={cancelButtonTestId}
              />
              <Button
                label="Submit"
                type="submit"
                disabled={hasErrorConflict}
                loading={submitLoading}
                dataTestId={submitButtonTestId}
              />
            </>
          )}
        </DrawerFooter>
      )}
    </Grid>
  )
}

export default MultiDayRequest
