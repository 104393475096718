import styled from '@emotion/styled'
import { Box, Select } from '@mui/material'
import { BookingPopupStyleProps } from './types'

const getArrowLeft = (popoverPosition: number): string => {
  switch (popoverPosition) {
    case 1: // Top
      return 'calc(50% - 10px)'
    case 2: // Right
      return '-20px'
    case 3: // Bottom
      return 'calc(50% - 10px)'
    case 4: // Left
      return '100%'
    default:
      return ''
  }
}

const getArrowTop = (popoverPosition: number): string => {
  switch (popoverPosition) {
    case 1: // Top
      return 'calc(100% - 1px)'
    case 3: // Bottom
      return '-20px'
    case 2: // Right
    case 4: // Left
      return 'calc(50% - 10px)'
    default:
      return ''
  }
}

const getArrowBorder = (popoverPosition: number, color: string): string => {
  switch (popoverPosition) {
    case 1: // Top
      return `${color} transparent transparent transparent`
    case 2: // Right
      return `transparent ${color} transparent transparent`
    case 3: // Bottom
      return `transparent transparent ${color} transparent`
    case 4: // Left
      return `transparent transparent transparent ${color}`
    default:
      return ''
  }
}

const getArrowColour = (isOwnBooking: boolean): string =>
  isOwnBooking ? '#20C5A0' : '#ffffff'

export const BookingPopover = styled.div<BookingPopupStyleProps>`
  ${props => `
    @keyframes popperFadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    & {
      // ${props.isMobile && `
      //   position: fixed;
      //   bottom: 0px;
      // `}
      ${true && `
        animation: popperFadeIn 500ms;
        background-color: white;
        height: 100%;
        width: 100%;
        position: absolute;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 #d1d1d1;
        padding: 10px 25px;
        box-sizing: border-box;
      `}
      }
    &:before {
      ${!props.isMobile && `
        content: '';
        position: absolute;
        height: 0px;
        width: 0px;
        top: ${getArrowTop(props.popoverPosition)};
        left: ${getArrowLeft(props.popoverPosition)};
        border-width: 10px;
        border-color: ${getArrowBorder(props.popoverPosition, getArrowColour(props.isOwnBooking))};
        border-style: solid;
      `}
    }`}
`

export const BookingSummaryInline = styled.div<{ lineCount: number }>`
  ${props => `
  @keyframes slideIn {
    0% {
      height: 0;
    }
    100% {
      height: ${props.lineCount * 24}px;
    }
  }
  & {
    overflow: hidden;
    animation: slideIn 500ms;
    margin: 5px 0 0;
  }`}
`

export const BookingTab = styled.a`
  ${props => `
  @keyframes BookingTabAni {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  & {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    color: #8D8D8D;
    margin-right: 25px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  &.bookingLinkActive {
    color: #20C5A0;
    border-bottom: 3px solid #20C5A0;
  }
  `}
`
export const BookingYourOwnDesk = styled.span<BookingPopupStyleProps>`
  ${props => `
  @keyframes popperOwnFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }  
  & {
    display: block;
    font-family: Poppins;
    font-size: 16px;
    animation: popperOwnFadeIn 500ms;
    box-shadow: 0 0 20px 0 #d1d1d1;
    background-color: #20c5a1;
    width: ${props.popupWidth + 30}px;
    padding: 12px 0 12px 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 #d1d1d1;
    color: white;
  }
  &:before {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    top: ${getArrowTop(props.popoverPosition)};
    left: ${getArrowLeft(props.popoverPosition)};
    border-width: 10px;
    border-color: ${getArrowBorder(props.popoverPosition, '#20c5a1')};
    border-style: solid;
  }`}
`

export const BookingBookDropdownContainer = styled(Box)`
  ${props => `
  @keyframes bookDDLengthSlideIn {
    0% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }  
  @keyframes bookDDLengthSlideOut {
    0% {
      width: 100%;
    }
    100% {
      width: 50%;
    }
  }  
  @keyframes bookDDPeriodSlideIn {
    0% {
      opacity: 0;
      width: 0%;
    }
    100% {
      opacity: 1;
      width: 50%;
    }
  }  
  @keyframes bookDDPeriodSlideOut {
    0% {
      opacity: 1;
      width: 50%;
    }
    100% {
      opacity: 0;
      width: 0%;
    }
  }  
  &.bookDDLength{
    width: 100%;
  }
  &.bookDDPeriod{
    width: 0%;
    opacity: 0;
  }
  &.bookDDFullDayLength{
    animation: bookDDLengthSlideIn 250ms;
    width: 100%;
  }
  &.bookDDPartDayLength{
    animation: bookDDLengthSlideOut 250ms;
    width: 50%;
    margin-right: 5px;
  }
  &.bookDDFullDayPeriod{
    animation: bookDDPeriodSlideOut 250ms;
    width: 0%;
    opacity: 0;
  }
  &.bookDDPartDayPeriod{
    animation: bookDDPeriodSlideIn 250ms;
    width: 50%;
    opacity: 1;
  }
  `}
`
